export const GET_SPORTS = 'GET_SPORTS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_LEAGUES = 'GET_LEAGUES';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';

export const SET_SPORTS = 'SET_SPORTS';
export const SET_SELECTED_SPORT = 'SET_SELECTED_SPORT';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const RESET_SELECTED_COUNTRY = 'RESET_SELECTED_COUNTRY';
export const SET_LEAGUES = 'SET_LEAGUES';
export const SET_SELECTED_LEAGUE = 'SET_SELECTED_LEAGUE';
export const RESET_SELECTED_LEAGUE = 'RESET_SELECTED_LEAGUE';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const RESET_SELECTED_EVENT = 'RESET_SELECTED_EVENT';
export const SET_ODDS = 'SET_ODDS';

export const getSports = () => ({
    type: GET_SPORTS,
});

export const setSports = (sports) => ({
    type: SET_SPORTS,
    payload: {
        sports
    }
});

export const setSelectedSport = (sportId) => ({
    type: SET_SELECTED_SPORT,
    payload: {
        sportId
    }
});

export const getCountries = (sportId) => ({
    type: GET_COUNTRIES,
    payload: {
        sportId,
    }
});

export const setCountries = (sportId, countries) => ({
    type: SET_COUNTRIES,
    payload: {
        sportId,
        countries,
    }
});

export const setSelectedCountry = (countryId) => ({
    type: SET_SELECTED_COUNTRY,
    payload: {
        countryId
    }
});

export const resetSelectedCountry = () => ({
    type: RESET_SELECTED_COUNTRY,
});

export const getLeagues = (sportId, countryId) => ({
    type: GET_LEAGUES,
    payload: {
        sportId,
        countryId,
    }
});

export const setLeagues = (leagues) => ({
    type: SET_LEAGUES,
    payload: {
        leagues
    }
});

export const setSelectedLeague = (leagueId) => ({
    type: SET_SELECTED_LEAGUE,
    payload: {
        leagueId
    }
});

export const resetSelectedLeague = () => ({
    type: RESET_SELECTED_LEAGUE,
});

export const getEvents = (sportId, leagueId) => ({
    type: GET_EVENTS,
    payload: {
        sportId,
        leagueId,
    }
});

export const setEvents = (events) => ({
    type: SET_EVENTS,
    payload: {
        events
    }
});

export const setSelectedEvent = (eventId) => ({
    type: SET_SELECTED_EVENT,
    payload: {
        eventId
    }
});

export const resetSelectedEvent = () => ({
    type: RESET_SELECTED_EVENT,
});

export const getEvent = (eventId) => ({
    type: GET_EVENT,
    payload: {
        eventId,
    }
});

export const setOdds = (eventId, odds) => ({
    type: SET_ODDS,
    payload: {
        eventId,
        odds
    }
});
