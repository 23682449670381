import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import initialTheme from "./theme/theme";
import { useState } from "react";
import DefaultLayout from "layouts/Main.js";
import RTLLayout from "layouts/RTL.js";
import Home from "views/Dashboard/Home";
import Policies from "views/Dashboard/Policies";
import ProvablyFair from "views/Dashboard/ProvablyFair";
import ResponsibleGambling from "views/Dashboard/ResponsibleGambling";
import { wsConnect } from "./actions/websocket";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = ({ dispatch, wsConnected }) => {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  // set the theme to dark explicitly
  localStorage.setItem("chakra-ui-color-mode", "dark");

  // check if the theme is not dark mode then set it to dark explicitly
  useEffect(() => {
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    if (colorMode !== "dark") {
      localStorage.setItem("chakra-ui-color-mode", "dark");
    }
  }, []);

  useEffect(() => {
    // Establish WebSocket connection if not connected
    if (!wsConnected) {
      dispatch(wsConnect("wss://sea-turtle-app-iudng.ondigitalocean.app"));
    }

    // Optionally: Handle WebSocket disconnect or error cleanup here
  }, [dispatch, wsConnected]);

  return (
    <ChakraProvider theme={currentTheme}>
      {/* Enforces theme across reloads */}
      <ColorModeScript
        initialColorMode={currentTheme.config.initialColorMode}
      />
      <Routes>
        {/* Auth Routes */}
        <Route
          path="/auth/*"
          element={
            <DefaultLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />

        {/* Main Layout and routes */}
        <Route
          path="/*"
          element={
            <DefaultLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        >
          {/* Define your routes */}
          <Route path="home" element={<Home />} />
          <Route path="policies/:policyName" element={<Policies />} />
          <Route path="provably-fair/:sectionName" element={<ProvablyFair />} />
          <Route
            path="responsible-gambling/:sectionName"
            element={<ResponsibleGambling />}
          />
        </Route>

        {/* RTL Layout */}
        <Route path="/rtl/*" element={<RTLLayout />} />

        {/* Default navigation */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/auth/home" element={<Navigate to="/home" replace />} />
      </Routes>
    </ChakraProvider>
  );
};

// Map Redux state to props
const s2p = (state) => ({
  wsConnected: state.websocket?.connected || false,
});

// Connect App component to Redux store
export default connect(s2p)(App);
