import {
  SET_SPORTS,
  SET_SELECTED_SPORT,
  SET_COUNTRIES,
  SET_SELECTED_COUNTRY,
  RESET_SELECTED_COUNTRY,
  SET_LEAGUES,
  SET_SELECTED_LEAGUE,
  RESET_SELECTED_LEAGUE,
  SET_EVENTS,
  SET_SELECTED_EVENT,
  RESET_SELECTED_EVENT,
  SET_ODDS,
} from "../actions/sports";

const initialState = {
  selectedSport: null,
  sports: {},
  selectedCountry: null,
  countries: {},
  selectedLeague: null,
  leagues: {},
  selectedEvent: null,
  events: {},
  odds: {},
};

export const sportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPORTS:
      // eslint-disable-next-line no-case-declarations
      const sports = {};
      action.payload.sports.forEach((sport) => {
        sports[sport.sportId] = {
          name: sport.sportName,
        };
      });

      return {
        ...state,
        sports: {
          ...state.sports,
          ...sports,
        },
      };

    case SET_SELECTED_SPORT:
      return {
        ...state,
        selectedSport: action.payload.sportId,
      };
    case SET_COUNTRIES:
      // eslint-disable-next-line no-case-declarations
      const _countries = {};
      // eslint-disable-next-line no-case-declarations
      const { sportId, countries } = action.payload;

      countries.forEach((country) => {
        _countries[country.countryId] = {
          sportId,
          name: country.countryName,
        };
      });

      return {
        ...state,
        countries: {
          ...state.countries,
          ..._countries,
        },
      };
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload.countryId,
      };
    case RESET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: null,
      };
    case SET_LEAGUES:
      // eslint-disable-next-line no-case-declarations
      const leagues = {};
      action.payload.leagues.forEach((league) => {
        leagues[league.leagueId] = {
          sportId: league.sportId,
          countryId: league.countryId,
          name: league.leagueName,
        };
      });

      return {
        ...state,
        leagues: {
          ...state.leagues,
          ...leagues,
        },
      };
    case SET_SELECTED_LEAGUE:
      return {
        ...state,
        selectedLeague: action.payload.leagueId,
      };
    case RESET_SELECTED_LEAGUE:
      return {
        ...state,
        selectedLeague: null,
      };
    case SET_EVENTS:
      // eslint-disable-next-line no-case-declarations
      const eventData = action.payload.events;
      // Convert object values to an array
      // eslint-disable-next-line no-case-declarations
      const events = {};

      // Iterate over the values (events) instead of assuming it's an array
      Object.values(eventData).forEach((event) => {
        const { eventId, ...rest } = event;
        events[eventId] = { ...rest };
      });

      return {
        ...state,
        events: {
          ...state.events,
          ...events,
        },
      };

    case SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload.eventId,
      };
    case RESET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: null,
      };
    case SET_ODDS:
      // eslint-disable-next-line no-case-declarations
      const newOdds = action.payload.odds;
      for (const odd in newOdds) {
        newOdds[odd].eventId = action.payload.eventId;
      }

      return {
        ...state,
        odds: {
          ...state.odds,
          ...newOdds,
        },
      };
    default:
      return state;
  }
};
