import { createIcon } from "@chakra-ui/icons";

export const OddTabIcon = createIcon({
  displayName: "OddTabIcon",
  viewBox: "0 0 64 64",
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.013 19.707 64 0v12.64L30.987 33.627 18.133 18.853 0 30.693V17.947L20.107 4.853l12.906 14.854ZM16 64H5.333V35.173L16 28.213V64Zm13.707-21.653-3.04-3.52V64h10.666V37.493l-2.773 1.76-4.853 3.094ZM58.667 64H48V30.72l10.667-6.8V64Z"
      />
    </>
  ),
});

export const EmptyBetSlipIcon = createIcon({
  displayName: "EmptyBetSlipIcon",
  viewBox: "0 0 80 80",
  path: (
    <>
      <path
        d="M23.7401 25.1095H16.4071V21.6539C16.4071 20.4152 23.7401 20.4152 23.7401 21.6539V25.1095Z"
        fill="currentColor"
      />
      <path
        d="M63.8036 24.3578H16.1963C12.0405 24.3578 8.67151 27.7259 8.67151 31.8807C8.67151 36.0355 12.0405 39.4036 16.1963 39.4036H63.8036C67.9594 39.4036 71.3284 36.0355 71.3284 31.8807C71.3284 27.7259 67.9594 24.3578 63.8036 24.3578Z"
        fill="currentColor"
      />
      <path
        d="M63.8419 29.4741H16.1617C14.8315 29.4741 13.7532 30.5515 13.7532 31.8807C13.7532 33.2098 14.8315 34.2873 16.1617 34.2873H63.8419C65.1721 34.2873 66.2504 33.2098 66.2504 31.8807C66.2504 30.5515 65.1721 29.4741 63.8419 29.4741Z"
        fill="currentColor"
      />
      <path
        d="M63.5963 77.4917V31.5643H16.4071V77.4917C17.7916 77.4917 18.9154 78.6155 18.9154 80H23.3374C23.3374 78.6155 24.4612 77.4917 25.8457 77.4917C27.2302 77.4917 28.354 78.6155 28.354 80H32.7761C32.7761 78.6155 33.8998 77.4917 35.2843 77.4917C36.6689 77.4917 37.7926 78.6155 37.7926 80H42.2147C42.2147 78.6155 43.3384 77.4917 44.7229 77.4917C46.1075 77.4917 47.2312 78.6155 47.2312 80H51.6533C51.6533 78.6155 52.777 77.4917 54.1615 77.4917C55.5461 77.4917 56.6698 78.6155 56.6698 80H61.0919C61.0919 78.6155 62.2156 77.4917 63.6002 77.4917H63.5963Z"
        fill="currentColor"
      />
      <path
        d="M32.2509 24.354C38.976 24.354 44.4278 18.9022 44.4278 12.177C44.4278 5.45183 38.976 0 32.2509 0C25.5257 0 20.0739 5.45183 20.0739 12.177C20.0739 18.9022 25.5257 24.354 32.2509 24.354Z"
        fill="currentColor"
      />
      <path
        d="M23.8704 3.34824C22.8233 4.34157 21.9489 5.52284 21.3046 6.84984L24.2999 4.61388L23.8704 3.34824Z"
        fill="currentColor"
      />
      <path
        d="M29.3356 8.18066L27.6135 13.7111L32.3463 17.0593L36.9908 13.5961L35.1307 8.10779L29.3356 8.18066Z"
        fill="currentColor"
      />
      <path
        d="M43.369 17.1437L42.0266 17.159L40.9182 20.722C41.9307 19.698 42.7668 18.4937 43.369 17.1437Z"
        fill="currentColor"
      />
      <path
        d="M41.9882 10.8807L44.3507 10.85C44.0861 8.41461 43.0889 6.15179 41.5548 4.3262L40.1281 5.38857L41.9882 10.8769V10.8807Z"
        fill="currentColor"
      />
      <path
        d="M35.0924 1.82945L35.5219 0.452581C35.1 0.333688 34.6705 0.237806 34.2256 0.164936C32.3578 -0.141886 30.5207 -0.0076516 28.8179 0.494769L29.2934 1.90232L35.0885 1.82945H35.0924Z"
        fill="currentColor"
      />
      <path
        d="M21.8223 13.4541L20.1425 13.4733C20.3879 15.7745 21.2854 17.9222 22.6737 19.6941L23.6824 18.9424L21.8223 13.4541Z"
        fill="currentColor"
      />
      <path
        d="M28.7218 22.5092L28.3459 23.7097C28.9634 23.9168 29.6078 24.0817 30.2751 24.1929C31.9166 24.4614 33.5312 24.3885 35.0538 24.028L34.5131 22.4364L28.718 22.5092H28.7218Z"
        fill="currentColor"
      />
      <path
        d="M54.1156 18.9041C54.1156 20.4305 53.4904 21.8074 52.4818 22.793C52.2401 23.0346 51.9755 23.2494 51.6917 23.4374C50.8249 24.0165 49.7856 24.354 48.6657 24.354C46.8784 24.354 45.2906 23.4949 44.3011 22.1679C44.1401 21.9569 43.9981 21.7383 43.8754 21.5082C43.4497 20.7335 43.2119 19.8475 43.2119 18.9041C43.2119 17.9606 43.4497 17.0746 43.8754 16.2999C43.9981 16.0698 44.1401 15.8512 44.3011 15.6402C45.2906 14.3132 46.8784 13.4541 48.6657 13.4541C49.7856 13.4541 50.8249 13.7916 51.6917 14.3708C51.9755 14.5587 52.2401 14.7735 52.4818 15.0151C53.4904 16.0008 54.1156 17.3776 54.1156 18.9041Z"
        fill="currentColor"
      />
      <path
        d="M49.3791 20.7258H49.5248V19.9588H49.0761C49.0147 19.7401 48.9687 19.5177 48.9457 19.2876H49.3676V18.5205H48.9457C48.9687 18.2904 49.0147 18.068 49.0761 17.8493H49.5248V17.0822H49.3791C49.2927 16.7706 49.1555 16.4809 48.9767 16.2222L48.614 16.5232L48.785 16.8476C48.5683 17.1188 48.4117 17.429 48.3272 17.7604H47.9053V18.5275H48.2991C48.2856 18.7449 48.2932 18.9644 48.3217 19.1819H47.9053V19.949H48.3272C48.4117 20.2804 48.5683 20.5905 48.785 20.8617L48.614 21.1862L48.9767 21.4871C49.1555 21.2284 49.2927 20.9387 49.3791 20.6271V20.7258Z"
        fill="currentColor"
      />
    </>
  ),
});

export const MultiBetIcon = createIcon({
  displayName: "MultiBetIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      d="M45.708 9.146 36.562 0a6.408 6.408 0 0 1-4.56 1.898A6.408 6.408 0 0 1 27.442 0L.002 27.44A6.408 6.408 0 0 1 1.898 32 6.408 6.408 0 0 1 0 36.56l9.146 9.146a6.481 6.481 0 0 1 9.14 9.154l.007-.006L27.439 64A6.408 6.408 0 0 1 32 62.102c1.783 0 3.396.726 4.56 1.898L64 36.56A6.408 6.408 0 0 1 62.101 32c0-1.782.727-3.396 1.898-4.56l-9.146-9.146a6.481 6.481 0 0 1-9.14-9.154l-.005.006ZM28.002 40.56 24.561 44l-4.56-4.56 3.44-3.44 4.56 4.56Zm8-8L32.562 36l-4.56-4.56 3.44-3.44 4.56 4.56Zm8-8L40.562 28l-4.56-4.56 3.44-3.44 4.56 4.56Z"
    />
  ),
});

export const SingleBetIcon = createIcon({
  displayName: "SingleBetIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      d="M16.134 63.996.002 47.997a5.474 5.474 0 0 0 1.728-4A5.48 5.48 0 0 0 .003 40l-.002-.002L40 0a5.474 5.474 0 0 0 4 1.728A5.48 5.48 0 0 0 47.996.002L47.998 0l16 16.133a5.474 5.474 0 0 0-1.658 3.934 5.47 5.47 0 0 0 1.657 3.931L64 24 24.002 63.998a5.475 5.475 0 0 0-3.934-1.658 5.47 5.47 0 0 0-3.931 1.658l-.003.002v-.004Z"
    />
  ),
});

export const ChatIcon = createIcon({
  displayName: "ChatIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      d="M32 1.916c-.288-.01-.628-.016-.97-.016C14.254 1.9.586 15.206.002 31.84L0 31.894A28.655 28.655 0 0 0 7.476 51.15l-.02-.024c-.688 4.028-1.89 7.636-3.552 10.974l.102-.228c4.634-.396 8.878-1.73 12.654-3.81l-.164.082c4.474 2.35 9.774 3.728 15.398 3.728h.112H32c.3.01.654.016 1.008.016 16.768 0 30.428-13.31 30.99-29.942l.002-.052C63.414 15.206 49.746 1.902 32.97 1.902c-.342 0-.68.006-1.018.016l.05-.002H32ZM16.138 37.604a5.948 5.948 0 1 1 0-11.896 5.948 5.948 0 0 1 0 11.896Zm15.862 0a5.948 5.948 0 1 1 0-11.896 5.948 5.948 0 0 1 0 11.896Zm15.862 0a5.948 5.948 0 1 1 0-11.896 5.948 5.948 0 0 1 0 11.896Z"
    />
  ),
});

export const SportsIcon = createIcon({
  displayName: "SportsIcon",
  viewBox: "0 0 96 96",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.287 13.917c16.599 6.854 30.869 15.965 43.231 27.143l-.001.002.126.11-.125-.112C64.262 31 65.501 17.31 60.63 1.865 56.773.739 52.34.092 47.758.092c-13.046 0-24.87 5.249-33.47 13.748v.077Zm79.997 46.514a46.803 46.803 0 0 1-7.907 15.996v-.003c-2.275-3.87-4.709-7.622-7.185-11.295l-.137.08c4.463-2.823 9.63-4.63 15.307-5.11l-.078.332ZM80.986 82.734c-4.75 4.553-10.46 8.116-17.124 10.458h-.003l.006-.108a38.977 38.977 0 0 1 9.137-22.842l-.281-.41c2.838 3.924 5.478 8.005 8.265 12.902Zm0 0 .016-.014-.015.015ZM12.017 64.772a83.99 83.99 0 0 0 9.697.599h.003l-.117-.006c.832.039 1.674.06 2.518.06 12.98 0 24.848-4.766 33.883-12.589a132.455 132.455 0 0 1 9.859 11.137 47.738 47.738 0 0 0-11.975 31.216l.284-.042c-2.68.49-5.44.751-8.269.76-21.022-.012-38.88-13.566-45.416-32.75 3.102.685 6.287 1.224 9.931 1.654l-.398-.039Zm-9.533-1.614c-.226-.05-.45-.1-.675-.152l.667.129.008.023Zm65.376.815.045-.051-.045.05ZM58 52.836l-.009-.009.01.01Zm-5.59-5.706A140.354 140.354 0 0 0 9.776 20.677l-.952-.332C3.305 28.021 0 37.61 0 47.97v.038c.018 2.3.192 4.539.512 6.733l-.033-.266c3.542.97 7.889 1.823 12.325 2.386l.488.05c16.526 1.797 30.138-1.637 39.12-9.78Zm21.58 11.182a149.73 149.73 0 0 0-10.601-11.7c7.864-10.897 10.059-25.19 6.466-41.155l.267.126C85.46 13.537 95.953 29.542 96 48.007c0 .604-.048 1.247-.097 1.904-.051.688-.104 1.393-.104 2.087h-.042c-8.002.159-15.445 2.596-21.552 6.586l-.215-.272Zm-10.601-11.7-.003-.003.003.003Z"
    />
  ),
});

export const BetslipIcon = createIcon({
  displayName: "BetslipIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      d="M.001 3.549v7.12h7.12v49.786h6.214c.778-3.122 3.556-5.398 6.866-5.398a7.07 7.07 0 0 1 6.856 5.348l.01.048h9.974c.778-3.122 3.556-5.398 6.866-5.398a7.07 7.07 0 0 1 6.856 5.348l.01.048h6.16V10.665h7.066v-7.12L.001 3.549Zm35.546 37.334h-17.76v-5.334h17.76v5.334Zm10.668-14.214H17.789v-5.334h28.426v5.334Z"
    />
  ),
});

export const CasinoIcon = createIcon({
  displayName: "CasinoIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      d="M12.265 47.726.21 14.603a3.574 3.574 0 0 1 2.108-4.553l.024-.007 19.282-7.015a3.55 3.55 0 0 1 4.553 2.082l.008.024.694 1.92L12.69 46.073a8.9 8.9 0 0 0-.418 1.598l-.008.056ZM63.79 15.511 48.002 58.93a3.529 3.529 0 0 1-4.558 2.1l.024.009-21.948-8.001a3.58 3.58 0 0 1-2.124-4.585l-.008.024 15.787-43.39a3.555 3.555 0 0 1 4.559-2.126l-.024-.008 21.948 8a3.58 3.58 0 0 1 2.124 4.585l.008-.024v-.002ZM50.457 32.685l-1.386-3.254a1.789 1.789 0 0 0-2.333-.956l.012-.004-2.666 1.174a1.787 1.787 0 0 1-2.316-.948l-.004-.012-1.146-2.667a1.764 1.764 0 0 0-2.332-.93l.012-.004-3.28 1.386a1.738 1.738 0 0 0-.929 2.33l-.004-.01 3.92 9.255a1.816 1.816 0 0 0 2.359.928l-.012.005 9.227-3.947a1.737 1.737 0 0 0 .794-2.356l.004.01h.08Z"
    />
  ),
});

export const BrowseIcon = createIcon({
  displayName: "BrowseIcon",
  viewBox: "0 0 64 64",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h23.867c-3.947 3.173-7.014 7.333-8.827 12.107H0V0Zm0 51.867h41.707v12.106H0V51.867Zm13.36-25.92H0v12.106h17.867a28.537 28.537 0 0 1-4.48-12.106h-.027ZM57.014 38.32l6.987 10.96-8.08 5.173-7.12-11.146c-2.24.773-4.64 1.2-7.12 1.2-12.24 0-22.187-9.947-22.187-22.187 0-12.24 9.947-22.187 22.187-22.187 12.24 0 22.187 9.947 22.187 22.187 0 6.293-2.64 11.947-6.854 16ZM41.681 9.733c-6.933 0-12.587 5.654-12.587 12.587s5.654 12.587 12.587 12.587 12.587-5.654 12.587-12.587S48.614 9.733 41.68 9.733Z"
    />
  ),
});

export const NoEventsIcon = createIcon({
  displayName: "NoEventsIcon",
  viewBox: "0 0 80 80",
  path: (
    <>
      <path
        d="M69.5298 21.2739H58.4108C57.0786 21.2739 55.9986 22.3539 55.9986 23.6862V77.5877C55.9986 78.92 57.0786 80 58.4108 80H69.5298C70.8621 80 71.9421 78.92 71.9421 77.5877V23.6862C71.9421 22.3539 70.8621 21.2739 69.5298 21.2739Z"
        fill="#263742"
      />
      <path
        d="M45.5615 36.9571H34.4424C33.1102 36.9571 32.0302 38.0371 32.0302 39.3694V77.5877C32.0302 78.9199 33.1102 79.9999 34.4424 79.9999H45.5615C46.8937 79.9999 47.9737 78.9199 47.9737 77.5877V39.3694C47.9737 38.0371 46.8937 36.9571 45.5615 36.9571Z"
        fill="#263742"
      />
      <path
        d="M21.5892 52.2665H10.4702C9.13792 52.2665 8.05792 53.3465 8.05792 54.6787V77.5877C8.05792 78.92 9.13792 80 10.4702 80H21.5892C22.9215 80 24.0015 78.92 24.0015 77.5877V54.6787C24.0015 53.3465 22.9215 52.2665 21.5892 52.2665Z"
        fill="#263742"
      />
      <path
        d="M21.8054 54.9316C21.8054 51.7407 19.2187 49.154 16.0278 49.154C12.8369 49.154 10.2501 51.7407 10.2501 54.9316V71.2491C10.2501 74.44 12.8369 77.0268 16.0278 77.0268C19.2187 77.0268 21.8054 74.44 21.8054 71.2491V54.9316Z"
        fill="#334552"
      />
      <path
        d="M45.7777 38.1194C45.7777 34.9284 43.1909 32.3417 40 32.3417C36.8091 32.3417 34.2224 34.9284 34.2224 38.1194V71.1209C34.2224 74.3118 36.8091 76.8986 40 76.8986C43.1909 76.8986 45.7777 74.3118 45.7777 71.1209V38.1194Z"
        fill="#334552"
      />
      <path
        d="M69.746 21.9485C69.746 18.7575 67.1593 16.1708 63.9684 16.1708C60.7775 16.1708 58.1907 18.7575 58.1907 21.9485V71.0219C58.1907 74.2128 60.7775 76.7995 63.9684 76.7995C67.1593 76.7995 69.746 74.2128 69.746 71.0219V21.9485Z"
        fill="#334552"
      />
      <path
        d="M16.0279 46.3862C19.2573 46.3862 21.8752 43.7683 21.8752 40.5389C21.8752 37.3095 19.2573 34.6916 16.0279 34.6916C12.7985 34.6916 10.1806 37.3095 10.1806 40.5389C10.1806 43.7683 12.7985 46.3862 16.0279 46.3862Z"
        fill="#3C8725"
      />
      <path
        d="M16.0277 42.0786C17.7507 42.0786 19.1475 40.6819 19.1475 38.9589C19.1475 37.2358 17.7507 35.8391 16.0277 35.8391C14.3047 35.8391 12.9079 37.2358 12.9079 38.9589C12.9079 40.6819 14.3047 42.0786 16.0277 42.0786Z"
        fill="#69E244"
      />
      <path
        d="M22.33 32.3417L17.1462 27.1579L33.5883 10.7158L39.9892 17.1167L57.1059 0L62.2933 5.18743L39.9892 27.4879L33.5883 21.0833L22.33 32.3417Z"
        fill="#334552"
      />
    </>
  ),
});

export const FacebookIcon = createIcon({
  displayName: "FacebookIcon",
  viewBox: "0 0 96 96",
  path: (
    <>
      <path
        d="M95.94 47.97C95.94 21.467 74.473 0 47.97 0S0 21.467 0 47.97c0 22.486 15.47 41.374 36.397 46.59v-31.9h-9.894V47.97h9.894v-6.296c0-16.31 7.376-23.925 23.446-23.925 3.058 0 8.274.6 10.433 1.2v13.31c-1.14-.12-3.118-.18-5.516-.18-7.856 0-10.914 3-10.914 10.734v5.157h15.65l-2.698 14.69H53.846v32.98C77.591 92.762 96 72.555 96 48.03l-.06-.06Z"
        fill="#0866FF"
      />
      <path
        d="m66.738 62.66 2.699-14.69h-15.65v-5.157c0-7.735 3.057-10.733 10.913-10.733 2.458 0 4.437 0 5.516.18V18.948c-2.158-.6-7.375-1.2-10.433-1.2-16.01 0-23.446 7.556-23.446 23.926v6.296h-9.894v14.69h9.894v31.9c3.718.9 7.615 1.44 11.573 1.44a46.6 46.6 0 0 0 5.816-.36V62.66h13.012Z"
        fill="#fff"
      />
    </>
  ),
});

export const GoogleIcon = createIcon({
  displayName: "GoogleIcon",
  viewBox: "0 0 96 96",
  path: (
    <>
      <path
        fill="#4285F4"
        d="M48.205 19.732c.476 0 .942.033 1.4.083v-.033h42.204c2.188 0 3.958 1.771 3.958 3.958v48.458c0 2.188-1.77 3.958-3.958 3.958H5.932C3.745 76.15 1.974 74.379 1.974 72.192V23.734c0-2.188 1.771-3.958 3.958-3.958h41.732c.516 0 1.034.017 1.541.083Zm18.78 25.083l-15.02 8.708v-6.717H31.4v-8.25h15.075v-6.713h8.51v6.713h6.917v8.25h-6.917v6.717l-15.02-8.708Z"
      />
      <path
        fill="#FFFFFF"
        d="M62.372 33.896v6.713H47.299v8.25H62.373v6.717l15.02-8.708v6.717H69.38v8.25h-6.917v6.717l-15.02-8.708Z"
      />
      <path
        fill="#EA4335"
        d="M46.744 33.896v6.713H31.4v8.25h15.075v6.717H46.744v-8.25H31.4v-8.25h15.075v-6.713Z"
      />
      <path
        fill="#FBBC05"
        d="M38.318 19.732c.503 0 1.021-.017 1.541-.083h41.732c2.188 0 3.958 1.77 3.958 3.958v48.458c0 2.188-1.77 3.958-3.958 3.958H5.932c-2.188 0-3.958-1.77-3.958-3.958V23.734c0-2.188 1.77-3.958 3.958-3.958h41.732c.51-.066 1.024-.083 1.541-.083Z"
      />
      <path
        fill="#34A853"
        d="M38.318 19.732c-.516 0-1.034.017-1.541.083h41.732c2.188 0 3.958 1.77 3.958 3.958v48.458c0 2.188-1.77 3.958-3.958 3.958H5.932c-2.188 0-3.958-1.77-3.958-3.958V23.734c0-2.188 1.77-3.958 3.958-3.958h41.732c.51-.066 1.024-.083 1.541-.083Z"
      />
      <path
        fill="#FBBC05"
        d="M46.744 33.896H31.4v8.25h15.075v8.25h15.075v-8.25H62.373v-8.25H46.744v8.25Z"
      />
    </>
  ),
});

export const LineIcon = createIcon({
  displayName: "LineIcon",
  viewBox: "0 0 96 96",
  path: (
    <>
      <path
        d="M48.121 96c26.51 0 48-21.49 48-48s-21.49-48-48-48-48 21.49-48 48 21.49 48 48 48Z"
        fill="#06C755"
      />
      <path
        d="M80.161 45.23c0-14.334-14.394-26.018-32.04-26.018s-32.04 11.684-32.04 26.018c0 12.888 11.383 23.608 26.8 25.656 1.024.24 2.47.662 2.831 1.566.301.783.241 2.108.12 2.89 0 0-.36 2.29-.481 2.77-.12.784-.663 3.193 2.77 1.748 3.433-1.446 18.49-10.901 25.175-18.61 4.637-5.12 6.865-10.239 6.865-16.02Z"
        fill="#fff"
      />
      <path
        d="M69.501 53.48a.603.603 0 0 0 .602-.602V50.59a.603.603 0 0 0-.602-.603h-6.143V47.64h6.143a.603.603 0 0 0 .602-.603v-2.288a.603.603 0 0 0-.602-.602h-6.143v-2.35h6.143a.603.603 0 0 0 .602-.602v-2.288a.603.603 0 0 0-.602-.602h-9.034a.604.604 0 0 0-.602.602v13.972c0 .362.301.602.602.602h9.034Zm-33.365 0a.603.603 0 0 0 .602-.602V50.59a.603.603 0 0 0-.602-.603h-6.143V38.906a.603.603 0 0 0-.602-.602h-2.289a.604.604 0 0 0-.602.602v13.972c0 .362.301.602.602.602h9.034Zm5.481-15.176h-2.289a.602.602 0 0 0-.602.602v14.032c0 .333.27.603.602.603h2.289c.332 0 .602-.27.602-.602V38.905a.602.602 0 0 0-.602-.602Zm15.478 0h-2.289a.604.604 0 0 0-.602.602v8.311l-6.384-8.672v-.06h-2.289a.604.604 0 0 0-.602.602v13.972c0 .361.301.602.602.602h2.289a.603.603 0 0 0 .602-.602v-8.311l6.384 8.672.18.181h2.471a.604.604 0 0 0 .601-.602V39.026a.604.604 0 0 0-.602-.602l-.361-.12Z"
        fill="#06C755"
      />
    </>
  ),
});

export const TwitchIcon = createIcon({
  displayName: "TwitchIcon",
  viewBox: "0 0 96 96",
  path: (
    <>
      <path
        d="M82.378 44.593 68.69 58.281H55.002L42.987 70.297V58.28H27.565V6.875h54.813v37.718Z"
        fill="#fff"
      />
      <path
        d="M24.156 0 7 17.156v61.688h20.563V96l17.156-17.156h13.688L89.25 48V0H24.156Zm58.222 44.593L68.69 58.281H55.002L42.987 70.297V58.28H27.565V6.875h54.813v37.718Z"
        fill="#9146FF"
      />
      <path
        d="M72.156 18.89h-6.875v20.563h6.875V18.89Zm-18.89 0H46.39v20.563h6.875V18.89Z"
        fill="#9146FF"
      />
    </>
  ),
});

export const LiveIcon = createIcon({
  displayName: "LiveIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 14.5c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm-4.618-3.874l.943-.943C8.387 13.018 8.198 12.542 8.198 12c0-.542.189-1.018.528-1.437l-.943-.943c-.587.705-.883 1.559-.883 2.38 0 .821.296 1.675.883 2.38zm8.236-3.874c.542 0 1.018.189 1.437.528l.943-.943c-.705-.587-1.559-.883-2.38-.883s-1.675.296-2.38.883l.943.943c.419-.339.895-.528 1.437-.528zM12 14.802c-.339 0-.676-.129-.943-.396l-.943.943c.587.705 1.441 1.101 2.38 1.101s1.793-.396 2.38-1.101l-.943-.943c-.267.267-.604.396-.943.396zm0-7.604c.339 0 .676.129.943.396l.943-.943c-.587-.705-1.441-1.101-2.38-1.101s-1.793.396-2.38 1.101l.943.943c.267-.267.604-.396.943-.396z"
    />
  ),
});

export const BonusIcon = createIcon({
  displayName: "BonusIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M20 8h-1.586l.293-.293c.781-.781.781-2.047 0-2.828l-2.586-2.586c-.781-.781-2.047-.781-2.828 0L12 4.414 10.707 3.121c-.781-.781-2.047-.781-2.828 0L5.293 5.707c-.781.781-.781 2.047 0 2.828L5.586 8H4c-1.104 0-2 .896-2 2v10c0 1.104.896 2 2 2h16c1.104 0 2-.896 2-2V10c0-1.104-.896-2-2-2zM14.586 5.414L16.707 7.535 14.586 9.656 12.464 7.535 14.586 5.414zM7.414 7.535L9.535 5.414 11.656 7.535 9.535 9.656 7.414 7.535zM20 18c0 .553-.447 1-1 1H5c-.553 0-1-.447-1-1v-8h16v8z"
    />
  ),
});

export const AdobexdLogo = createIcon({
  displayName: "AdobexdLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z"
        fill="#470137"
      />
      <path
        d="M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z"
        fill="#FF61F6"
      />
    </g>
  ),
});

export const AtlassianLogo = createIcon({
  displayName: "AtlassianLogo",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        d="M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z"
        fill="#2684FF"
      />
      <path
        d="M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z"
        fill="#2684FF"
      />
    </g>
  ),
});

export const CartIcon = createIcon({
  displayName: "CartIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z"
    />
  ),
});

export const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
            />
          </g>
        </g>
      </g>
    </g>
  ),
});
export const CreativeTimLogo = createIcon({
  displayName: "CreativeTimLogo",
  viewBox: "0 0 100 100",
  path: (
    <g
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      version="1.1"
    >
      <g
        id="logo-creative-tim-black"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon"
          transform="translate(15.000000, 19.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M66.4346744,1.49018376e-13 L3.56968951,1.49018376e-13 C1.59820443,1.49018376e-13 -2.98036753e-13,1.59820443 -2.98036753e-13,3.56968951 L-2.98036753e-13,33.5035241 C-2.98036753e-13,35.4750091 1.59820443,37.0732136 3.56968951,37.0732136 L16.4677598,37.0732136 L16.4677598,66.4346744 C16.4666001,67.3810129 16.8417189,68.288989 17.5104719,68.9585615 C18.1792249,69.628134 19.0867408,70.0043639 20.03308,70.0043639 L49.9669146,70.0043639 C51.9383997,70.0043639 53.5366041,68.4061595 53.5366041,66.4346744 L53.5366041,37.0863214 L66.4346744,37.0863214 C67.3810136,37.0863221 68.2885296,36.7100915 68.9572826,36.0405189 C69.6260355,35.3709464 70.0011544,34.4629704 70,33.5166319 L70,3.56968951 C70.0011544,2.62335099 69.6260355,1.71537495 68.9572826,1.04580242 C68.2885296,0.37622988 67.3810136,1.49018376e-13 66.4346744,1.49018376e-13 Z M4.13769396,4.14206323 L32.9311503,4.14206323 L32.9311503,32.9355196 L4.13769396,32.9355196 L4.13769396,4.14206323 Z M49.3989102,65.86667 L20.6054538,65.86667 L20.6054538,37.0863214 L49.3989102,37.0863214 L49.3989102,65.86667 Z M65.8623007,32.9355196 L37.0688443,32.9355196 L37.0688443,4.14206323 L65.8623007,4.14206323 L65.8623007,32.9355196 Z"
            id="Shape"
          />
          <path
            d="M23.6988934,23.3056595 C24.5142794,24.0708897 25.7898859,24.0506562 26.5805917,23.2599503 C27.3712976,22.4692445 27.3915311,21.193638 26.6263009,20.378252 L20.015603,13.767554 C19.2050095,12.9645312 17.8987889,12.9645312 17.0881954,13.767554 L10.4600204,20.378252 C9.90907005,20.8953125 9.68342943,21.6713831 9.87125954,22.4032422 C10.0590897,23.1351013 10.6305787,23.7065903 11.3624377,23.8944204 C12.0942968,24.0822505 12.8703675,23.8566099 13.387428,23.3056595 L18.5344222,18.1586654 L23.6988934,23.3056595 Z"
            id="Path"
          />
          <path
            d="M46.3185783,23.3056595 L51.4655725,18.1586654 L56.6125667,23.3056595 C57.4279526,24.0708897 58.7035592,24.0506562 59.494265,23.2599503 C60.2849709,22.4692445 60.3052044,21.193638 59.5399742,20.378252 L52.9292763,13.767554 C52.1186828,12.9645312 50.8124622,12.9645312 50.0018687,13.767554 L43.3911708,20.378252 C42.6259406,21.193638 42.6461741,22.4692445 43.43688,23.2599503 C44.2275858,24.0506562 45.5031924,24.0708897 46.3185783,23.3056595 L46.3185783,23.3056595 Z"
            id="Path"
          />
          <path
            d="M29.8508185,46.6987044 C29.0342976,45.9346441 27.7586832,45.9567597 26.9691385,46.7486649 C26.1795937,47.54057 26.1612859,48.8162446 26.9277802,49.6304812 L33.5384782,56.2368099 C33.9262423,56.6258256 34.452915,56.8444779 35.002182,56.8444779 C35.5514489,56.8444779 36.0781217,56.6258256 36.4658857,56.2368099 L43.0765837,49.6304812 C43.6002952,49.1075502 43.8052442,48.3449453 43.6142284,47.6299323 C43.4232126,46.9149194 42.8652519,46.3561259 42.1505246,46.1640439 C41.4357974,45.971962 40.6728876,46.1757734 40.1491761,46.6987044 L35.002182,51.8500678 L29.8508185,46.6987044 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  ),

  //   <chakra.svg
  //   height="50px"
  //   width="50px"
  //   viewBox="0 0 100 100"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   color={fill}
  //   {...props}
  // >
  // </chakra.svg>
});

export const CreditIcon = createIcon({
  displayName: "CreditIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z"
    />
  ),
});

export const DashboardLogo = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 1000 257",
  path: (
    // eslint-disable-next-line react/no-unknown-property
    <g width="998" height="257" viewBox="0 0 998 257" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z"
          fill="currentColor"
        />
        <path
          d="M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z"
          fill="currentColor"
        />
        <path
          d="M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z"
          fill="currentColor"
        />
        <path
          d="M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z"
          fill="currentColor"
        />
        <path
          d="M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z"
          fill="currentColor"
        />
        <path
          d="M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z"
          fill="currentColor"
        />
        <rect width="257" height="257" rx="128.5" fill="#4FD1C5" />
        <path
          d="M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="128.5"
          y1="0"
          x2="128.5"
          y2="257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7BCBD4" />
          <stop offset="1" stopColor="#29C6B7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="997.832" height="257" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export const DashboardLogoWhite = createIcon({
  displayName: "DashboardLogo",
  viewBox: "0 0 163.5 42",
  path: (
    <g fill="none">
      <path
        d="M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z"
        fill="#fff"
      />
      <path
        d="M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z"
        fill="#3BCBBE"
      />
    </g>
  ),
});

export const DocumentIcon = createIcon({
  displayName: "DocumentIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M18.809 10.344h-6.153a2.11 2.11 0 01-2.11-2.11V2.083a.176.176 0 00-.175-.176H6.328A2.812 2.812 0 003.516 4.72v14.063a2.812 2.812 0 002.812 2.812h9.844a2.812 2.812 0 002.812-2.813V10.52a.176.176 0 00-.175-.176z"
      />
      <path
        fill="currentColor"
        d="M18.423 8.789l-6.32-6.32a.088.088 0 00-.15.062v5.705a.703.703 0 00.703.703h5.705a.088.088 0 00.062-.15z"
      />
    </g>
  ),
});

export const GlobeIcon = createIcon({
  displayName: "GlobeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109a9.14 9.14 0 100 18.281 9.14 9.14 0 000-18.281z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109C8.698 2.109 6.3 6.2 6.3 11.249c0 5.048 2.4 9.14 4.951 9.14 2.552 0 4.951-4.092 4.951-9.14 0-5.048-2.399-9.14-4.95-9.14z"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904M17.344 17.344c-1.68-1.193-3.796-1.904-6.094-1.904-2.298 0-4.413.711-6.094 1.904"
        strokeWidth=".75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        fill="transparent"
        d="M11.25 2.109v18.28M20.39 11.249H2.11"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
    </g>
  ),
});

export const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z"
    />
  ),
});

export const HomeIcon = createIcon({
  displayName: "HomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M11.494 4.951a.351.351 0 00-.486 0l-8.09 7.729a.352.352 0 00-.109.254v7.254a1.406 1.406 0 001.405 1.406h4.223a.703.703 0 00.704-.703v-5.976a.351.351 0 01.351-.352h3.516a.351.351 0 01.351.352v5.976a.703.703 0 00.704.703h4.22a1.407 1.407 0 001.407-1.406v-7.254a.35.35 0 00-.108-.254L11.494 4.95z"
      />
      <path
        fill="currentColor"
        d="M21.574 11.23l-3.287-3.144V3.314a.703.703 0 00-.703-.703h-2.11a.703.703 0 00-.703.703V4.72l-2.545-2.434c-.239-.24-.593-.378-.976-.378-.38 0-.734.138-.972.379L.93 11.23a.717.717 0 00-.058.983.703.703 0 001.018.046l9.119-8.713a.352.352 0 01.486 0l9.12 8.713a.703.703 0 00.992-.019c.27-.28.248-.74-.033-1.01z"
      />
    </g>
  ),
});

export const InvisionLogo = createIcon({
  displayName: "InvisionLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M18.687 0H2.313A1.813 1.813 0 00.5 1.811v16.374C.5 19.188 1.312 20 2.313 20h16.374a1.813 1.813 0 001.813-1.813V1.812A1.813 1.813 0 0018.687 0z"
        fill="#DC395F"
      />
      <path
        d="M7.184 6.293c.665 0 1.222-.522 1.222-1.204 0-.683-.557-1.204-1.222-1.204-.665 0-1.222.521-1.222 1.204 0 .682.557 1.204 1.222 1.204zM4.65 12.739a4.134 4.134 0 00-.108.905c0 1.06.575 1.764 1.797 1.764 1.013 0 1.834-.602 2.426-1.573l-.361 1.449h2.012l1.15-4.612c.287-1.168.844-1.774 1.689-1.774.665 0 1.078.413 1.078 1.096 0 .197-.018.413-.09.646l-.593 2.12c-.09.306-.126.611-.126.899 0 1.006.593 1.742 1.833 1.742 1.06 0 1.904-.682 2.371-2.317l-.79-.305c-.395 1.095-.737 1.293-1.006 1.293-.27 0-.414-.18-.414-.538 0-.162.037-.342.09-.558l.575-2.065c.144-.485.198-.915.198-1.31 0-1.546-.934-2.352-2.066-2.352-1.06 0-2.138.956-2.677 1.962l.395-1.806H8.962L8.53 8.996h1.438l-.885 3.544c-.695 1.545-1.972 1.57-2.132 1.534-.264-.06-.432-.159-.432-.5 0-.198.036-.482.126-.823l1.348-5.346H4.579l-.431 1.591h1.419L4.65 12.74"
        fill="#fff"
      />
    </g>
  ),
});

export const JiraLogo = createIcon({
  displayName: "JiraLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M20.26 10.42l-8.863-8.93-.86-.865-6.67 6.722L.814 10.42a.827.827 0 000 1.162l6.096 6.14 3.627 3.654 6.67-6.722.105-.104 2.947-2.964a.824.824 0 000-1.166zm-9.722 3.649L7.493 11l3.045-3.068L13.582 11l-3.044 3.068z"
        fill="#2684FF"
      />
      <path
        d="M10.537 7.932a5.184 5.184 0 01-1.502-3.637A5.185 5.185 0 0110.515.65L3.852 7.36l3.626 3.654 3.06-3.082z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.59 10.992l-3.053 3.076a5.186 5.186 0 011.502 3.653c0 1.37-.54 2.683-1.502 3.652l6.682-6.728-3.63-3.653z"
        fill="url(#paint1_linear)"
      />
    </g>
  ),
});

export const MastercardIcon = createIcon({
  displayName: "MastercardIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="24"
      height="20"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.63158" cy="7.49999" r="6.63158" fill="#EB001B" />
      <circle cx="14.3686" cy="7.49999" r="6.63158" fill="#F79E1B" />
    </svg>
  ),
});

export const PayPalIcon = createIcon({
  displayName: "PayPalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M16.179 2.532C15.142 1.357 13.265.854 10.863.854H3.895a.997.997 0 00-.986.834L.007 19.945a.595.595 0 00.591.686H4.9l1.08-6.8-.033.215a.993.993 0 01.983-.834h2.044c4.017 0 7.16-1.619 8.08-6.3.027-.14.07-.406.07-.406.262-1.732-.001-2.907-.945-3.974z"
      />
      <path
        fill="currentColor"
        d="M18.213 7.393c-.998 4.61-4.184 7.048-9.24 7.048H7.142L5.773 23.1h2.973a.87.87 0 00.862-.731l.035-.184.684-4.297.044-.237a.87.87 0 01.86-.731h.544c3.514 0 6.264-1.416 7.068-5.51.322-1.644.166-3.021-.63-4.017z"
      />
    </g>
  ),
});

export const PersonIcon = createIcon({
  displayName: "PersonIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M14.618 3.338c-.855-.924-2.05-1.432-3.368-1.432-1.325 0-2.524.505-3.375 1.423-.86.928-1.28 2.188-1.181 3.55.195 2.686 2.239 4.87 4.556 4.87s4.358-2.184 4.556-4.87c.1-1.349-.322-2.607-1.188-3.541zM18.984 21.592H3.515a1.363 1.363 0 01-1.063-.489 1.552 1.552 0 01-.316-1.279c.371-2.058 1.529-3.786 3.348-5 1.616-1.076 3.664-1.67 5.766-1.67s4.15.594 5.765 1.67c1.82 1.214 2.977 2.942 3.348 5 .085.471-.03.937-.315 1.279a1.362 1.362 0 01-1.064.49z"
    />
  ),
});

export const ProfileIcon = createIcon({
  displayName: "ProfileIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path d="M0 0h24v24H0V0z" fill="transparent" />
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
      />
    </g>
  ),
});

export const RocketIcon = createIcon({
  displayName: "RocketIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z"
      />
      <path
        fill="currentColor"
        d="M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z"
      />
    </g>
  ),
});

export const SettingsIcon = createIcon({
  displayName: "SettingsIcon",
  viewBox: "0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
      />
    </g>
  ),
});

export const SlackLogo = createIcon({
  displayName: "SlackLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z"
        fill="#36C5F0"
      />
      <path
        d="M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z"
        fill="#2EB67D"
      />
      <path
        d="M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z"
        fill="#ECB22E"
      />
      <path
        d="M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z"
        fill="#E01E5A"
      />
    </g>
  ),
});

export const SpotifyLogo = createIcon({
  displayName: "SpotifyLogo",
  viewBox: "0 0 24 24",
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M10.5 0C4.977 0 .5 4.477.5 10s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm4.586 14.422a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.216c3.808-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857zm1.223-2.722a.78.78 0 01-1.072.257c-2.687-1.652-6.786-2.13-9.965-1.166a.78.78 0 01-.973-.519.781.781 0 01.52-.972c3.632-1.102 8.147-.569 11.233 1.329a.78.78 0 01.258 1.072zm.106-2.835C13.19 6.95 7.875 6.775 4.797 7.708a.935.935 0 11-.543-1.79c3.533-1.072 9.404-.865 13.115 1.338a.935.935 0 01-.954 1.609z"
        fill="#2EBD59"
      />
    </g>
  ),
});

export const SupportIcon = createIcon({
  // Doesn't display the full icon without w and h being specified
  displayName: "BuildIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M20.885 5.547a.703.703 0 00-1.122-.176l-2.7 2.702a.708.708 0 01-.995 0l-1.167-1.169a.702.702 0 010-.994l2.689-2.69a.704.704 0 00-.21-1.138c-2.031-.908-4.566-.435-6.164 1.152-1.358 1.348-1.763 3.455-1.11 5.78a.698.698 0 01-.197.703L2.593 16.4a2.82 2.82 0 103.981 3.983l6.754-7.332a.699.699 0 01.693-.2 7.885 7.885 0 002.03.279c1.469 0 2.757-.475 3.686-1.39 1.72-1.695 1.983-4.57 1.148-6.192zM4.623 19.901a1.407 1.407 0 11-.305-2.797 1.407 1.407 0 01.305 2.797z"
    />
  ),
});

export const StatsIcon = createIcon({
  displayName: "StatsIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M4.57 22.297H3.164a1.055 1.055 0 01-1.055-1.054v-6.328a1.055 1.055 0 011.055-1.055H4.57a1.055 1.055 0 011.055 1.055v6.328a1.055 1.055 0 01-1.055 1.054zM14.414 22.296h-1.406a1.055 1.055 0 01-1.055-1.055V10.695a1.055 1.055 0 011.055-1.055h1.406a1.055 1.055 0 011.055 1.055V21.24a1.055 1.055 0 01-1.055 1.055zM19.336 22.297H17.93a1.055 1.055 0 01-1.055-1.055V5.773A1.055 1.055 0 0117.93 4.72h1.406a1.055 1.055 0 011.055 1.054v15.47a1.055 1.055 0 01-1.055 1.054zM9.492 22.297H8.086a1.055 1.055 0 01-1.055-1.055V2.257a1.055 1.055 0 011.055-1.054h1.406a1.055 1.055 0 011.055 1.054v18.985a1.055 1.055 0 01-1.055 1.055z"
    />
  ),
});

export const WalletIcon = createIcon({
  displayName: "WalletIcon",
  viewBox: "0 0 24 24",
  path: (
    <g>
      <path
        fill="currentColor"
        d="M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z"
      />
      <path
        fill="currentColor"
        d="M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z"
      />
    </g>
  ),
});

export const VisaIcon = createIcon({
  displayName: "VisaIcon",
  viewBox: "0 0 24 24",
  path: (
    <svg
      width="26"
      height="20"
      viewBox="0 0 30 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6708 2.96349C12.6568 4.15055 13.653 4.81298 14.4034 5.20684C15.1744 5.61099 15.4333 5.87013 15.4304 6.23147C15.4245 6.78459 14.8153 7.02862 14.2452 7.03812C13.2505 7.05475 12.6723 6.74889 12.2125 6.51753L11.8542 8.32341C12.3155 8.55247 13.1696 8.75217 14.0554 8.76087C16.1345 8.76087 17.4947 7.65543 17.5021 5.94145C17.5102 3.76625 14.7087 3.64579 14.7278 2.67348C14.7344 2.37871 14.9956 2.06408 15.568 1.98406C15.8512 1.94368 16.6332 1.91274 17.5198 2.35252L17.8677 0.605286C17.391 0.418253 16.7782 0.239136 16.0153 0.239136C14.0583 0.239136 12.6819 1.35962 12.6708 2.96349ZM21.2115 0.389687C20.8319 0.389687 20.5118 0.628245 20.3691 0.99433L17.3991 8.63249H19.4767L19.8901 7.40183H22.429L22.6689 8.63249H24.5L22.9021 0.389687H21.2115ZM21.5021 2.61641L22.1016 5.7116H20.4596L21.5021 2.61641ZM10.1518 0.389687L8.51418 8.63249H10.4939L12.1308 0.389687H10.1518ZM7.22303 0.389687L5.16233 6.00003L4.32878 1.22966C4.23097 0.697187 3.84472 0.389687 3.41579 0.389687H0.0471011L0 0.629037C0.691574 0.790671 1.47729 1.0514 1.95326 1.33033C2.24457 1.50067 2.32775 1.64964 2.42336 2.05458L4.00214 8.63249H6.0945L9.3021 0.389687H7.22303Z"
        fill="#4318FF"
      />
    </svg>
  ),
});

export const RaceIcon = createIcon({
  displayName: "RaceIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M64.688 54.312c37.121-4.57 30.353-38.63 30.353-38.63H78.354c.628-4.697.991-10.138 1.005-15.665V0H16.64c.014 5.54.377 10.982 1.073 16.32l-.068-.641H.958S-5.807 49.69 31.31 54.309c2.62 3.87 5.51 7.244 8.743 10.254l.034.031c-.411 5.579-2.65 21.624-15.59 21.624h-7.861V96h62.719v-9.782l-10.88-.367C58.192 83.52 56.272 69.67 55.905 64.594a59.158 59.158 0 0 0 8.647-10.08l.13-.205.007.003ZM75.43 30.267l2.194-5.44h8.503c-.33 4.638-1.995 8.831-4.611 12.26l.04-.055a22.158 22.158 0 0 1-10.542 6.682l-.158.038c1.735-3.789 3.302-8.283 4.457-12.94l.117-.548v.003Zm-60.984 6.765a22.653 22.653 0 0 1-4.567-12.134l-.003-.072h8.41l2.287 5.441c1.395 5.322 3.051 9.882 5.064 14.246l-.22-.531c-4.409-1.235-8.143-3.665-10.947-6.92l-.024-.027v-.003Zm40.78 3.977-6.402-3.11a1.712 1.712 0 0 0-.823-.205c-.301 0-.586.075-.833.212l.01-.003-6.4 3.11a1.604 1.604 0 0 1-2.243-1.656v.006l1.005-7.176a1.547 1.547 0 0 0-.411-1.282l-4.846-5.256a1.56 1.56 0 0 1 .813-2.606h.01l7.176-1.326c.498-.055.916-.35 1.139-.768l.003-.007 3.429-6.401a1.555 1.555 0 0 1 2.74-.007l.003.007 3.428 6.4c.216.416.617.71 1.09.776h.007l7.176 1.326a1.555 1.555 0 0 1 .823 2.606l-5.03 5.256a1.548 1.548 0 0 0-.425 1.07c0 .075.007.147.014.22v-.008l1.005 7.176a1.6 1.6 0 0 1-2.475 1.646l.006.004.01-.004Z"
    />
  ),
});

export const RouletteIcon = createIcon({
  displayName: "RouletteIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12 2a10 10 0 1 0 10 10A10.01 10.01 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8zm-1-13h2v2h-2zm0 4h2v6h-2zm-1-7h2v1h-2zm-2 2h2v2h-2zm0 4h2v6h-2zm-2-3h2v1h-2zm11.5 5h-1.06a5.002 5.002 0 0 0 0-4h1.06a5.006 5.006 0 0 0 0 4zm-8.88-1.12a4.985 4.985 0 0 0 2.7-1.62A5.02 5.02 0 0 0 12 12a5.01 5.01 0 0 0 2.69-.74A4.985 4.985 0 0 0 17 10h-1.06a5.002 5.002 0 0 0 0 4H17z"
    />
  ),
});
