import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Image,
  Input,
  Heading,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import providersData from "data/Providers/providers.json";

const Providers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");

  // Function to filter providers by name based on search term
  const filteredProviders = providersData.filter((provider) =>
    provider.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box bg={bgColor} minH="100vh" py={10} px={8}>
      {/* Heading */}
      <Heading size="lg" mb={6}>
        Providers
      </Heading>

      {/* Search Input */}
      <Flex justify="center" mb={6}>
        <Input
          placeholder="Search your game"
          maxW="400px"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Flex>

      {/* Providers Grid */}
      <SimpleGrid columns={{ base: 2, md: 4, lg: 6 }} spacing={6}>
        {filteredProviders.map((provider, index) => (
          <Box
            key={index}
            bg={cardBg}
            p={4}
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
            border="1px solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <Image
              src={provider.logo}
              alt={`${provider.name} logo`}
              boxSize="50px"
              mx="auto"
              mb={4}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Providers;
