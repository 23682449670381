import React from "react";
import { Box, Image, Text, Flex, IconButton, Button, Heading, UnorderedList, ListItem,SimpleGrid,useColorModeValue} from "@chakra-ui/react";
import { FaFacebookF, FaTimes } from "react-icons/fa";
import BlogImage from "assets/img/blog/blog-image.webp";
import { NavLink } from "react-router-dom";
import DiceImage from "assets/img/blog/dice-image.webp";
import PokerImage from "assets/img/blog/Poker-image.webp";
import BounsImage from "assets/img/blog/Bouns-image.webp";


export default function Strategy() {

  const tabBg = useColorModeValue("gray.100", "gray.700");
  const bg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const buttonbg = useColorModeValue("gray.200", "gray.900");
  const textColor2 = "gray.500";

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "65px" }} >


      {/* Text Content */}
      <Box pl={{ base: 0, md: 6, lg: 150 }} pr={{ base: 0, md: 6, lg: 150 }} mb={4}>
        <Heading>What is the 3-Bet Poker Strategy?</Heading>
        <Box mb={6} mt={6}>
          <Flex justify="space-between" alignItems="center">
            <Text mb={0} fontSize={{ base: 'md', md: 'md', lg: 'xl' }} color={"gray.600"}>
              Wager Street - September 12, 2024
            </Text>

            {/* Action Buttons */}
            <Flex>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  borderRadius={100}
                  aria-label="Share on Facebook"
                  icon={<FaFacebookF />}
                  colorScheme="gray"
                  variant="solid"
                  mr={4}
                />
              </a>

              <a
                href="https://x.com/intent/post?u=YOUR_URL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  borderRadius={100}
                  aria-label="Close"
                  icon={<FaTimes />}
                  colorScheme="gray"
                  variant="solid"
                />
              </a>


            </Flex>
          </Flex>
        </Box>

        <Image
          src={BlogImage}
          alt="Featured Blog"
          borderRadius="md"
          maxWidth={{ base: "100%", md: "100%" }}
          transition="all 0.2s ease-in-out"
          _hover={{ transform: "translateY(-10px)" }}
          mb={10}
        />
        <Box mt={4}>
          {/* Main Article Section */}
          <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
            What is a 3-Bet Poker Strategy? - Betting Ranges & Strategies Explained
          </Heading>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            <strong>Online poker</strong> is fairly simple to understand, but its depth of strategies and game dynamics make it complex and extremely interesting to play. One such strategy that has become more popular in online poker is 3-betting.

          </Text>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            In this <strong>how to guide </strong>, we’ll explain what the 3-bet strategy is and how to use it at <strong>Wager Street Casino </strong>.
          </Text>
        </Box>


        <Box mt={4}>
          {/* Main Article Section */}
          <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
            Basics of Online Poker
          </Heading>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            In poker, the ultimate goal is to beat your opponents by having a stronger hand made up of your hole cards and the community cards. Betting rounds after each set of cards is dealt give players a chance to add money to the pot, and the winning player takes the pot at the end. Find more details on <strong>how to play poker</strong> which you can implement when playing rounds in <strong>Wager Street Poker</strong>, and <strong>Wager Street Poker Tournaments!</strong>

          </Text>
        </Box>
        <Box mt={4}>
          {/* Main Article Section */}
          <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
            What is the 3-Bet Poker Strategy?
          </Heading>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            n simple terms, a 3-bet is the third bet made in a betting round. After the blinds are made, poker players can either call or raise. The original raise is known as the 2-bet, and if a player raises after this, it’s a 3-bet. This is an aggressive move, typically done by players with strong hands. A player that raises after an initial raise is signalling to the rest of the poker table that they have a strong range, likely causing opponents to fold.

          </Text>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            Although most players will only 3-bet when they have a strong hand, it can be an effective strategy for bluffing too. Unless opponents have reason to suspect your buffing or have a strong hand themselves, they’re likely to fold. This can be a great strategy for winning pots pre-flop, though it has the potential to backfire if you’re not careful with speculative hands
          </Text>
        </Box>

        <Box mt={4}>
          {/* Main Article Section */}
          <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
            3-Betting Example
          </Heading>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            Let’s say you’re playing a game of online poker at <strong>WagerStreet.net</strong> with $1 small blind and $2 big blind. You’re in position, so you have an advantage over your opponents, and you hold a king of spades and queen of spades.

          </Text>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            All other opponents fold except for the player at the cut-off, who makes an initial raise to $6. You then 3-bet to $18, and your opponent calls. After the flop, your opponent checks, and you keep the aggression up by raising to $24, forcing them to fold.
          </Text>
        </Box>


        <Box mt={4}>
          {/* Main Article Section */}
          <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
            What Are the Best 3-Betting Ranges?
          </Heading>
          <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            3-betting is an aggressive strategy, and you need to carefully consider your range before you proceed with your bet and choose your bet size. Here are some examples of ranges you can use when 3-betting:
          </Text>
          <UnorderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
            <ListItem>Strong range – Typically, 3-betting should be reserved for the strongest hands, reducing your risk and ensuring you maximise the value from your hand. However, this can make weaker players more predictable and prevent them from building the pot effectively. As a result, lots of players prefer to use a merged or polarised range.</ListItem>
            <ListItem>Polarised range – The ideal 3-betting strategy should mix strong hands with the occasional weaker hand, with bluffing helping to increase your profits long term. However, make sure your weaker hands are at least suited connectors to give you more potential post-flop if opponents call your 3-bet.</ListItem>
          </UnorderedList>
        </Box>
        <NavLink to='/casino'>
          <Button fontSize={{ base: 'md', md: 'md', lg: 'xl' }} borderRadius={30} bg={buttonbg} p={6}>Casino</Button>
        </NavLink>
      </Box>
      <Box bg={bg}>
        {/* Blog Posts Section */}
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
          <Box  borderRadius="md" p="4">
            <Image
              src={DiceImage}
              alt="UFC Picks & Expert UFC Predictions"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
              How to play Dise at Stack
            </Text>
            <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
              Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
              Merab Dvalishvili and the full UFC fight card! Discover the best MMA
              odds on all UFC fights and place your bets.
            </Text>
          </Box>

          <Box  borderRadius="md" p="4">
            <Image
              src={PokerImage}
              alt="2024 MLB Predictions & World Series Picks"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
              How to play Video Poker Dise at Stack
            </Text>
            <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
              MLB predictions for the 2024 season are here, with baseball picks to
              win from our expert tipsters. See which team is predicted to win the
              World Series and make your selections on our Sportsbook!
            </Text>
          </Box>

          <Box  borderRadius="md" p="4">
            <Image
              src={BounsImage}
              alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
              Slot Machine Bouns Game
            </Text>
            <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
              We&apos;re wrapping up the week by looking at top sporting events,
              22 new-release games &amp; fresh bet bonuses and promos! Find out
              how to join the latest casino competitions today!
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
