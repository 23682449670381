import React, { createContext, useState, useEffect, useContext } from 'react';

// Create WebSocket context
const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [ws, setWs] = useState(null);
  const [isWsReady, setIsWsReady] = useState(false);  // New state for WebSocket readiness
  const [wsSportsData, setWsSportsData] = useState({});
  const [wsEventsData, setWsEventsData] = useState({});
  const [wsEventDetails, setWsEventDetails] = useState({});
  const [wsCountriesData, setWsCountriesData] = useState({});
  const [wsLeaguesData, setWsLeaguesData] = useState({});

  useEffect(() => {
    const connectWebSocket = () => {
      console.log('Initializing WebSocket...');

      const websocket = new WebSocket("wss://sea-turtle-app-iudng.ondigitalocean.app");

      websocket.onopen = () => {
        console.log('WebSocket connected');
        setIsWsReady(true);  // Set the WebSocket as ready
        websocket.send(JSON.stringify({ command: 'sports' }));
      };

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received data:', data);

        if (data.sports) {
          setWsSportsData(data.sports);
        }
        if (data.events) {
          setWsEventsData(data);
        }
        if (data.event) {
          setWsEventDetails(data);
        }
        if (data.countries) {
          setWsCountriesData((prev) => ({
            ...prev,
            [data.sportId]: data.countries,
          }));
        }
        if (data.leagues) {
          setWsLeaguesData((prev) => {
            const existingLeagues = prev[data.request] || [];
            const existingLeaguesMap = existingLeagues.reduce((acc, league) => ({ ...acc, ...league }), {});
            const newLeagues = { ...existingLeaguesMap, ...data.leagues };
            return { ...prev, [data.request]: [newLeagues] };
          });
        }
      };

      websocket.onerror = (error) => {
        console.error('WebSocket error:', error.message || error);
      };

      websocket.onclose = (event) => {
        console.log('WebSocket closed:', event);
        setIsWsReady(false);  // Reset readiness when WebSocket closes
        if (event.code !== 1000) {
          console.log('Reconnecting in 5 seconds...');
          setTimeout(connectWebSocket, 3000);
        }
      };

      setWs(websocket);

      return () => {
        console.log('Closing WebSocket...');
        if (websocket) websocket.close(); // Close the websocket on cleanup
      };
    };

    connectWebSocket(); // Establish the WebSocket connection

    return () => {
      if (ws) ws.close();
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  const fetchCountries = (sportId) => {
    console.log('Fetching countries for sportId:', sportId);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ command: 'countries', sportId }));
    } else {
      console.log("WebSocket not ready");
    }
  };

  const fetchLeagues = (sportId, countryId) => {
    console.log('Fetching Leagues for sportId:', sportId, 'and countryId:', countryId);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ command: 'leagues', sportId, countryId }));
    } else {
      console.log("WebSocket not ready");
    }
  };

  const fetchEvents = (sportId, leagueId) => {
    console.log('Fetching Events for sportId:', sportId, 'and leagueId:', leagueId);
    
    const retryFetch = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ command: 'leagueEvents', sportId, leagueId }));
      } 
    };
    
    retryFetch();
  };
  

  const fetchEventDetails = (eventId) => {
    console.log('Fetching Event details for eventId:', eventId);
    
    const retryFetch = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ command: 'event', eventId }));
      } 
    };
    
    retryFetch();
  };
  

  return (
    <WebSocketContext.Provider
      value={{
        wsSportsData,
        wsEventsData,
        wsEventDetails,
        wsCountriesData,
        wsLeaguesData,
        isWsReady,
        fetchCountries,
        fetchLeagues,
        fetchEvents,
        fetchEventDetails,
        sendCommand: (command, payload) => {
          ws && ws.send(JSON.stringify({ command, ...payload }));
        },
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);