import React from "react";
import { Box, Image, Text, Flex, IconButton, Button, Heading, UnorderedList, ListItem, SimpleGrid, useColorModeValue, OrderedList, Divider } from "@chakra-ui/react";
import { FaFacebookF, FaTimes } from "react-icons/fa";
import BlogImage from "assets/img/blog/blog-image.webp";
import { NavLink } from "react-router-dom";
import DiceImage from "assets/img/blog/dice-image.webp";
import PokerImage from "assets/img/blog/Poker-image.webp";
import BounsImage from "assets/img/blog/Bouns-image.webp";
import UfcPicks from "assets/img/blog/ufc-picks-blog image.webp"
import Newgame from "assets/img/blog/new-games-1-blog-image.webp"


export default function New_Game() {

    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");
    const buttonbg = useColorModeValue("gray.200", "gray.900");
    const textColor2 = "gray.500";

    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "100px" }} >
            {/* Text Content */}
            <Box pl={{ base: 0, md: 6, lg: 150 }} pr={{ base: 0, md: 6, lg: 150 }} mb={4}>
                <Heading>New Games on Wager Street: May 17th 2024 Casino Releases & Sports Promos</Heading>
                <Box mb={6} mt={6}>
                    <Flex justify="space-between" alignItems="center">
                        <Text mb={0} fontSize={{ base: 'md', md: 'md', lg: 'xl' }} color={"gray.600"}>
                        Wager Street - May 17, 2024
                        </Text>

                        {/* Action Buttons */}
                        <Flex>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconButton
                                    borderRadius={100}
                                    aria-label="Share on Facebook"
                                    icon={<FaFacebookF />}
                                    colorScheme="gray"
                                    variant="solid"
                                    mr={4}
                                />
                            </a>

                            <a
                                href="https://x.com/intent/post?u=YOUR_URL"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconButton
                                    borderRadius={100}
                                    aria-label="Close"
                                    icon={<FaTimes />}
                                    colorScheme="gray"
                                    variant="solid"
                                />
                            </a>


                        </Flex>
                    </Flex>
                </Box>

                <Image
                    src={Newgame}
                    alt="Featured Blog"
                    borderRadius="md"
                    maxWidth={{ base: "100%", md: "100%" }}
                    transition="all 0.2s ease-in-out"
                    _hover={{ transform: "translateY(-10px)" }}
                    mb={10}
                />
                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                    Weekly Wrap-Up: New & Exclusive Slots & Sports Betting Bonuses
                    </Heading>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Join thousands of players and win big on WagerStreet.net this week, with amazing promotions and bonuses you want to miss!

                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Head on over to our sportsbook for the latest markets on major sports, including NHL , NBA , cricket , Premier League , Formula 1 , with live streams available right here, and all for free.

                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    If a slot lover, our online casino offers the very best slots with jackpots just waiting to be won. forget to check out our live casino games and table games , from blackjack to baccarat , and more.

                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Discover what hot this week on Wager Street!

                    </Text>


                </Box>


                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                    New Game Releases May 2024
                    </Heading>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Weve got slot machines that have exciting bonus features and exclusive games you wont find anywhere else
                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Whether you love themes like dragons and fantasy , or prefer classic joker slots with gem symbols, you will find it all with our newest games..
                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Explore our games for free in demo mode , or bet with your favourite crypto or local currency .
                    </Text>
                    

                </Box>
                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                        When & Where is UFC 306?
                    </Heading>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        UFC 306 takes place on September 14, 2024. It will take place at the Sphere in Paradise, Nevada. This is the first combat sports event to be held in the Sphere, and it will be sponsored by Saudi Arabia’s General Entertainment Authority as part of Riyadh season. Prelims will get underway at 7:30 pm ET, while the main card is scheduled to begin at 10 pm ET.
                    </Text>
                </Box>

                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                        UFC 306 Picks & Previews
                    </Heading>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>Main Card</Text>
                    <UnorderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        <ListItem><NavLink><strong>Sean O’Malley vs Merab Dvalishvili – O’Malley by KO/TKO</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Alexa Grasso vs Valentina Shevchenko – Grasso by decision</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Brian Ortega vs Diego Lopes – Lopes by decision</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Raul Rosas Jr. vs Aori Qileng – Rosas Jr. by submission</strong></NavLink></ListItem>                        
                    </UnorderedList>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>Preliminary Card</Text>
                    <UnorderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        <ListItem><NavLink><strong>Irene Aldana vs Norma Dumont – Aldana by decision</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Manuel Torres vs Ignacio Bahamondes – Bahamondes by KO/TKO</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Édgar Cháirez vs Kevin Borjas – Cháirez by submission</strong></NavLink></ListItem>                       
                    </UnorderedList>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>
                    Early Prelims</Text>
                    <UnorderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        <ListItem><NavLink><strong>Ronaldo Rodríguez vs  Osbourne – Rodríguez by submission</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>Yazmin Jauregui vs Ketlen Souza – Jauregui by KO/TKO</strong></NavLink></ListItem>
                        <ListItem><NavLink><strong>
                        Daniel Zellhuber vs Esteban Ribovics – Zellhuber by decision</strong></NavLink></ListItem>                       
                    </UnorderedList>
                </Box>


                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                    Previous Fights & Fighter History
                    </Heading>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>
                    Sean O’Malley vs Merab Dvalishvili</Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Sean O’Malley took the bantamweight title from Aljamain Sterling via an impressive KO, and his striking has looked unstoppable at times. He secured his first title defense in a unanimous decision against Vera, avenging the only loss on his 18-1 record. Although he excels in his striking, some fans feel he’s been protected from facing strong wrestlers in the division.
                    </Text>
                   
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Merab Dvalishvili has been waiting a long time for his shot at the title. Now, he’s finally getting his chance, thanks to an impressive ten-fight win streak. His last three fights have been dominant wins over Henry Cejudo, Petr Yan, and Jose Aldo. He fights at an extremely high pace, relying on constant takedowns, and he’ll look to take O’Malley down to the canvas as much as possible in this title fight. However, he’s been caught before, and his entries leave him open to knees and uppercuts.
                    </Text>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>
                    Alexa Grasso vs Valentina Shevchenko</Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    The co-main event is the trilogy fight between these two fighters who represent the best of women’s MMA. Shevchenko is a legend of the sport, but she was finally dethroned by Grasso in 2023. Their rematch played out as a draw, so they’ll once again be fighting for the right to be called champion. Grasso has some of the cleanest boxing in the division, and she capitalized on a miWagerStreet in their first fight. She’s incredibly sharp, and we believe she’ll win the fight by decision.
                    </Text>
                   
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Shevchenko ruled the women’s 125-pound division for several years, racking up an impressive seven consecutive title defenses. Her only losses came when she moved up in weight to face Amanda Nunes, though at 36 years old, she doesn’t have time on her side. It will be interesting to see how she can improve and whether her experience will make a difference in the outcome.
                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    We sat down with Alexa Grasso for an exclusive interview ahead of her title defence. Read about what she had to say in preparation for the big fight.
                    </Text>
                    <Text fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} fontWeight={"bold"}>
                    Brian Ortega vs Diego Lopes</Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    This fight was originally scheduled to take place at UFC 303 before Ortega pulled out at the last minute due to falling ill after cutting weight. Despite being a last-minute scheduled fight, it was one that fans were really looking forward to. Lopes has become a huge fan-favorite in a short space of time thanks to his high finish rate. Since entering the UFC, his only loss is to Evloev and he’s finished three out of four fights.
                    </Text>
                   
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    Ortega is something of an enigma in the featherweight division. He hasn’t looked his best in recent years, but he’s been fighting the best of the best. His decisive win against the Korean Zombie was very impressive, and he’s shown remarkable toughness and submission threats in every fight. If he can weather the storm of Lopes, he could catch him in a submission.
                    </Text>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    We sat down with Alexa Grasso for an exclusive interview ahead of her title defence. Read about what she had to say in preparation for the big fight.
                    </Text>
                </Box>

                <Box mt={4}>
                    {/* Main Article Section */}
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb={4}>
                        Types of UFC Bets & How to Bet on UFC & MMA
                    </Heading>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    It’s simple to start betting on the UFC . You can read our full betting guide for more details, but here are some easy steps to follow to place your first bets at Wager Street:
                    </Text>
                   
                    <OrderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        <ListItem>Log into your account or sign up if you don’t already have one</ListItem>
                        <ListItem>Check to see if you have enough funds available, and use our accepted payment methods and currencies to store your deposit in the vault .</ListItem>
                        <ListItem>Search for UFC or MMA from the sports menu to find the available events.</ListItem>
                        <ListItem>Select UFC 306 to see all the upcoming fights you can bet on.</ListItem>                        
                    </OrderedList>
                    <Text mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                    We’ve got lots of different ways you can bet on UFC here at Wager Street. There’s a great selection of pre-live and live options to choose from. Some of the most popular betting markets include:
                    </Text>
                    <UnorderedList mb={4} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} color={textColor2}>
                        <ListItem>Match winner</ListItem>
                        <ListItem>1x2</ListItem>
                        <ListItem>Winning method</ListItem>                       
                    </UnorderedList>
                    
                </Box>

                <NavLink to='/sports'>
                    <Button fontSize={{ base: 'md', md: 'md', lg: 'xl' }} borderRadius={30} bg={buttonbg} p={6}>Sport</Button>
                </NavLink>
                <NavLink to='/blog/stakenews'>
                    <Button fontSize={{ base: 'md', md: 'md', lg: 'xl' }} borderRadius={30} bg={buttonbg} p={6}>Wager Street News</Button>
                </NavLink>
            </Box>
            <Box bg={bg}>
            <Box m={4}>

            <Text fontSize={{ base: 'md', md: 'xl', lg: 'xl' }} fontWeight={"bold"} p={2}>Other Popular Articles</Text>
            <Divider></Divider>
            </Box>
                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <Box borderRadius="md" p="4">
                        <Image
                            src={DiceImage}
                            alt="UFC Picks & Expert UFC Predictions"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box borderRadius="md" p="4">
                        <Image
                            src={PokerImage}
                            alt="2024 MLB Predictions & World Series Picks"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Video Poker Dise at Stack
                        </Text>
                        <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            MLB predictions for the 2024 season are here, with baseball picks to
                            win from our expert tipsters. See which team is predicted to win the
                            World Series and make your selections on our Sportsbook!
                        </Text>
                    </Box>

                    <Box borderRadius="md" p="4">
                        <Image
                            src={BounsImage}
                            alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="bold" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            Slot Machine Bouns Game
                        </Text>
                        <Text color={textColor2} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            We&apos;re wrapping up the week by looking at top sporting events,
                            22 new-release games &amp; fresh bet bonuses and promos! Find out
                            how to join the latest casino competitions today!
                        </Text>
                    </Box>
                </SimpleGrid>
            </Box>
        </Flex>
    );
}
