/*eslint-disable*/
// chakra imports
import {
  Box,
  useColorModeValue,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import SidebarContent from "./SidebarContent";
import { useState } from "react";
import { FaAlignJustify, FaArrowLeft } from "react-icons/fa";
import SidebarMid from "./SidebarMid";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    logoText,
    routes,
    sidebarVariant,
    isCollapsed,
    toggleSidebar,
    leftMenu,
  } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <>
      <Box
        display={{ sm: "none", lg: "block", xl: "none" }}
        ref={mainPanel}
        w="80px"
        transition="width 0.3s"
        float="left"
      >
        <Button
          onClick={onOpen}
          ml={"10px"}
          position="fixed"
          top="10px"
          left="16px"
        >
          <FaAlignJustify />
        </Button>

        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              {/* Close Button */}
              <DrawerHeader>
                <DrawerCloseButton />
              </DrawerHeader>
              <DrawerBody>
                <SidebarContent
                  routes={routes}
                  leftMenu={leftMenu}
                  logoText={logoText}
                />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
      <Box
        ref={mainPanel}
        w={isCollapsed ? "80px" : "260px"} // Adjust width based on collapse state
        transition="width 0.3s"
        float="left"
      >
        <Box display={{ sm: "none", xl: "block" }} position="fixed">
          <Box
            bg={sidebarBg}
            transition={variantChange}
            w="100%" // Full width to adjust button positioning
            maxW={isCollapsed ? "90px" : "260px"}
            ms={{
              sm: "16px",
            }}
            my={{
              sm: "16px",
            }}
            h="calc(100vh - 32px)"
            ps="20px"
            pe="20px"
            m={sidebarMargins}
            borderRadius={sidebarRadius}
          >
            <Button
              onClick={toggleSidebar}
              ml={"10px"}
              position="absolute"
              top="10px"
              right={isCollapsed ? "auto" : "16px"}
              left={isCollapsed ? "16px" : "auto"}
            >
              {isCollapsed ? <FaAlignJustify /> : <FaArrowLeft />}
            </Button>

            <SidebarContent
              routes={routes}
              leftMenu={leftMenu}
              logoText={"WAGER STREET"}
              display="none"
              sidebarVariant={sidebarVariant}
              isCollapsed={isCollapsed}
              toggleSidebar={toggleSidebar} // Pass toggle function to Sidebar
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Sidebar;
