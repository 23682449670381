import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  Link,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { ExternalLinkIcon } from "@chakra-ui/icons"; // External link icon for URLs
import CuracaoImage from "assets/img/licenses/curacao.png";
import { PiWarningCircleLight } from "react-icons/pi";

// Example licenses data with both icons and images
const licenses = [
  {
    title: "Curacao",
    description:
      "Wager Street is licensed in Curacao and authorized to operate with different URLs in countries such as Mexico or the UK.",
    image: CuracaoImage,
  },
  {
    title: "WagerStreet.net",
    description:
      "The website www.wagerstreet.net is operated by Medium Rare N.V. under the gaming license 8048/JAZ2021-027 issued to Antillephone, which is authorized and regulated by the Government of Curaçao.",
    icon: BsFillShieldLockFill,
    url: "https://www.wagerstreet.net",
  },
  {
    title: "WagerStreet.net",
    description:
      "The website www.wagerstreet.net is operated by Wager Street Colombia SAS, NIT 901392284-4, Carrera 15 # 106-32, Of PH3, 110111, Bogotá DC, which is licensed and regulated in Colombia and issued by Coljuegos under license number C1751.",
      image: CuracaoImage,
    url: "https://www.wagerstreet.net",
  },
  {
    title: "No US Bets (and others)",
    description:
      "Next to our licenses, there are countries where we don't accept customers due to international policies or local legislation. Those countries would either be mentioned in our terms of services or be geo IP blocked on our site(s).",
    icon: PiWarningCircleLight,
  },
];

const Licenses = () => {
  const bg = useColorModeValue("gray.100", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.400");

  return (
    <Box minH="100vh" bg={bg} py={10} pt={{ base: "75px", md: "100px" }} px={{ base: 4, md: 8 }}>
      {/* Heading with an icon */}
      <Flex align="center" mb={8}>
        <Icon as={BsFillShieldLockFill} boxSize={6} mr={2} />
        <Heading size="lg" color={textColor}>
          Licenses
        </Heading>
      </Flex>

      {/* License cards container */}
      <Stack spacing={6}>
        {licenses.map((license, index) => (
          <Box
            key={index}
            bg={cardBg}
            p={6}
            borderRadius="md"
            boxShadow="lg"
            border="1px solid"
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <Flex align="center" mb={4}>
              {/* Conditionally render icon or image */}
              {license.icon ? (
                <Icon as={license.icon} boxSize={8} mr={4} />
              ) : (
                <Image src={license.image} boxSize="50px" alt={`${license.title} logo`} mr={4} />
              )}
              <Heading size="md" color={textColor}>
                {license.title}
              </Heading>
            </Flex>
            <Text color={textColor} mb={4}>
              {license.description}
            </Text>
            {license.url && (
              <Link href={license.url} isExternal color="blue.400">
                {license.url} <ExternalLinkIcon mx="2px" />
              </Link>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Licenses;
