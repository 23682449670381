import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Divider,
  Checkbox,
  SimpleGrid,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";

import {
  FacebookIcon,
  GoogleIcon,
  TwitchIcon,
  LineIcon,
} from "components/Icons/Icons";
import { NavLink } from "react-router-dom";

const RegisterModal = ({ isRegisterOpen, closeRegister, openLogin }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal isOpen={isRegisterOpen} onClose={closeRegister} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent
        bg="gray.700"
        color="gray.400"
        minHeight={["400px", "500px"]}
        maxHeight={["550px", "650px"]}
        overflow="hidden"
        minWidth={["50%", "30%"]}
        m={4}
      >
        <ModalHeader fontSize="md" textAlign="center" color="white">
          Create an Account
        </ModalHeader>
        <ModalHeader fontSize="sm" textAlign="center">
          Step 1/2: Fill out your details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {/* Registration Form */}
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Username</FormLabel>
            <Input placeholder="Enter your username" />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder="Enter your password" />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Date of Birth</FormLabel>
            <Box display="flex" gap={2} flexDirection={["column", "row"]}>
              <Input placeholder="DD" maxWidth={["100%", "30%"]} />
              <Input placeholder="Month" maxWidth={["100%", "40%"]} />
              <Input placeholder="YYYY" maxWidth={["100%", "50%"]} />
            </Box>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Phone (Optional)</FormLabel>
            <Box display="flex" gap={2} flexDirection={["column", "row"]}>
              <Input placeholder="Country code" maxWidth={["100%", "30%"]} />
              <Input placeholder="Phone number" maxWidth={["100%", "70%"]} />
            </Box>
          </FormControl>
          <FormControl mt={4}>
            <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
              Code (Optional)
            </Checkbox>
            {isChecked && <Input mt={2} placeholder="Enter code" />}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Box w="100%">
            <Button
              style={{ backgroundColor: "#00E701", color: "black" }}
              w="100%"
              _hover="none"
            >
              Continue
            </Button>

            <Stack direction="row" align="center" spacing={4} my={4}>
              <Divider borderColor="gray.600" flex="1" />
              <Text fontSize="sm" color="gray.400">
                OR
              </Text>
              <Divider borderColor="gray.600" flex="1" />
            </Stack>
            <Flex justify="center" align="center" mt={4}>
              <SimpleGrid columns={4} spacing={2}>
                <Button
                  size="md"
                  w="50px"
                  h="50px"
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FacebookIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w="50px"
                  h="50px"
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <GoogleIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w="50px"
                  h="50px"
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LineIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w="50px"
                  h="50px"
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TwitchIcon boxSize={4} />
                </Button>
              </SimpleGrid>
            </Flex>

            <Text textAlign="center" fontSize="sm" color="gray.400" mt={4}>
              Already have an account?
              <NavLink
                style={{ color: "white" }}
                onClick={() => {
                  openLogin();
                  closeRegister();
                }}
              >
                {" "}
                Sign In
              </NavLink>
            </Text>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterModal;
