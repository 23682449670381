import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";

function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, isChecked, onSwitch, onTransparent, onOpaque } = props;
  const [switched, setSwitched] = useState(isChecked);

  // Chakra Color Mode
  let fixedDisplay = secondary ? "none" : "flex";

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement={document.documentElement.dir === "rtl" ? "left" : "right"}
      finalFocusRef={settingsRef}
      blockScrollOnMount={false}
    >
      <DrawerContent>
        <DrawerHeader pt="24px" px="24px">
          <DrawerCloseButton />
          <Text fontSize="xl" fontWeight="bold" mt="16px">
            Configurator
          </Text>
          <Text fontSize="md" mb="16px">
            See your dashboard options.
          </Text>
          <Separator />
        </DrawerHeader>
        <DrawerBody w="340px" ps="24px" pe="40px">
          <Flex flexDirection="column">
            <Box>
              <Text fontSize="md" fontWeight="600">
                Sidenav Type
              </Text>
              <Text fontSize="sm" mb="16px">
                Choose between 2 different sidenav types.
              </Text>
              <Flex>
                <Button
                  w="50%"
                  p="8px 32px"
                  me="8px"
                  colorScheme="teal"
                  borderColor="teal.300"
                  color="teal.300"
                  variant="outline"
                  fontSize="xs"
                  onClick={onTransparent}
                >
                  Transparent
                </Button>
                <Button
                  type="submit"
                  bg="teal.300"
                  w="50%"
                  p="8px 32px"
                  mb={5}
                  _hover={{ bg: "teal.300" }}
                  color="white"
                  fontSize="xs"
                  onClick={onOpaque}
                >
                  Opaque
                </Button>
              </Flex>
            </Box>
            <Box
              display={fixedDisplay}
              justifyContent="space-between"
              mb="16px"
            >
              <Text fontSize="md" fontWeight="600" mb="4px">
                Navbar Fixed
              </Text>
              <Switch
                colorScheme="teal"
                isChecked={switched}
                onChange={() => {
                  const newSwitchState = !switched;
                  onSwitch(newSwitchState);
                  setSwitched(newSwitchState);
                }}
              />
            </Box>
            <Separator />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fixed: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onTransparent: PropTypes.func.isRequired,
  onOpaque: PropTypes.func.isRequired,
};

export default Configurator;
