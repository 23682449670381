import React from 'react';
import { Box, useStyleConfig } from "@chakra-ui/react";
import PropTypes from 'prop-types';

function PanelContainer(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("PanelContainer", { variant });

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}

PanelContainer.propTypes = {
  variant: PropTypes.string, // Define variant as a string if it can be a string; adjust if needed
  children: PropTypes.node, // children can be any renderable content
};

export default PanelContainer;
