import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Icon,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  SimpleGrid,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  useColorModeValue,
  useBreakpointValue,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import {
  FaCopy,
  FaExternalLinkAlt,
  FaHeart,
  FaClock,
  FaShareAlt,
} from "react-icons/fa";

export default function Affiliate() {
  const [likeStatus, setLikeStatus] = useState(false);

  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const iconColor = useColorModeValue("gray.600", "gray.300");
  const tabBg = useColorModeValue("gray.100", "gray.700");
  const inputBg = useColorModeValue("gray.200", "gray.700");
  const tabOrientation = useBreakpointValue({
    base: "horizontal",
    md: "vertical",
  });

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "65px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      color={textColor}
      minH="100vh"
    >
      <Box
        maxW="1200px"
        mx="auto"
        p={4}
        bg={bg}
        borderRadius="md"
        boxShadow="lg"
      >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              <Icon as={FaExternalLinkAlt} mr={2} />
              Affiliate Program
            </Text>
          </Box>
        </SimpleGrid>

        <Tabs
          orientation={tabOrientation}
          variant="soft-rounded"
          colorScheme="teal"
          mt={4}
        >
          <TabList
            width={{ base: "100%", md: "25%" }}
            bg={tabBg}
            borderRadius="md"
            p={4}
            mb={2}
          >
            <Tab
              fontWeight="bold"
              _selected={{ bg: "teal.400", color: "white" }}
            >
              Overview
            </Tab>
            <Tab
              fontWeight="bold"
              _selected={{ bg: "teal.400", color: "white" }}
            >
              Retention Program
            </Tab>
          </TabList>
          <TabPanels
            width={{ base: "100%", md: "75%" }}
            bg={tabBg}
            borderRadius="md"
            p={4}
          >
            <TabPanel>
              <VStack align="start" spacing={4}>
                <Box position="relative" w="full" pb="56.25%" overflow="hidden">
                  <iframe
                    src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                    title="Affiliate Program Video"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    allowFullScreen
                  />
                  <Box position="absolute" top="4px" right="4px">
                    <Flex direction="column" align="center" gap="2">
                      <Icon
                        as={FaHeart}
                        boxSize={6}
                        color={likeStatus ? "red.400" : iconColor}
                        cursor="pointer"
                        onClick={() => setLikeStatus(!likeStatus)}
                      />
                      <Icon as={FaClock} boxSize={6} color={iconColor} />
                      <Icon as={FaShareAlt} boxSize={6} color={iconColor} />
                    </Flex>
                  </Box>
                </Box>
                <Text fontSize="lg">
                  Refer our brand and earn money through participating in
                  WagerStreet.net’s Affiliate Program! You will receive a commission
                  for users who register and play on WagerStreet.net through your
                  links.
                </Text>
                <Text fontSize="lg">
                  Being a WagerStreet.net affiliate means you will earn commission for
                  all bets placed on both our Casino and Sportsbook - which
                  unlike traditional affiliate programs, means that despite if
                  they win or lose, you still make the same commission!
                </Text>
                <Text fontSize="sm" color="gray.400">
                  *Please note that referrals from accounts with the same IP
                  address will not be recognised.
                </Text>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <InputGroup
                    size="md"
                    maxW={{ base: "100%", md: "60%" }}
                    mb={{ base: 4, md: 0 }}
                  >
                    <Input
                      pr="4.5rem"
                      placeholder="Referral Link"
                      bg={inputBg}
                      borderColor={inputBg}
                      focusBorderColor="teal.400"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        bg="teal.400"
                        _hover={{ bg: "teal.300" }}
                      >
                        <Icon as={FaCopy} />
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <Button
                    bg="teal.400"
                    color="white"
                    _hover={{ bg: "teal.300" }}
                    rightIcon={<FaExternalLinkAlt />}
                  >
                    Download Banners
                  </Button>
                </Flex>
              </VStack>
            </TabPanel>

            <TabPanel>
              <VStack align="start" spacing={4}>
                <Text fontSize="lg" pb={2}>
                  At WagerSteeet.net, first priority has always been and will always
                  be player satisfaction. This is the core of our long term
                  strategy for our users, interacting with the platform as
                  players and as affiliates. We strive to achieve player
                  satisfaction through unique programs and campaigns that reward
                  our players.
                </Text>
                <Text fontSize="lg" pb={2}>
                  These programs provide additional funds and incentives to our
                  players which not only provides for a better gambling
                  experience but also contributes to increasing your overall
                  affiliate commission earnings.
                </Text>
                <Text fontSize="lg" pb={2}>
                  These programs include:
                </Text>
                <UnorderedList fontSize="lg" pl={5} pb={2}>
                  <ListItem>Lorem ipsum dolor sit amet</ListItem>
                  <ListItem>Consectetur adipiscing elit</ListItem>
                  <ListItem>Integer molestie lorem at massa</ListItem>
                  <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                </UnorderedList>
                <Text fontSize="lg" pb={2}>
                  With this unique approach, your commission is based off of the
                  house edge after these costs have been realised. Depending on
                  the extent of what players receive as benefits, the house edge
                  commission you receive will come out to roughly 45% less of
                  the house edge advertised on any of our games.
                </Text>
                <Text fontSize="lg" pb={2}>
                  If you would like a detailed breakdown on how these costs are
                  calculated, feel free to contact support to be put in touch
                  with an affiliate manager.
                </Text>
                <Box
                  border="3px dashed"
                  borderColor={iconColor}
                  p={4}
                  borderRadius="lg"
                >
                  <Text fontSize="lg">
                    The retention program is utilised for your affiliates on
                    both the Sportsbook and Casino, however the costs are
                    already absorbed before calculating the affiliate commission
                    on Sportsbook bets so that we can set a standard rate of 3%
                    assumed margin.
                  </Text>
                </Box>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}
