// const MainPanel = {
//   baseStyle: {
//     float: "right",
//     maxWidth: "1440px",
//     overflow: "auto",
//     position: "relative",
//     maxHeight: "100%",
//     transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
//     transitionDuration: ".2s, .2s, .35s",
//     transitionProperty: "top, bottom, width",
//     transitionTimingFunction: "linear, linear, ease",
//   },
//   variants: {
//     main: (props) => ({
//       float: "right",
//     }),
//     rtl: (props) => ({
//       float: "left",
//     }),
//   },
//   defaultProps: {
//     variant: "main",
//   },
// };

// export const MainPanelComponent = {
//   components: {
//     MainPanel,
//   },
// };

const MainPanel = {
  baseStyle: {
    float: "center",
    maxWidth: "1440px",
    overflow: "auto",
    position: "relative",
    maxHeight: "100%",
    transition: "all 0.2s cubic-bezier(0.25, 0.8, 0.5, 1)", // smoother and faster
    transitionDuration: "0.2s", // reducing overall transition duration
    transitionProperty: "top, bottom, width",
    transitionTimingFunction: "ease-in-out", // smoother transition
  },
  variants: {
    main: (props) => ({
      float: "center",
    }),
    rtl: (props) => ({
      float: "center",
    }),
  },
  defaultProps: {
    variant: "main",
  },
};

export const MainPanelComponent = {
  components: {
    MainPanel,
  },
};
