import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { clearBetItems, setBetItems, setBetSlipStatus } from "actions/betslip";
import {
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  HStack,
  useColorModeValue,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import {
  FaSearch,
  FaChartBar,
  FaExclamationTriangle,
  FaFlag,
  FaFutbol,
} from "react-icons/fa";
import { NoEventsIcon } from "components/Icons/Icons";
import soccerBg from "assets/img/event-page/soccer-bg.jpg";
import tennisBg from "assets/img/event-page/tennis-bg.jpg";
import basketballBg from "assets/img/event-page/basketball-bg.jpg";
import footballBg from "assets/img/event-page/footabll-bg.jpg";
import rugbyBg from "assets/img/event-page/rugby-bg.jpg";
import hockeyBg from "assets/img/event-page/basketball-bg.jpg";
import baseballBg from "assets/img/event-page/baseball-bg.jpg";
import cricketBg from "assets/img/event-page/cricket-bg.jpg";
import tableTennisBg from "assets/img/event-page/table-tennis-bg.jpg";

import soccerField from "assets/img/event-page/soccer-field.jpg";
import tennisField from "assets/img/event-page/tennis-field.jpg";
import basketballField from "assets/img/event-page/basketball-field.jpg";
import footballField from "assets/img/event-page/football-field.jpg";
import rugbyField from "assets/img/event-page/rugby-field.jpg";
import hockeyField from "assets/img/event-page/hockey-field.jpg";
import baseballField from "assets/img/event-page/baseball-field.jpg";
import cricketField from "assets/img/event-page/cricket-field.jpg";
import tableTennisField from "assets/img/event-page/table-tennis-field.jpg";

import MatchDetailsChart from "components/MatchDetailsChart";
import Betslip from "views/BetslipTemplate";

import { getEvent } from "../../actions/sports";

const EventTemplate = ({
  dispatch,
  sports,
  countries,
  leagues,
  events,
  odds,
  wsConnected,
  betItems,
  isBetSlipStatus,
}) => {
  const navigate = useNavigate();

  const textColor = useColorModeValue("gray.700", "white");
  const activeTextColor = useColorModeValue("gray.700", "gray.700");
  const secondaryTextColor = useColorModeValue("gray.400", "gray.400");
  const collapsableBg = useColorModeValue("gray.100", "gray.700");
  const oddColor = useColorModeValue("blue.400", "blue.400");
  const activeOddColor = useColorModeValue("white", "white");
  const buttonBg = useColorModeValue("gray.200", "gray.900");
  const activeButtonBg = useColorModeValue("gray.200", "blue.500");
  const tabBg = useColorModeValue("gray.100", "gray.900");
  const tabBgColor = useColorModeValue("gray.200", "gray.900");
  const tabHoverBg = useColorModeValue("gray.200", "gray.700");
  const selectedTabBg = useColorModeValue("gray.300", "gray.700");
  const tabColor = useColorModeValue("black", "white");
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // const sportId = queryParams.get("sportId");
  // const countryId = queryParams.get("countryId");
  // const leagueId = queryParams.get("leagueId");
  // const eventId = queryParams.get("eventId");

  const { sportId, countryId, leagueId, eventId } = location.state || {};

  console.log(location);
  console.log(eventId);

  console.log(betItems);

  // const [betItems, setBetItems] = useState([]);
  // const [isBetSlipStatus, setisBetSlipStatus] = useState(false);
  const isBetSlipVisible = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    if (eventId && wsConnected) {
      dispatch(getEvent(eventId)); // Only called when eventId changes
    }
  }, [eventId, wsConnected]); // Depend only on eventId

  // const testOdds = {
  //   1: { eventId: "51271551", marketName: "Market 1", odds: [{ oddName: "Odd 1", hnd: "Handicap 1", moneyLine: "+100" }] },
  //   2: { eventId: "51271551", marketName: "Market 2", odds: [{ oddName: "Odd 2", hnd: "Handicap 2", moneyLine: "-200" }] },
  // };
  // const eventId = "51271551";
  // const odds = testOdds;

  console.log(odds);

  let sport = {};
  let country = {};
  let league = {};

  if (events[eventId]) {
    const event = events[eventId];

    const teams = event?.teams.split(" - ").map((team) => team.trim()) || [];
    const team1 = teams[0] || "Team 1";
    const team2 = teams[1] || "Team 2";

    if (sports) {
      sport = sports[sportId];
    }

    if (leagues[leagueId]) {
      country = countries[leagues[leagueId].countryId];
      league = leagues[leagueId];
    }
    console.log(sport);
    console.log(country);
    console.log(event);
    console.log(league);

    const eventDate = new Date(event.startTime);
    const formattedDate = eventDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = eventDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const selectBetSlip = (marketId, marketName, oddId, oddData) => {
      console.log(marketId);
      console.log(marketName);
      console.log(oddId);
      console.log(oddData);
      const selectedBet = {
        marketId,
        marketName,
        oddId,
        oddName: oddData.oddName,
        moneyLine: oddData.moneyLine,
      };

      console.log(isBetSlipStatus);

      if (!isBetSlipStatus) {
        dispatch(setBetSlipStatus(true));
        console.log(isBetSlipStatus);
      }

      const index = betItems.findIndex((item) => item.oddId === oddId);
      let newBetItems = [...betItems];

      if (index === -1) {
        newBetItems.push(selectedBet);
      } else {
        newBetItems.splice(index, 1);
      }
      dispatch(setBetItems(newBetItems));
      // setBetItems(newBetItems); // Update the state with the new array
      // dispatch(addBetItems(newBetItems));
    };

    const closeBetSlip = () => setBetSlipStatus(false);

    const clearAllBetItems = () => {
      // setBetItems([]);
      // Dispatch the clearAllBets action
      dispatch(clearBetItems());
    };

    // Map sportId to corresponding background images
    const bgBannerMapping = {
      342: soccerBg,
      343: tennisBg,
      348: basketballBg,
      350: footballBg,
      345: rugbyBg,
      372: rugbyBg,
      347: hockeyBg,
      366: baseballBg,
      386: cricketBg,
      427: tableTennisBg,
    };

    // Map sportId to corresponding field images
    const fieldImageMapping = {
      342: soccerField,
      343: tennisField,
      348: basketballField,
      350: footballField,
      345: rugbyField,
      372: rugbyField,
      347: hockeyField,
      366: baseballField,
      386: cricketField,
      427: tableTennisField,
    };

    // Select the background image based on sportId, fallback to default (soccerBg)
    const bannerImage = bgBannerMapping[sportId] || soccerBg;

    // Select the field image based on sportId, fallback to default (soccerField)
    const fieldImage = fieldImageMapping[sportId] || soccerField;

    const MatchInfoBox = () => {
      return (
        <Box
          position="relative"
          bgImage={bannerImage}
          bgSize="cover"
          bgPosition="center"
          h={{ base: "150px", md: "200px" }} // Responsive height
          borderRadius="md"
          mb={6}
          overflow="hidden"
        >
          <Flex
            color={secondaryTextColor}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            bg="rgba(0, 0, 0, 0.6)" // Semi-transparent background
            borderRadius="md"
            p={{ base: 2, md: 4 }} // Responsive padding
            width="80%" // Wider on smaller screens
          >
            <Box flex="1">
              <Text fontSize={{ base: "xs", md: "sm" }} color="gray.300">
                {formattedDate} {formattedTime}
              </Text>
              <Flex alignItems="center">
                <Text fontWeight="bold">{event.teams}</Text>
              </Flex>
            </Box>

            <HStack spacing={3} flexShrink={0}>
              <Icon as={FaChartBar} color="gray.400" boxSize={4} />
              <Icon as={FaExclamationTriangle} color="yellow.400" boxSize={4} />
              <Icon as={FaFlag} color="red.400" boxSize={4} />
              <Icon as={FaFutbol} color="teal.400" boxSize={4} />
            </HStack>
          </Flex>
        </Box>
      );
    };

    const matchDetailsSeries = [
      {
        name: team1,
        data: [48],
        color: "#1F8BFF",
      },
      {
        name: "Draw",
        data: [24],
        color: "#A0AEC0",
      },
      {
        name: team2,
        data: [28],
        color: "#2fbc2f",
      },
    ];

    const previousMeetingsSeries = [
      {
        name: team1,
        data: [0],
        color: "#1F8BFF",
      },
      {
        name: "Draws",
        data: [0],
        color: "#A0AEC0",
      },
      {
        name: team2,
        data: [1],
        color: "#2fbc2f",
      },
    ];

    // Helper function to format the strings of Navlinks
    const formatStringForURL = (str) => {
      if (typeof str === "string") {
        return str.replace(/\s+/g, ""); // Remove all white spaces
      }
      return ""; // Return an empty string if str is not a valid string
    };

    return (
      <Flex flexDirection="column" pt="75px">
        <Box pt={{ base: 4, md: 6 }} minH="100vh" color="white">
          <Flex alignItems="center" mb={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              variant="ghost"
              aria-label="Back"
              bg={tabBgColor}
              mr={2}
              onClick={() =>
                navigate(-1)
              } /* This will go to the previous page */
            />
            <Breadcrumb
              separator={
                <Box as="span" color="gray.500">
                  /
                </Box>
              }
              bg={tabBgColor}
              p={2}
              pl={3}
              pr={3}
            >
              {/* Non-clickable sport name */}
              <BreadcrumbItem
                display={{ base: "none", md: "flex", lg: "flex" }}
              >
                <Text fontWeight="bold">
                  {Object.keys(sport).length ? sport.name : "-"}
                </Text>
              </BreadcrumbItem>

              {/* Non-clickable country name */}
              <BreadcrumbItem
                display={{ base: "none", md: "flex", lg: "flex" }}
              >
                <Text fontWeight="bold">
                  {Object.keys(country).length ? country.name : "-"}
                </Text>
              </BreadcrumbItem>

              {/* Clickable league name */}
              <BreadcrumbItem>
                <BreadcrumbLink
                    variant="ghost"
                    aria-label="Back"
                    bg={tabBgColor}
                    mr={2}
                    onClick={() =>
                        navigate(-1)
                    }
                >
                  {event?.leagueName}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Flex>

          <Flex flexDirection={{ base: "column", md: "column", lg: "row" }}>
            <Box flex="2" mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
              <MatchInfoBox />
              {odds && (
                <Tabs
                  variant="soft-rounded"
                  colorScheme="gray"
                  sx={{
                    "& .chakra-tabs__tab": {
                      backgroundColor: tabBg,
                      color: tabColor,
                    },
                    "& .chakra-tabs__tab:hover": {
                      backgroundColor: tabHoverBg,
                    },
                    '& .chakra-tabs__tab[aria-selected="true"]': {
                      backgroundColor: selectedTabBg,
                      color: tabColor,
                    },
                  }}
                >
                  <TabList
                    mb={4}
                    display="flex"
                    justifyContent="flex-start" // Align tabs to the left for scrolling
                    whiteSpace="nowrap" // Prevent tabs from wrapping
                    overflowX="auto" // Enable horizontal scrolling
                    css={{
                      "::-webkit-scrollbar": { display: "none" }, // Hide scrollbar on WebKit browsers
                    }}
                  >
                    {/* Dynamically map market names as Tab items */}
                    {/* {Object.values(odds)
        .filter((marketData) => marketData.eventId.toString() === eventId)
        .map((marketData) => (
          <Tab key={marketData.marketName}>{marketData.marketName}</Tab>
        ))} */}
                    <Tab>Main</Tab>
                    <Tab>Same Game Multi</Tab>
                    <Tab>Goals</Tab>
                    <Tab>Half</Tab>
                    <Tab>Asian Lines</Tab>
                    <Tab>Goalscorers</Tab>
                    <Tab>Corners</Tab>
                    <Tab>Cards</Tab>
                  </TabList>
                  <Box mb="24px">
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FaSearch color="gray.300" />
                      </InputLeftElement>
                      <Input
                        placeholder="Search"
                        size="md"
                        borderRadius="12px"
                      />
                    </InputGroup>
                  </Box>

                  <TabPanels>
                    <TabPanel>
                      {odds ? (
                        <Accordion
                          allowToggle
                          mt={4}
                          color={secondaryTextColor}
                        >
                          {Object.entries(
                            Object.entries(odds).reduce(
                              (acc, [marketId, marketData]) => {
                                // Grouping marketData by marketName
                                if (marketData.eventId.toString() === eventId) {
                                  if (!acc[marketData.marketName]) {
                                    acc[marketData.marketName] = [];
                                  }
                                  acc[marketData.marketName].push({
                                    marketId,
                                    marketData,
                                  });
                                }
                                return acc;
                              },
                              {}
                            )
                          ).map(([marketName, groupedMarkets]) => (
                            <AccordionItem
                              bg={collapsableBg}
                              key={marketName}
                              border="none"
                              mb={4}
                            >
                              <h2>
                                <AccordionButton p={4}>
                                  <Box
                                    color={textColor}
                                    flex="1"
                                    textAlign="left"
                                    fontSize="sm"
                                  >
                                    {marketName}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                {/* Conditionally display the oddNames in the same row */}
                                {groupedMarkets.length > 1 && (
                                  <Flex mb={2} justify="space-between">
                                    {Object.entries(
                                      groupedMarkets[0].marketData.odds
                                    ).length > 0 && (
                                      <Text fontSize="sm">
                                        {
                                          groupedMarkets[0].marketData.odds[
                                            Object.keys(
                                              groupedMarkets[0].marketData.odds
                                            )[0]
                                          ].oddName
                                        }
                                      </Text>
                                    )}
                                    {Object.entries(
                                      groupedMarkets[0].marketData.odds
                                    ).length > 1 && (
                                      <Text fontSize="sm">
                                        {
                                          groupedMarkets[0].marketData.odds[
                                            Object.keys(
                                              groupedMarkets[0].marketData.odds
                                            )[1]
                                          ].oddName
                                        }
                                      </Text>
                                    )}
                                  </Flex>
                                )}

                                {groupedMarkets.map(
                                  ({ marketId, marketData }) => (
                                    <Flex
                                      key={marketId}
                                      justify="space-between"
                                      flexWrap="wrap"
                                    >
                                      {Object.entries(marketData.odds).map(
                                        ([oddId, odd]) => (
                                          <Button
                                            fontSize={{ base: "xs", md: "sm" }}
                                            bg={
                                              betItems.some(
                                                (item) => item.oddId === oddId
                                              )
                                                ? activeButtonBg
                                                : buttonBg
                                            }
                                            key={oddId}
                                            onClick={() =>
                                              selectBetSlip(
                                                marketId,
                                                marketData.marketName,
                                                oddId,
                                                odd
                                              )
                                            }
                                            _hover={{ bg: "blue.900" }}
                                            mb={4}
                                            className={
                                              groupedMarkets.length > 1
                                                ? ""
                                                : "event-odd-button"
                                            }
                                            w={
                                              groupedMarkets.length > 1
                                                ? "48%"
                                                : ""
                                            }
                                          >
                                            <Flex
                                              justify="space-between"
                                              w="full"
                                            >
                                              {/* Conditionally display odd.hnd if there are multiple markets */}
                                              <Box
                                                color={
                                                  betItems.some(
                                                    (item) =>
                                                      item.oddId === oddId
                                                  )
                                                    ? activeTextColor
                                                    : textColor
                                                }
                                              >
                                                {groupedMarkets.length > 1
                                                  ? odd.hnd
                                                  : odd.oddName}
                                              </Box>
                                              <Box
                                                color={
                                                  betItems.some(
                                                    (item) =>
                                                      item.oddId === oddId
                                                  )
                                                    ? activeOddColor
                                                    : oddColor
                                                }
                                                ml={4}
                                              >
                                                {odd.moneyLine}
                                              </Box>{" "}
                                              {/* Add spacing between oddName and odd value */}
                                            </Flex>
                                          </Button>
                                        )
                                      )}
                                    </Flex>
                                  )
                                )}
                              </AccordionPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <NoEventsIcon boxSize="80px" color="gray.400" />
                          <Text color={secondaryTextColor} mt={4} mb={4}>
                            No Markets Available
                          </Text>
                        </Box>
                      )}
                    </TabPanel>

                    {/* Add more TabPanels as needed */}
                  </TabPanels>
                </Tabs>
              )}
            </Box>

            {!isBetSlipStatus && (
              <Box flex="1" p={4} borderRadius="md">
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="sm" color={secondaryTextColor}>
                    {formattedDate} {formattedTime}
                  </Text>
                  <Text fontSize="sm" color={secondaryTextColor}>
                    AGG 0 : 3
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" color={textColor}>
                    {team1}
                  </Text>
                  <Text fontSize="md" color={textColor}>
                    {team2}
                  </Text>
                </Flex>

                <Box
                  h={{ base: 150, md: 200 }}
                  borderRadius="md"
                  p={4}
                  mb={4}
                  backgroundImage={fieldImage}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    bg="rgba(0, 0, 0, 0.6)"
                    color={secondaryTextColor}
                    borderRadius="md"
                    p={4}
                    textAlign="center"
                  >
                    <Text fontSize="sm">Tofiq Bahramov Republican Stadium</Text>
                    <Text fontSize="sm">Baku</Text>
                    <Text fontSize="sm" mt={2}>
                      31200 CAPACITY
                    </Text>
                  </Box>
                </Box>

                {/* Win Probability */}
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  mb={2}
                  textAlign="center"
                  color={secondaryTextColor}
                >
                  Win Probability
                </Text>
                <MatchDetailsChart
                  height={110}
                  categories={[""]}
                  series={matchDetailsSeries}
                  tooltipFormatter={(val) => `${val}%`}
                />
                {/* Previous Meetings */}
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  mb={2}
                  textAlign="center"
                  color={secondaryTextColor}
                >
                  Previous Meetings
                </Text>
                <MatchDetailsChart
                  height={110}
                  categories={[""]}
                  series={previousMeetingsSeries}
                  tooltipFormatter={(val) => `${val} Wins`}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    );
  }

  return null;
};

const s2p = (state) => ({
  sports: state.sports.sports,
  countries: state.sports.countries,
  leagues: state.sports.leagues,
  events: state.sports.events,
  odds: state.sports.odds,
  wsConnected: state.websocket?.connected || false,
  betItems: state.betSlip.betItems,
  isBetSlipStatus: state.betSlip.isBetSlipStatus,
});

export default connect(s2p)(EventTemplate);
