import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  useColorModeValue,
  useBreakpointValue
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

export default function Footer() {
  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  // Initial state for ratings, all set to 0 initially
  const initialRatings = [0, 0, 0]; // assuming there are 3 testimonials
  const [ratings, setRatings] = useState(initialRatings);

  // Handle star click to update rating for the specific box
  const handleStarClick = (boxIndex, starIndex) => {
    setRatings((prevRatings) =>
      prevRatings.map((rating, index) =>
        index === boxIndex ? (rating === starIndex + 1 ? 0 : starIndex + 1) : rating
      )
    );
  };

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "65px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      color={textColor}
    >
      <SimpleGrid columns={{ base: 2, sm: 2, md: 3 }} spacing="4" m={4} mb={6}>
        {[0, 1, 2].map((boxIndex) => (
          <Box
            key={boxIndex}
            p={6}
            bg="gray.700"
            borderRadius={8}
            maxW="500px"
            mx="auto"
            boxShadow="lg"
          >
            <Heading as="h3" size="lg" mb={4} textAlign="center">
              Testimonial
            </Heading>
            <Text fontSize="lg" textAlign="center" mb={4}>
              I had a great time at WagerStreet. The new website design they just came out
              with is a game changer in my opinion.
            </Text>
            <Flex justify="center" mb={4}>
              {/* Clickable Rating component for each box */}
              {[...Array(5)].map((_, starIndex) => (
                <Icon
                  as={FaStar}
                  key={starIndex}
                  color={starIndex < ratings[boxIndex] ? "yellow.400" : "gray.400"} // Color star yellow if rated
                  boxSize={5}
                  cursor="pointer" // Change cursor to pointer for interactivity
                  onClick={() => handleStarClick(boxIndex, starIndex)} // Update rating on click
                />
              ))}
            </Flex>
            <Text fontWeight="bold" textAlign="center" mb={1}>
              Benjamin Wilson
            </Text>
            <Text fontSize="sm" color="gray.500" textAlign="center">
              User since July 03, 2021
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
}
