import React from "react";
import { useState, useEffect } from "react";

import {
  Box,
  Image,
  Text,
  Flex,
  SimpleGrid,
  Icon,
  Grid,
  GridItem,
  useColorModeValue,
  Menu, MenuButton, MenuList, MenuItem, Button,
  Tabs, TabList, Tab, TabPanel, TabPanels, Table, Thead, Tr, Td, Th, Tbody
} from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import {
  FaBitcoin,
  FaDollarSign

} from "react-icons/fa";
import { GrSort } from "react-icons/gr";
import dracksStack from "assets/img/casino_challenges/drac'sStacks.avif";
import roostersRevenge from "assets/img/casino_challenges/roostersRevenge.avif";
import bonsaiBanzai from "assets/img/casino_challenges/bonsaiBanzai.avif";
import dracksStack83 from "assets/img/casino_challenges/drac'sStack83.09.avif";
import pixelFarm from "assets/img/casino_challenges/pixelFarm.avif";
import roostersRevenge19 from "assets/img/casino_challenges/rooster'sRevenge19.05.avif";
import dracksStack756 from "assets/img/casino_challenges/drack'sStack756.avif";
import labOfLunacy from "assets/img/casino_challenges/LabOfLunacy.avif";
import dracksStack7562 from "assets/img/casino_challenges/drac'sStack7562.avif";
import spinReaper from "assets/img/casino_challenges/spinReaper.avif";
import immortalLightning from "assets/img/casino_challenges/immortalLightning.avif";
import samuraiDogs from "assets/img/casino_challenges/samuraiDogs.avif";
import carpDiem from "assets/img/casino_challenges/carpDiem.avif";
import spinReaper24380 from "assets/img/casino_challenges/spinReaper24380.avif";
import carpDiem2014 from "assets/img/casino_challenges/carpDiem2014.avif";
import carpDiem402 from "assets/img/casino_challenges/carpDiem402.avif";
import immortalLightning4020 from "assets/img/casino_challenges/immortalLIghtning4020.avif";
import labOfLunacy4783 from "assets/img/casino_challenges/labOfLunacy4783.avif";
import labOfLunacy47830 from "assets/img/casino_challenges/labOfLunacy47830.avif";
import immortalLightning24875 from "assets/img/casino_challenges/immortalLightning24875.avif";
import immortalLightning2487 from "assets/img/casino_challenges/immortalLightning2487.avif";
import samuraiDogs2555 from "assets/img/casino_challenges/samuraiDogs21555.avif";









const Challenges = () => {
  const secondaryTextColor = useColorModeValue("gray.400", "gray.400");
  const [allBets, setAllBets] = useState([]);
  const [highRollers, setHighRollers] = useState([]);
  const [raceLeaderboard, setRaceLeaderboard] = useState([]);



  useEffect(() => {
    const fetchAllBets = async () => {
      const data = [
        {
          game: "Plinko",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "Limbo",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "-1999.199920",
        },
        {
          game: "Black Jack",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "LE Bandit",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "2999.199920",
        },
        // Add more dummy data here
      ];
      setAllBets(data);
    };

    const fetchHighRollers = async () => {
      const data = [
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        // Add more dummy data here
      ];
      setHighRollers(data);
    };

    const fetchRaceLeaderboard = async () => {
      const data = [
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        // Add more dummy data here
      ];
      setRaceLeaderboard(data);
    };

    fetchAllBets();
    fetchHighRollers();
    fetchRaceLeaderboard();
  }, []);


  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "75px" }}
      px={{ base: 2, md: 4 }}
      color="white"
      minH="100vh"
    >
  <Box padding="0" width="100%" // Width of 90% for mobile, full width for larger screens
  maxWidth={{ base: "300px", md: "100%" }} backgroundColor="#213743" height="120px" mt={{ base: "-40px", md: "18px" }} overflow="hidden">
  {/* Challenges Header */}
  <Flex alignItems="center" justifyContent="space-between" height="100%" paddingLeft="10px" paddingRight="10px">
    <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="400" color="white" mb="0" ml="10px">
      Challenges
    </Text>
    <Image
      src="https://mediumrare.imgix.net/group-banner-challenges.png"
      alt="Challenges"
      height={{ base: "80px", md: "100%" }}
      width={{ base: "100%", md: "auto" }} 
      maxWidth={{ base: "100%", md: "50%" }} 
      display="block"
      objectFit="contain"
      marginLeft={{ base: "auto", md: "0" }}
    />
  </Flex>
</Box>

      <Box mt="30px" display="flex" justifyContent="flex-end" >
        <GrSort style={{ marginTop: "3px" }} />
        <Text fontSize="s" fontWeight="400" color="white" mb="2px" ml="10px">
          Sort By
        </Text>
        <Menu placement="top"
        >
          <MenuButton mt="-3px" as={Button} variant="outline" size="sm" marginLeft="10px" color="#fff" backgroundColor="rgb(15 33 46)" _hover={{ backgroundColor: "rgb(15 33 46)", color: "#fff" }}
            _active={{ backgroundColor: "rgb(15 33 46)" }}
            _focus={{ boxShadow: "none" }}>
            Choose Option
          </MenuButton>
          <MenuList
            backgroundColor="#fff"
            boxShadow="none"
            border="none"
            width="100%"
            minWidth="80px"
            mt="0"
            _hover={{ backgroundColor: "#fff" }}
            _active={{ backgroundColor: "#fff" }}
            _focus={{ boxShadow: "none" }}

          >
            <MenuItem color="black" backgroundColor="#fff" _hover={{ backgroundColor: "rgb(134 171 219 / 50%)" }}>
              Created
            </MenuItem>
            <MenuItem color="black" backgroundColor="#fff" _hover={{ backgroundColor: "rgb(134 171 219 / 50%)" }}>
              Prize
            </MenuItem>
            <MenuItem color="black" backgroundColor="#fff" _hover={{ backgroundColor: "rgb(134 171 219 / 50%)" }}>
              Bet Size
            </MenuItem>
            <MenuItem color="black" backgroundColor="#fff" _hover={{ backgroundColor: "rgb(134 171 219 / 50%)" }}>
              Multiplier
            </MenuItem>
          </MenuList>
        </Menu>

      </Box>

<Box mt="30px">
      <SimpleGrid
                columns={{ base: 1, sm: 1, md: 3, lg: 5 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "dracksStack", image: dracksStack },
                  { bg: "orange.400", title: "roostersRevenge", image: roostersRevenge },
                  { bg: "purple.300", title: "bonsaiBanzai", image: bonsaiBanzai },
                  { bg: "green.400", title: "dracksStack83", image: dracksStack83 },
                  { bg: "yellow.400", title: "pixelFarm", image: pixelFarm },
                  {
                    bg: "green.300",
                    title: "roostersRevenge19",
                    image: roostersRevenge19,
                  },
                  { bg: "green.600", title: "dracksStack756", image: dracksStack756 },
                  { bg: "purple.500", title: "labOfLunacy", image: labOfLunacy },
                  {
                    bg: "orange.400",
                    title: "dracksStack7562",
                    image: dracksStack7562,
                  },
                  { bg: "purple.300", title: "spinReaper", image: spinReaper },
                  { bg: "green.400", title: "immortalLightning", image: immortalLightning },
                  { bg: "yellow.400", title: "samuraiDogs", image: samuraiDogs },
                  { bg: "green.300", title: "carpDiem", image: carpDiem },
                  {
                    bg: "green.600",
                    title: "spinReaper24380",
                    image: spinReaper24380,
                  },
                  {
                    bg: "purple.500",
                    title: "carpDiem2014",
                    image: carpDiem2014,
                  },
                  {
                    bg: "orange.400",
                    title: "carpDiem402",
                    image: carpDiem402,
                  },
                  {
                    bg: "purple.300",
                    title: "immortalLightning4020",
                    image: immortalLightning4020,
                  },
                  { bg: "green.400", title: "labOfLunacy4783", image: labOfLunacy4783 },
                  { bg: "green.400", title: "labOfLunacy47830", image: labOfLunacy47830 },
                  { bg: "green.400", title: "immortalLightning24875", image: immortalLightning24875 },
                  { bg: "green.400", title: "immortalLightning2487", image: immortalLightning2487 },
                  { bg: "green.400", title: "samuraiDogs2555", image: samuraiDogs2555 },
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      width="188px" // Set the width to 188px
                      height="252px" // Set the height to 252px
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
              </Box>



      <Tabs
        boxShadow="lg"
        variant="solid-rounded"
        colorScheme="teal"
        size="md"
        mb={12}
      >
        <TabList>
          <Tab>My Bets</Tab>
          <Tab>All Bets</Tab>
          <Tab>High Rollers</Tab>
          <Tab>Race Leaderboard</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* All Bets content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Game</Th>
                    <Th className="hide-mobile">User</Th>
                    <Th className="hide-mobile">Time</Th>
                    <Th className="hide-mobile">Bet Amount</Th>
                    <Th className="hide-mobile">Multiplier</Th>
                    <Th>Payout</Th>
                  </Tr>
                </Thead>
                <Tbody >
                  {allBets.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.game}</Td>
                      <Td className="hide-mobile">{bet.user}</Td>
                      <Td className="hide-mobile">{bet.time}</Td>
                      <Td className="hide-mobile">
                        <Flex align="center">
                          {bet.betAmount}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td className="hide-mobile">{bet.multiplier}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.payout}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* High Rollers content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Game</Th>
                    <Th>User</Th>
                    <Th>Time</Th>
                    <Th>Bet Amount</Th>
                    <Th>Multiplier</Th>
                    <Th>Payout</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {highRollers.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.event}</Td>
                      <Td>{bet.user}</Td>
                      <Td>{bet.time}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>{bet.odds}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* Race Leaderboard content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>User</Th>
                    <Th>Wagered</Th>
                    <Th>Prize</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {raceLeaderboard.map((entry, index) => (
                    <Tr key={index}>
                      <Td>{entry.rank}</Td>
                      <Td>{entry.user}</Td>
                      <Td>
                        <Flex align="center">
                          {entry.wagered}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>
                        <Flex align="center">
                          {entry.prize}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Flex>


  );
};

export default Challenges;
