const websocketInitialState = {
  host: '',
  connected: false,
};

export const websocketReducer = (state = { ...websocketInitialState }, action) => {
  switch (action.type) {
    case 'WS_CONNECT':
      return { ...state, host: action.host };
    case 'WS_CONNECTED':
      return { ...state, host: action.host, connected: true };
    case 'WS_DISCONNECTED':
      return { ...state, host: action.host, connected: false };
    default:
      return state;
  }
};