import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Divider,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";

import {
  FacebookIcon,
  GoogleIcon,
  TwitchIcon,
  LineIcon,
} from "components/Icons/Icons";
import { NavLink } from "react-router-dom";

const LoginModal = ({ isLoginOpen, closeLogin, openRegister }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal isOpen={isLoginOpen} onClose={closeLogin} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
      <ModalContent
        bg="gray.700"
        color="gray.400"
        minHeight={["300px", "400px"]} // Responsive height
        maxHeight={["500px", "600px"]} // Responsive max-height
        minWidth={["50%", "30%"]} // Responsive minWidth for mobile screens
        overflow="hidden"
        m={4}
      >
        <ModalHeader fontSize="md" textAlign="center" color="white">
          Sign In
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder="Enter your password" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Box w="100%">
            <Button
              style={{ backgroundColor: "#00E701", color: "black" }}
              w="100%"
              _hover="none"
            >
              Sign In
            </Button>

            <Stack direction="row" align="center" spacing={4} my={4}>
              <Divider borderColor="gray.600" flex="1" />
              <Text fontSize="sm" color="gray.400">
                OR
              </Text>
              <Divider borderColor="gray.600" flex="1" />
            </Stack>
            <Flex justify="center" align="center" mt={4}>
              <SimpleGrid columns={4} spacing={2}>
                <Button
                  size="md"
                  w={["40px", "50px"]} // Responsive width for mobile
                  h={["40px", "50px"]} // Responsive height for mobile
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FacebookIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w={["40px", "50px"]}
                  h={["40px", "50px"]}
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <GoogleIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w={["40px", "50px"]}
                  h={["40px", "50px"]}
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LineIcon boxSize={4} />
                </Button>
                <Button
                  size="md"
                  w={["40px", "50px"]}
                  h={["40px", "50px"]}
                  bg="gray.600"
                  _hover={{ bg: "gray.400" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TwitchIcon boxSize={4} />
                </Button>
              </SimpleGrid>
            </Flex>
            <Text textAlign="center" fontSize="sm" mt={4}>
              <NavLink style={{ color: "white" }}> Forgot Password?</NavLink>
            </Text>
            <Text textAlign="center" fontSize="sm" color="gray.400" mt={4}>
              Dont have an account?
              <NavLink
                style={{ color: "white" }}
                onClick={() => {
                  openRegister();
                  closeLogin();
                }}
              >
                {" "}
                Register an Account
              </NavLink>
            </Text>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
