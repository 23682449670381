// Chakra imports
import {
  ChakraProvider,
  Portal,
  useDisclosure,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar";
import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import routes from "routes.js";
import leftMenu from "data/menu/leftMenu.json";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import RegisterModal from "views/Auth/RegisterModal";
import LoginModal from "views/Auth/LoginModal";
import Betslip from "views/BetslipTemplate";

// Dashboard component
const Dashboard = (props) => {
  const { isBetSlipStatus, ...rest } = props; // Destructure isBetSlipStatus from props

  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false); // Lifted state
  // const isBetSlipVisible = useBreakpointValue({ base: false, md: true });

  const [isBetSlipVisible, setIsBetSlipVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsBetSlipVisible(true);
      } else {
        setIsBetSlipVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Run on initial load
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // State for managing the open/close status of each modal
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isRegisterOpen, setRegisterOpen] = useState(false);

  // Handlers for Login modal
  const openLogin = () => setLoginOpen(true);
  const closeLogin = () => setLoginOpen(false);

  // Handlers for Register modal
  const openRegister = () => setRegisterOpen(true);
  const closeRegister = () => setRegisterOpen(false);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed); // Function to toggle

  // functions for changing the states from components
  const getRoute = () => window.location.pathname !== "/full-screen-maps";

  const getActiveRoute = (routes) => {
    let activeRoute = "Home";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      } else {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
    });

  document.documentElement.dir = "ltr";

  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Sidebar
        routes={routes}
        leftMenu={leftMenu}
        logoText={"Wager-Street"}
        display="none"
        sidebarVariant={sidebarVariant}
        isCollapsed={isCollapsed} // Pass state to Sidebar
        toggleSidebar={toggleSidebar} // Pass toggle function to Sidebar
        {...rest}
      />
      <Flex direction="row" w="100%" h="100vh">
        {/* Main content with MainPanel */}
        <MainPanel
          w={{
            base: "100%",
            // lg: "calc(100% - 50px)",
            xl: isCollapsed ? "calc(100% - 80px)" : "calc(100% - 150px)",
          }}
          marginLeft={{
            base: "",
            lg: "70px",
            xl: isCollapsed ? "100px" : "250px",
          }}
        >
          <Portal>
            <AdminNavbar
              maxW={"1440px"}
              openLogin={openLogin}
              openRegister={openRegister}
              logoText={"Wager-Street"}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              isCollapsed={isCollapsed}
              {...rest}
            />
          </Portal>
          {/* Login Modal */}
          <LoginModal
            isLoginOpen={isLoginOpen}
            closeLogin={closeLogin}
            openLogin={openLogin}
            openRegister={openRegister}
          />
          {/* Register Modal */}
          <RegisterModal
            isRegisterOpen={isRegisterOpen}
            closeRegister={closeRegister}
            openLogin={openLogin}
          />
          {getRoute() ? (
            <PanelContent>
              <PanelContainer>
                <Outlet />
                <Routes>
                  {getRoutes(routes)}
                  <Route path="/" element={<Navigate to="/home" replace />} />
                </Routes>
              </PanelContainer>
            </PanelContent>
          ) : null}
          <Footer />
          <SidebarResponsive
            logoText={"Wager-Street"}
            secondary={getActiveNavbar(routes)}
            routes={routes}
            leftMenu={leftMenu}
            {...rest}
          />
        </MainPanel>

        {/* BetSlip on the right side */}
        {/* <Betslip
          w={{ base: "100%", xl: "300px" }} // Set fixed width for BetSlip
          position="relative"
          bg="gray.100"
          h="100vh" // Full height
        /> */}
        {/* BetSlip on the right side */}
        {isBetSlipStatus && isBetSlipVisible && (
          <Betslip
            className={isBetSlipVisible ? "betslip-visible" : "betslip-hidden"}
            // betItems={betItems}
            // closeBetSlip={closeBetSlip} // Pass toggle function to BetSlip
            // selectBetSlip={selectBetSlip}
            // clearAllBetItems={clearAllBetItems}
            // event={event}
          />
        )}
      </Flex>
    </ChakraProvider>
  );
};

const s2p = (state) => ({
  isBetSlipStatus: state.betSlip.isBetSlipStatus, // map the state to props
});

// Connect Redux store to the Dashboard component
export default connect(s2p)(Dashboard);
