export const breakpoints = {
  sm: "320px",
  msm: "375px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  xxl: "1400px",
  xxxl: "1600px",
  xxxxl: "1900px",
};
