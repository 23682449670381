import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import logoImage from "assets/img/blog/logo-image.png";
import SportsImage1 from "assets/img/blog/Sports/image-1.webp";
import SportsImage2 from "assets/img/blog/Sports/image-2.webp";
import SportsImage3 from "assets/img/blog/Sports/image-3.webp";
import SportsImage4 from "assets/img/blog/Sports/image-4.webp";
import SportsImage5 from "assets/img/blog/Sports/image-5.webp";
import SportsImage6 from "assets/img/blog/Sports/image-6.webp";
import SportsImage7 from "assets/img/blog/Sports/image-7.webp";
import { NavLink } from "react-router-dom";


const Sports = () => {
    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        Sports
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%",sm:"50%", md: "50%"  }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage1}
                            alt="UFC Picks & Expert UFC Predictions"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage2}
                            alt="2024 MLB Predictions & World Series Picks"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage3}
                            alt="New Games on New Street: August 30th 2024 Casino Releases & Sports Promos"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage4}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>
                    <NavLink to="/blog/mlb_predictions_and_world_series_picks">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage5}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        2024 MLB Predictions & World Series Picks
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        MLB predictions for the 2024 season are here, with baseball picks to
                win from our expert tipsters. See which team is predicted to win the
                World Series and make your selections on our Sportsbook!                        </Text>
                    </Box>
                    </NavLink>
                    <NavLink to="/blog/champions_league_predictions">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={SportsImage6}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        Champions League Predictions
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        The return of the UEFA Champions League is nearly upon us, with soccer fans eagerly anticipating the brand new look for the 2024-25 campaign of the most prestigious European competition.
                        </Text>
                    </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default Sports;
