import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Text,
    SimpleGrid,
    VStack,
    Badge,
    Icon,
    useColorModeValue,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    useBreakpointValue,
    Divider,
    Select
} from '@chakra-ui/react';
import { FaStar, FaBitcoin, FaAngleLeft, FaAngleDown, FaRocket, FaUserAlt, FaArrowCircleUp, FaCog } from 'react-icons/fa';
import { RiCheckboxCircleFill } from 'react-icons/ri';

export default function VIP_Club() {
    // const [likeStatus, setLikeStatus] = useState(false);

    const bg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.800", "white");
    const tabBg = useColorModeValue("gray.100", "gray.700");
    const questionsBg = useColorModeValue("gray.200", "gray.800");
    const tabOrientation = useBreakpointValue({
        base: "horizontal",
        md: "vertical",
    });


    const items = [
        {
            id: 1,
            title: "Why is WagerStreet’s VIP program the best?",
            content: [
                "WagerStreet’s VIP program is often rated as one of the best online casino experiences due to the amount of bonuses we give out to players.",
                "Additionally, our award winning support team is online 24/7 to answer any questions you have. We have the highest customer satisfaction rate out of many online casinos where we have amassed a community of some of the most passionate gamblers across the globe."
            ]
        },
        {
            id: 2,
            title: "How much has Wager Street given out in bonuses?",
            content: [
                "We have given over $1 Billion in bonuses. This is primarily the reason we have the best VIP program online.We truly believe in rewarding our players for their gameplay and loyalty."
            ]
        },
        {
            id: 3,
            title: "How do I enter the $75,000 weekly raffle?",
            content: [
                "To get one ticket to enter the raffle, you must wager $1000 on WagerStreet.net. The more you wager, the more tickets you get, which increases your chances of winning"
            ]
        },
        {
            id: 4,
            title: "Where can I find the Wager Street Telegram Channel?",
            content: [
                "The Wager Street Telegram channel is (@WagerStreetCasino).",
                "Click here to join if you have already downloaded Telegram."
            ]
        },
        {
            id: 5,
            title: "Where can I find the Wager Street VIP Telegram channel?",
            content: [
                "Once you reach Bronze, you can ask live support to be added to the Wager Street VIP Telegram Channel.Alternatively, you can find more information about this by joining the Wager Street Telegram channel (@WagerStreetCasino)"
            ]
        },

        // Add more items with unique IDs
    ];

    const items2 = [
        {
            id: 6,
            title: "What is a recent gameplay bonus?",
            content: [
                "This is a bonus given to you by the discretion of your host or VIP manager and is based on significant player wagers and luck."
            ]
        },
        {
            id: 7,
            title: "What is rakeback?",
            content: [
                "Rakeback is a percentage of a player's rake (house edge) refunded to you."
            ]
        },
        {
            id: 8,
            title: "What is a reload? How do I claim my reload?",
            content: [
                "Reload is a bonus which is calculated on the basis of a player's recent activity. A player is eligible to choose between either a Daily, Hourly or 10 Minute reload intervals.", "Go to the user panel on the top right hand side of the screen and click on VIP. A modal will appear. Click on the reload tab and click on the reload button to claim your reload.", "Having a host is dependable on your gameplay."
            ]
        },
        {
            id: 9,
            title: "When is the Monthly bonus scheduled for?",
            content: [
                "The monthly bonus is distributed once a month. The date is generally around the 15th. In some instances it can be a little later or even earlier. This is why it’s one of the most anticipated bonuses on Wager Street. When it’s released, you will be notified via email. To ensure you do not miss out on a bonus, please check your spam folder in your email.", "Bronze, Silver & Gold players will receive the bonus in the form of a daily reload, whilst Platinum and Diamond players get their bonus in the form of a one time bonus claim."
            ]
        },
        {
            id: 10,
            title: "How do I calculate the amount I need to wager to move to the next level?",
            content: [
                "Firstly, view the percentage left in your VIP progress bar in your account page on the top right hand corner. Multiply the percentage number left to the full 100% bar with the wager requirement you need to meet to unlock the new level."
            ]
        },

        // Add more items with unique IDs
    ];

    const items3 = [
        {
            id: 11,
            title: "What can my VIP Host do for me?",
            content: [
                "Your VIP Host is there to ensure you are being rewarded for your gaming experiences. They assist with reload renewals, guide you through your level-up progression, review sports limits, assess extra bonuses when available and address any issues faced by a user on the site."
            ]
        },
        {
            id: 12,
            title: "When I’m assigned a VIP host, does my Reload become a continuous or renewable benefit?",
            content: [
                "When you reach Platinum IV and get assigned a host, you start receiving weekly reloads that are renewable. Once your weekly reload expires, you can contact your host who can renew your reload for the following 7 day period.", "Your reload amount is based on your weekly gaming activity, aiming to give extra on top if you’ve had an unlucky week in terms of profit."
            ]
        },
        {
            id: 13,
            title: "What is the job of a VIP host and how does it differ from regular Live Support assistance?",
            content: [
                "VIP hosts are only given to players in Platinum IV and beyond. They are there to answer your queries about the VIP program and to ensure that you are collecting all the bonuses you are entitled to. VIP hosts have the ability to send more frequent and larger bonuses to players whilst ensuring the bonuses are befitting to your needs.", "Live Support is there to help you with any technical issues you encounter on the platform"
            ]
        },
        {
            id: 14,
            title: "When do I get a VIP host?",
            content: [
                "VIP hosts are given to a player when they reach Platinum IV. They will be your dedicated support member who will help you with all of your queries and help guide your gaming experience. Eligibility is also subject to recent activity."
            ]
        },
        {
            id: 15,
            title: "What can I do if my VIP host is on vacation?",
            content: [
                "Make sure you ask your VIP host for a link to their unique VIP hosting channel. If you’ve missed out on doing so, you can send a message to live support and you will be given a VIP backup link. This backup host will be temporary until your host comes back."
            ]
        },

        // Add more items with unique IDs
    ];

    const ToggleItem = ({ id, title, content, isOpen, onClick }) => (
        <Box
            alignItems="center"
            backgroundColor={questionsBg}
            borderRadius="md"
            cursor="pointer"
            onClick={() => onClick(id)}
        >
            <Flex p={4}>
                <Box flex="1">
                    <Text>{title}</Text>
                </Box>
                <Box>
                    <Icon as={isOpen ? FaAngleDown : FaAngleLeft} w={6} h={6} ml="4" />
                </Box>
            </Flex>
            {isOpen && (
                <Box>
                    <Divider borderColor="gray.600" mb="4" />
                    <Box p={4}>
                        {content.map((text, index) => (
                            <Text key={index}>{text}</Text>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );


    const TimelineItem = ({ color }) => (
        <Box pos="relative" bg={tabBg} borderRadius={"50px"} p={2}>
            <Icon as={FaStar} w={10} h={10} color={color} />
        </Box>
    );

    const TimelineLine = () => (
        <Box
            h="5px"
            bg={tabBg}
            flex="1"
            mx={4}
        />
    );

    const [openItems, setOpenItems] = useState({});

    const toggleOpen = (id) => {
        setOpenItems((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    function RankingCard({ width, title, amount, iconColor, features }) {
        return (
            <Box>
                <Flex align="center" justify="space-between" w="100%" maxW="1000px" mx="auto" py={8}>
                    <TimelineItem color={iconColor} />
                    <TimelineLine />
                </Flex>
                <Box bg={tabBg} p={4} borderRadius={"lg"}>
                    <Badge colorScheme={textColor} variant="outline" bg={iconColor} fontSize={"lg"} p={1} mb={4} borderRadius={"lg"}>
                        {title}
                    </Badge>
                    <Text fontSize="3xl" fontWeight="bold">
                        {amount}
                    </Text>
                    <Text>
                        Wager amount
                    </Text>
                    <VStack spacing={2} align="flex-start" mt={4}>
                        {features.map((feature) => {
                            return (
                                <Flex key={feature}>
                                    <Icon as={RiCheckboxCircleFill} color={iconColor} boxSize="2em" />
                                    <Text ml={2} mt={1}>{feature}</Text>
                                </Flex>
                            )
                        })}
                    </VStack>
                </Box>
            </Box>

        );
    }

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <style>
                {`
          .table-wrapper {
              overflow-x: auto;
             -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          }

          @media (max-width: 600px) {
            .hide-mobile {
              display: none;
            }
          }
        `}
            </style>
            <Box padding="4" bg={bg}>
                <Container maxW="container.xl">
                    {/* Header Section */}
                    <VStack spacing={4} textAlign="center">
                        <Heading>The Unrivaled VIP Experience</Heading>
                        <Text fontSize="lg">
                            Unlock exclusive benefits and receive instantly withdrawable bonuses
                            without any strings attached
                        </Text>
                        <Button colorScheme="blue" size="lg">
                            Sign up
                        </Button>
                    </VStack>

                    {/* VIP Ranking */}
                    <Box mt={16} >
                        <Text fontSize="3xl" fontWeight={"bold"} textAlign="center" mb={8}>
                            Wager Street VIP Ranking System
                        </Text>
                        <div className='table-wrapper'>
                            <Box width={"1100px"}>
                                <SimpleGrid columns={{ base: 4 }} spacing={8}>
                                    <RankingCard
                                        title="Bronze"
                                        amount="$10k"
                                        iconColor="orange.400"
                                        features={['Monthly Bonuses', 'Level Up Bonuses', 'Rakeback']}
                                    />
                                    <RankingCard
                                        title="Silver"
                                        amount="$50k - $100k"
                                        iconColor="gray.400"
                                        features={['Monthly Bonuses', 'Level Up Bonuses', 'Rakeback']}
                                    />
                                    <RankingCard
                                        title="Gold"
                                        amount="$100k"
                                        iconColor="yellow.400"
                                        features={['Monthly Bonuses', 'Level Up Bonuses', 'Rakeback']}
                                    />
                                    <RankingCard
                                        title="Platinum I-III"
                                        amount="$250k - $1M"
                                        iconColor="cyan.400"
                                        features={['Monthly Bonuses', 'Level Up Bonuses', 'Rakeback']}
                                    />
                                </SimpleGrid>
                            </Box>
                        </div>


                    </Box>


                    {/* VIP Benefits */}
                    <Box mt={16} >
                        <Text fontSize="3xl" fontWeight={"bold"} textAlign="center" mb={8}>
                            Wager Street VIP Club Benefits
                        </Text>
                        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="4" mb={6}>
                            {/* Crypto Category */}
                            <Flex
                                alignItems="center"
                                backgroundColor={tabBg}
                                borderRadius="md"
                                p="4"
                                cursor="pointer"
                            >
                                <Icon as={FaRocket} w={8} h={8} color="orange.400" mr="4" />
                                <Box>
                                    <Text fontSize="xl" fontWeight="bold">
                                        Boost
                                    </Text>
                                    <Text>
                                        Every week and every month, expect a fresh bonus based on your recent games. The more you play, the higher the bonuses.
                                    </Text>
                                </Box>
                            </Flex>

                            <Flex
                                alignItems="center"
                                backgroundColor={tabBg}
                                borderRadius="md"
                                p="4"
                                cursor="pointer"
                            >
                                <Icon as={FaUserAlt} w={8} h={8} color="purple.400" mr="4" />
                                <Box>
                                    <Text fontSize="xl" fontWeight="bold">
                                        Dedicated VIP Host
                                    </Text>
                                    <Text>
                                        Receive your own dedicated VIP host who will support and cater to your betting needs
                                    </Text>
                                </Box>
                            </Flex>

                            <Flex
                                alignItems="center"
                                backgroundColor={tabBg}
                                borderRadius="md"
                                p="4"
                                cursor="pointer"
                            >
                                <Icon as={FaArrowCircleUp} w={8} h={8} color="purple.400" mr="4" />
                                <Box>
                                    <Text fontSize="xl" fontWeight="bold">
                                        Recent Play Bonuses
                                    </Text>
                                    <Text>
                                        Having a rough streak of luck? Wager Street offers money back on losses every time you level up
                                    </Text>
                                </Box>
                            </Flex>
                            <Flex
                                alignItems="center"
                                backgroundColor={tabBg}
                                borderRadius="md"
                                p="4"
                                cursor="pointer"
                            >
                                <Icon as={FaStar} w={8} h={8} color="purple.400" mr="4" />
                                <Box>
                                    <Text fontSize="xl" fontWeight="bold">
                                        Level-Ups
                                    </Text>
                                    <Text>
                                        Reach a new level and get paid. The level-ups get better the higher you go.
                                    </Text>
                                </Box>
                            </Flex>
                            <Flex
                                alignItems="center"
                                backgroundColor={tabBg}
                                borderRadius="md"
                                p="4"
                                cursor="pointer"
                            >
                                <Icon as={FaCog} w={8} h={8} color="purple.400" mr="4" />
                                <Box>
                                    <Text fontSize="xl" fontWeight="bold">
                                        Bespoke
                                    </Text>
                                    <Text>
                                        Work with your dedicated VIP host to tailor benefits to your gaming needs.
                                    </Text>
                                </Box>
                            </Flex>
                        </SimpleGrid>
                    </Box>

                    {/*Questions Section*/}
                    <Box mt={16}>
                        <Text fontSize="3xl" fontWeight={"bold"} textAlign="center" >
                            Frequently Asked Questions
                        </Text>
                        <Text fontSize="2xl" textAlign="center" mb={8}>
                            Reach out to our award winning support team
                        </Text>
                        <Tabs
                            orientation={tabOrientation}
                            variant="soft-rounded"
                            colorScheme="teal"
                            mt={4}
                        >
                            <TabList
                                width={{ base: "100%", md: "25%" }}
                                bg={tabBg}
                                borderRadius="md"
                                p={4}
                                mb={2}
                            >
                                <Tab
                                    fontWeight="bold"
                                    _selected={{ bg: "teal.400", color: "white" }}
                                >
                                    General
                                </Tab>
                                <Tab
                                    fontWeight="bold"
                                    _selected={{ bg: "teal.400", color: "white" }}
                                >
                                    Benefits
                                </Tab>
                                <Tab
                                    fontWeight="bold"
                                    _selected={{ bg: "teal.400", color: "white" }}
                                >
                                    VIP Host
                                </Tab>
                            </TabList>
                            <TabPanels
                                width={{ base: "100%", md: "75%" }}
                                bg={tabBg}
                                borderRadius="md"
                                p={4}
                            >
                                <TabPanel>
                                    <SimpleGrid spacing="4" mb={6}>
                                        {items.map((item) => (
                                            <ToggleItem
                                                key={item.id}
                                                id={item.id}
                                                title={item.title}
                                                content={item.content}
                                                isOpen={!!openItems[item.id]}
                                                onClick={toggleOpen}
                                            />
                                        ))}
                                    </SimpleGrid>
                                </TabPanel>

                                <TabPanel>
                                    <SimpleGrid spacing="4" mb={6}>
                                        {items2.map((item) => (
                                            <ToggleItem
                                                key={item.id}
                                                id={item.id}
                                                title={item.title}
                                                content={item.content}
                                                isOpen={!!openItems[item.id]}
                                                onClick={toggleOpen}
                                            />
                                        ))}
                                    </SimpleGrid>
                                </TabPanel>

                                <TabPanel>
                                    <SimpleGrid spacing="4" mb={6}>
                                        {items3.map((item) => (
                                            <ToggleItem
                                                key={item.id}
                                                id={item.id}
                                                title={item.title}
                                                content={item.content}
                                                isOpen={!!openItems[item.id]}
                                                onClick={toggleOpen}
                                            />
                                        ))}
                                    </SimpleGrid>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>

                    {/*Chat Section*/}
                    <Box mt={8} alignItems="center"
                        backgroundColor={tabBg}
                        borderRadius="md"
                        p="4"
                        cursor="pointer">
                        <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} spacing="4" mb={6}>
                            <Box >
                                <Text fontSize="3xl" fontWeight={"bold"}>
                                    Live, 24-hour customer support
                                </Text>
                                <Text fontSize="lg">
                                    Real support from real people. We are available through instant live chat and email to help you.
                                </Text>
                            </Box>

                            <Box>
                                <Select border={'none'}>
                                    <option value=''>English</option>
                                    <option value='option2'>Hindi</option>
                                </Select>
                            </Box>
                            <Button
                                bg="teal.400"
                                color="white"
                                _hover={{ bg: "teal.300" }}
                            >
                                Chat With us
                            </Button>
                        </SimpleGrid>
                    </Box>
                </Container>
            </Box>
        </Flex>
    );
}
