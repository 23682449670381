import { combineReducers } from 'redux';
import { sportReducer } from './sports';
import { websocketReducer } from './websocket';
import { betSlipReducer} from './betslip';

const rootReducer = combineReducers({
  sports: sportReducer,
  websocket: websocketReducer,
  betSlip: betSlipReducer,
});

export default rootReducer;