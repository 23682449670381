// actions/betslipActions.js
export const SET_BET_ITEMS = "SET_BET_ITEMS";
export const CLEAR_BET_ITEMS = "CLEAR_BET_ITEMS";
export const SET_BETSLIP_STATUS = "SET_BETSLIP_STATUS";
export const REMOVE_BET_ITEM = "REMOVE_BET_ITEM";
export const SET_BETSLIP_COORDS = "SET_BETSLIP_COORDS";

export const setBetItems = (betItems) => ({
  type: SET_BET_ITEMS,
  payload: betItems,
});

export const clearBetItems = () => ({
  type: CLEAR_BET_ITEMS,
});

export const setBetSlipStatus = (status) => ({
  type: SET_BETSLIP_STATUS,
  payload: status,
});

export const removeBetItem = (oddId) => ({
  type: REMOVE_BET_ITEM,
  payload: oddId,
});

export const setBetslipCoords = (coords) => ({
  type: SET_BETSLIP_COORDS,
  payload: coords,
});
