import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Select,
  Text,
  Stack,
  Grid,
  Heading,
  Icon,
  Divider,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { FaBitcoin, FaDollarSign, FaSearch } from "react-icons/fa";
import {
  FaStar,
  FaAngleLeft,
  FaAngleDown,
  FaRocket,
  FaUserAlt,
  FaArrowCircleUp,
  FaCog,
  FaChartLine,
  FaYoutube,
} from "react-icons/fa";
import { FiGrid } from "react-icons/fi";
import { useLocation, NavLink } from "react-router-dom";
import eventsByWnba from "data/eventsByWnba";
import { useWebSocket } from "../../provider/WebSocketContext.js";
import { LiveIcon } from "components/Icons/Icons";

const Soccer = () => {
  const location = useLocation();
  const eventsData = eventsByWnba;
  const secondaryTextColor = useColorModeValue("gray.400", "gray.100");
  const bg = useColorModeValue("gray.100", "gray.700");
  const buttonBg = useColorModeValue("gray.600", "gray.900");
  const [allBets, setAllBets] = useState([]);
  const [highRollers, setHighRollers] = useState([]);
  const [raceLeaderboard, setRaceLeaderboard] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const sportId = 342;

  const {
    wsEventsData,
    fetchCountries,
    wsCountriesData,
    isWsReady,
    fetchLeagues,
    wsLeaguesData,
  } = useWebSocket();
  const events = wsEventsData.events;
  const [eventCount, setEventsCount] = useState(0);

  console.log(wsCountriesData);
  console.log(wsLeaguesData);
  const countries = wsCountriesData[342] || [];

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [isOpenCountry, setIsOpenCountry] = useState({});
  const [isOpenLeague, setIsOpenLeague] = useState({});
  
  // Fetch leagues when a country is clicked
  const handleCountryClick = (countryId) => {
    setSelectedCountry(countryId);
    setIsOpenCountry((prevState) => ({
      ...prevState,
      [countryId]: !prevState[countryId],
    }));
    console.log(sportId)
    console.log(countryId)
    fetchLeagues(sportId, countryId);
  };

  // Handle league dropdown click
  const handleLeagueClick = (leagueId) => {
    setSelectedLeague(leagueId);
    setIsOpenLeague((prevState) => ({
      ...prevState,
      [leagueId]: !prevState[leagueId],
    }));
  };

  useEffect(() => {
    console.log(sportId);
    if (sportId && isWsReady) {
      console.log(
        `Fetching Countries for sportId: ${sportId}`
      );
      fetchCountries(sportId); // Only called when WebSocket is ready
    }
  }, [sportId, isWsReady]);

  useEffect(() => {
    if (events && Array.isArray(events)) {
      console.log("WebSocket Events Data:", events);
      setEventsCount(events.length);
    } else {
      console.error("Invalid WebSocket Events Data:", events);
    }
  }, [events]); // Only logs when wsEventsData changes

  const formatStringForURL = (str) => {
    return str.replace(/\s+/g, ""); // Remove all white spaces
  };

  
  const EventCard = ({ eventData }) => {
    console.log(eventData);
    const date = new Date(eventData.startTime);
    const formattedDateTime = date.toUTCString();

    const teams = eventData?.teams?.split(" - ").map((team) => team.trim());
    const team1 = teams[0];
    const team2 = teams[1];

    return (
      <NavLink
        to={`events/${formatStringForURL(eventData.teams)}/?eventId=${
          eventData.brId
        }&&sportId=${eventData.sportId}`}
        style={{ textDecoration: "none" }}
      >
        <Box
          
          p={4}
          rounded="md"
          shadow="md"
          color="white"
          borderWidth={1}
          borderColor="gray.700"
        >
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 2fr" }} // Adjust columns for responsiveness
            gap={4}
            alignItems="center"
            overflowX="auto" // Enable horizontal scrolling on smaller screens
            whiteSpace="nowrap" // Prevent tabs from wrapping
            
          >
            <Box>
              <Stack spacing={2} align="flex-start">
                <Text fontSize="sm" color="gray.400">
                  {formattedDateTime}
                </Text>
                <Stack spacing={1} align="flex-start">
                  <Text color={secondaryTextColor} fontWeight="bold">{eventData.teams}</Text>
                </Stack>
              </Stack>
            </Box>

            <Stack direction="row" align="center" justify={"flex-end"} w="100%">
              <Stack spacing={2} align="flex-start">
                <Icon color={secondaryTextColor} as={FaChartLine} w={6} h={6} />
                <Icon color={secondaryTextColor} as={FaYoutube} w={6} h={6} />
              </Stack>

              <Button
                bg={buttonBg}
                _hover={{ bg: "gray.600" }}
                height={"60px"}
                width={"220px"}
                borderRadius={"lg"}
              >
                <Stack spacing={2} align="flex-start">
                  <Text color={secondaryTextColor}>{team1}</Text>
                  <Text color={secondaryTextColor}>1.49</Text>
                </Stack>
              </Button>
              {/* Conditional Rendering: Show 3 buttons if sportId is "342" which is Soccer, otherwise 2 buttons */}
              {wsEventsData?.sport?.sportId == "342" ? (
                <>
                  {/* Second Button (Draw) */}
                  <Button
                    bg={buttonBg}
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={"220px"}
                    borderRadius={"lg"}
                  >
                    <Stack spacing={2} align="flex-start">
                      <Text color={secondaryTextColor}>Draw</Text>
                      <Text color={secondaryTextColor}>2.65</Text>
                    </Stack>
                  </Button>

                  {/* Third Button */}
                  <Button
                    bg={buttonBg}
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={"220px"}
                    borderRadius={"lg"}
                  >
                    <Stack spacing={2} align="flex-start">
                      <Text color={secondaryTextColor}>{team2}</Text>
                      <Text color={secondaryTextColor}>1.89</Text>
                    </Stack>
                  </Button>
                </>
              ) : (
                <>
                  {/* Only two buttons, skipping the Draw button */}
                  <Button
                    bg="gray.900"
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={"200px"}
                    borderRadius={"lg"}
                  >
                    <Stack spacing={2} align="flex-start">
                      <Text>{team2}</Text>
                      <Text>7/20</Text>
                    </Stack>
                  </Button>
                </>
              )}

              <Stack direction="row" align="center">
                <Text>+14</Text>
              </Stack>
            </Stack>
          </Grid>
        </Box>
      </NavLink>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "65px" }} bg={bg}>
      <Box p={6} minH="100vh">
        <style>
          {`
          .table-wrapper {
              overflow-x: auto;
             -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          }

          @media (max-width: 600px) {
            .hide-mobile {
              display: none;
            }
          }
        `}
        </style>
        {/* Breadcrumb Navigation */}
        <Flex alignItems="center" mb={4}>
          <IconButton
            icon={<ChevronLeftIcon />}
            variant="ghost"
            aria-label="Back"
          />
          <Breadcrumb separator=">">
            <BreadcrumbItem>
              <BreadcrumbLink href="#">
                Soccer
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>

        {/* Tabs */}
        <Tabs variant="soft-rounded" colorScheme="teal">
          <TabList
            overflowX="auto" // Enable horizontal scrolling on smaller screens
            whiteSpace="nowrap" // Prevent tabs from wrapping
            pb={4}
          >
            <Tab>Live & Upcoming</Tab>
            <Tab>Outrights</Tab>
            <Tab>All</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box
                alignItems="center"
                
                borderRadius="md"
                cursor="pointer"
              >
               {/* Render Country Dropdowns */}
              {countries.length > 0 ? (
  countries.map((country) => (
    <Box key={country.countryId} borderRadius="md" cursor="pointer">
      <Flex p={4} onClick={() => handleCountryClick(country.countryId)}>
        <Box flex="1">
          <Text>{country.countryName || "Country"}</Text>
        </Box>
        <Icon
          as={isOpenCountry[country.countryId] ? FaAngleDown : FaAngleLeft} // Use isOpenCountry state
          w={6}
          h={6}
          ml="4"
        />
      </Flex>

      {/* League dropdown */}
      {isOpenCountry[country.countryId] && (  // Check isOpenCountry here
        <Box>
          {Array.isArray(wsLeaguesData[country.countryId]) && wsLeaguesData[country.countryId].length > 0 ? (  // Ensure leagues are fetched for this country
            wsLeaguesData[country.countryId].map((league) => (
              <Box key={league.leagueId} p={4}>
                <Text fontSize={"xl"}>{league.leagueName}</Text>

                {/* EventCard inside League */}
                {league.events && league.events.length > 0 ? (
                  league.events.map((event) => (
                    <EventCard key={event.eventId} eventData={event} />
                  ))
                ) : (
                  <Text>No events found</Text>
                )}
              </Box>
            ))
          ) : (
            <Text>No leagues found</Text>
          )}
        </Box>
      )}
    </Box>
  ))
) : (
  <Text>No countries found</Text>
)}


              </Box>
            </TabPanel>
            {/* Add other TabPanels here if needed */}
          </TabPanels>
        </Tabs>

        <Tabs
          boxShadow="lg"
          variant="solid-rounded"
          colorScheme="teal"
          size="md"
          mb={12}
          pt={4}
        >
          <TabList
            overflowX="auto" // Enable horizontal scrolling on smaller screens
            whiteSpace="nowrap" // Prevent tabs from wrapping
            pb={4}
          >
            <Tab>All Bets</Tab>
            <Tab>High Rollers</Tab>
            <Tab>Race Leaderboard</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* My Bets content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Game</Th>
                      <Th className="hide-mobile">User</Th>
                      <Th className="hide-mobile">Time</Th>
                      <Th className="hide-mobile">Bet Amount</Th>
                      <Th className="hide-mobile">Multiplier</Th>
                      <Th>Payout</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allBets.map((bet, index) => (
                      <Tr key={index}>
                        <Td>{bet.game}</Td>
                        <Td className="hide-mobile">{bet.user}</Td>
                        <Td className="hide-mobile">{bet.time}</Td>
                        <Td className="hide-mobile">
                          <Flex align="center">
                            {bet.betAmount}
                            <FaBitcoin
                              color="orange"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td className="hide-mobile">{bet.multiplier}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.payout}
                            <FaBitcoin
                              color="orange"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* High Rollers content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Game</Th>
                      <Th>User</Th>
                      <Th>Time</Th>
                      <Th>Bet Amount</Th>
                      <Th>Multiplier</Th>
                      <Th>Payout</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {highRollers.map((bet, index) => (
                      <Tr key={index}>
                        <Td>{bet.event}</Td>
                        <Td>{bet.user}</Td>
                        <Td>{bet.time}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.betAmount}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td>{bet.odds}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.betAmount}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* Race Leaderboard content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Rank</Th>
                      <Th>User</Th>
                      <Th>Wagered</Th>
                      <Th>Prize</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {raceLeaderboard.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.rank}</Td>
                        <Td>{entry.user}</Td>
                        <Td>
                          <Flex align="center">
                            {entry.wagered}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td>
                          <Flex align="center">
                            {entry.prize}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};
export default Soccer;
