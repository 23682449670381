// import
import Casino from "views/Dashboard/Casino";
import Sports from "views/Dashboard/Sports";
import Live from "views/Dashboard/Live";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import LeagueTemplate from "views/LeaguesTemplate";
import EventTemplate from "views/EventTemplate";
import Affiliate from "views/Dashboard/Affiliate";
import Blog from "views/Dashboard/Blog";
import Strategy from "views/Dashboard/Blog/Strategy/Index";
import Crypto from "views/Dashboard/Blog/Crypto";
import HowToGuide from "views/Dashboard/Blog/How to Guide";
import StakeNews from "views/Dashboard/Blog/Stake News";
import BlogSports from "views/Dashboard/Blog/Sports";
import BlogCasino from "views/Dashboard/Blog/Casino";
import Other from "views/Dashboard/Blog/Other";
import UtfPicks from "views/Dashboard/Blog/Utf-Picks";
import Mlb_Predictions from "views/Dashboard/Blog/Mlb_prdictions";
import New_Game from "views/Dashboard/Blog/New_games";
import Double_Chance_Betting from "views/Dashboard/Blog/Double_Chance_Betting/Index";
import Common_Sports_Betting from "views/Dashboard/Blog/Common_Sports_Betting";
import New_Games_On_Casino from "views/Dashboard/Blog/New_Games_On_Casino";
import Review from "components/Review/index.js"
import ChampionsLeaguePredictions from "views/Dashboard/Blog/Champions_League_Predictions";
import EuropaLeaguePredictions from "views/Dashboard/Blog/Europa_League_Predictions";
import PlayStakePokerTournaments from "views/Dashboard/Blog/Play_Stake_Poker_Tournaments";
import WhatAreHoldAndWinSlotFeatures from "views/Dashboard/Blog/What_are_Hold_And_Win_Slot_Features";
import CornerBetting from "views/Dashboard/Blog/Corner_Betting";
import OtherSubBlog from "views/Dashboard/Blog/Other_subBlog";
import Providers from "views/Providers";
import {
  CasinoIcon,
  SportsIcon,
  LiveIcon,
  BonusIcon,
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import {FaHandshake} from 'react-icons/fa';
import VIP_Club from "views/Dashboard/VIP-Club";
import Promotions from "views/Dashboard/Promotions";
import PromotionCasino from "views/Dashboard/Promotions/PromotionCasino";
import PromotionSports from "views/Dashboard/Promotions/PromotionSports";
import Soccer from "views/Soccer";
import Policies from "views/Dashboard/Policies";
import Licenses from "views/Dashboard/Licenses";
import ProvablyFair from "views/Dashboard/ProvablyFair";
import ResponsibleGambling from "views/Dashboard/ResponsibleGambling";
import Challenges from "views/Dashboard/Casino/challenges";

var dashRoutes = [
  {
    path: "/sports/*",
    name: "League",
    rtlName: "",
    icon: <SportsIcon color="inherit" />,
    component: <LeagueTemplate />,
  },
  {
    path: "/sports/events/*",
    name: "Event",
    rtlName: "",
    icon: <SportsIcon color="inherit" />,
    component: <EventTemplate />,
  },
  // {
  //   path: "/Basket/Nord&SudAmerica/USA-WNBA",
  //   name: "USA-WNBA",
  //   rtlName: "",
  //   icon: <SportsIcon color="inherit" />,
  //   component: <USA_WNBA />,
  // },
  // {
  //   path: "/Basket/Nord&SudAmerica/USA-NBA",
  //   name: "USA-NBA",
  //   rtlName: "",
  //   icon: <SportsIcon color="inherit" />,
  //   component: <USA_NBA />,
  // },
  {
    path: "/casino",
    name: "Casino",
    rtlName: "كازينو",
    icon: <CasinoIcon color="inherit" />,
    component: <Casino />,
  },
  {
    path: "/sports",
    name: "Sports",
    rtlName: "",
    icon: <SportsIcon color="inherit" />,
    component: <Sports/>,
  },
  {
    path: "/live",
    name: "Live",
    rtlName: "",
    icon: <LiveIcon color="inherit" />,
    component: <Live/>,
  },
  {
    path: "/bonuses",
    name: "Bonuses",
    rtlName: "",
    icon: <BonusIcon color="inherit" />,
    component: <Sports/>,
  },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color="inherit" />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <Profile />,
  },
  {
    path: "/affiliate",
    name: "Affiliate",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Affiliate/>,
  },
  {
    path: "/blog",
    name: "Blog",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Blog/>,
  },


  //blog path start
  {
    path: "/blog/what-is-3-bet-poker-strategy",
    name: "Strategy",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Strategy/>,
  },
  {
    path: "/blog/crypto",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Crypto/>,
  },
  {
    path: "/blog/howtoguides",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <HowToGuide/>,
  },
  {
    path: "/blog/stakenews",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <StakeNews/>,
  },
  {
    path: "/blog/sports",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <BlogSports/>,
  },
  {
    path: "/blog/casino",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <BlogCasino/>,
  },
  {
    path: "/blog/other",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Other/>,
  },
  {
    path: "/blog/ufc-picks",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <UtfPicks/>,
  },
  {
    path: "/blog/mlb_predictions_and_world_series_picks",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Mlb_Predictions/>,
  },
  {
    path: "/blog/new_games_on_stake",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <New_Game/>,
  },
  {
    path: "/blog/double_chance_betting",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Double_Chance_Betting/>,
  },
  {
    path: "/blog/common_sports_betting",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Common_Sports_Betting/>,
  },
  {
    path: "/blog/new_games_on_casino",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <New_Games_On_Casino/>,
  },
  {
    path: "/blog/champions_league_predictions",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <ChampionsLeaguePredictions/>,
  },
  {
    path: "/blog/europa_league_predictions",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <EuropaLeaguePredictions/>,
  },
  {
    path: "/blog/play_stake_poker_tournaments",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <PlayStakePokerTournaments/>,
  },
  {
    path: "/blog/win_slot_features",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <WhatAreHoldAndWinSlotFeatures/>,
  },
  {
    path: "/blog/corner_betting",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <CornerBetting/>,
  },
  {
    path: "/blog/other_sub_blog",
    name: "Crypto",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <OtherSubBlog/>,
  },
 
  //blog path end 
  {
    path: "/vip-club",
    name: "VIP-Club",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <VIP_Club/>,
  },
  {
    path: "/review",
    name: "Review",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Review/>,
  },
  {
    path: "/promotions",
    name: "Promotions",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Promotions/>,
  },
  {
    path: "/promotions/casino",
    name: "PromotionsCasino",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <PromotionCasino/>,
  },
  {
    path: "/promotions/sports",
    name: "PromotionsSports",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <PromotionSports/>,
  },
  {
    path: "/soccer",
    name: "Soccer",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Soccer/>,
  },
  {
    path: "/policies",
    name: "Policies",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Policies/>,
  },
  {
    path: "/provably-fair",
    name: "ProvablyFair",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <ProvablyFair/>,
  },
  {
    path: "/licenses",
    name: "Licenses",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Licenses/>,
  },
  {
    path: "/responsible-gambling/Overview",
    name: "ResponsibleGambling",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <ResponsibleGambling/>,
  },
  {
    path: "/providers",
    name: "Providers",
    rtlName: "",
    icon: <FaHandshake color="inherit" />,
    component: <Providers/>,
  },
  {
    path: "/challenges",
    name: "Challenges",
    rtlName: "",
    icon: <SportsIcon color="inherit" />,
    component: <Challenges/>,
  },
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: <SignIn />,
    layout: "/auth",
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: <SignUp />,
    layout: "/auth",
  }
];
export default dashRoutes;
