import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: "'Roboto', sans-serif",
      },
      html: {
        fontFamily: "'Roboto', sans-serif",
      },

      //   // Custom scrollbar styles
      //   "::-webkit-scrollbar": {
      //     width: "8px", // Width of the scrollbar
      //   },
      //   "::-webkit-scrollbar-track": {
      //     background: "#f1f1f1", // Background of the scrollbar track
      //   },
      //   "::-webkit-scrollbar-thumb": {
      //     background: "#888", // Color of the scrollbar thumb
      //     borderRadius: "10px", // Rounded corners for the thumb
      //   },
      //   "::-webkit-scrollbar-thumb:hover": {
      //     background: "#555", // Color of the thumb on hover
      //   },

   // Modern custom scrollbar styles
   "::-webkit-scrollbar": {
    width: "2px", // Vertical scrollbar width
    height: "2px", // Horizontal scrollbar height
  },
  "::-webkit-scrollbar-track": {
    background: "#e0e0e0", // Light background for contrast
    borderRadius: "10px", // Rounded corners for the track
  },
  "::-webkit-scrollbar-thumb": {
    background: "rgba(100, 100, 100, 0.7)", // Semi-transparent thumb for a sleek look
    borderRadius: "10px", // Rounded corners for the thumb
    border: "2px solid #f1f1f1", // Adds a border for a floating effect
    transition: "background 0.3s ease", // Smooth transition on hover
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: "rgba(100, 100, 100, 0.9)", // Darker on hover for interactivity
  },
  "::-webkit-scrollbar-thumb:active": {
    background: "rgba(100, 100, 100, 1)", // Solid color when active (dragged)
  },
  "::-webkit-scrollbar-corner": {
    background: "transparent", // Transparent corner for a cleaner look
  },
  
  // Horizontal scrollbar specific styles
  "::-webkit-scrollbar:horizontal": {
    height: "2px", // Adjust height for horizontal scroll
  },
  
  // Mobile-specific scrollbar styles
  "@media screen and (max-width: 600px)": {
    "::-webkit-scrollbar": {
      width: "2px", // Width for mobile devices
    },
    "::-webkit-scrollbar:horizontal": {
      height: "4px", // Smaller height for horizontal scrollbar on mobile devices
    },
  },

    }),
  },
};
