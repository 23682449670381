import {
  CLEAR_BET_ITEMS,
  SET_BET_ITEMS,
  REMOVE_BET_ITEM,
  SET_BETSLIP_COORDS,
  SET_BETSLIP_STATUS,
} from "actions/betslip";

const initialState = {
  betItems: [],
  isBetSlipStatus: false,
  betslipCoords: null,
};

export const betSlipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BET_ITEMS:
      return {
        ...state,
        betItems: action.payload,
      };
    case SET_BETSLIP_STATUS:
      return {
        ...state,
        isBetSlipStatus: action.payload,
      };
    case CLEAR_BET_ITEMS:
      return {
        ...state,
        betItems: [],
      };
    case REMOVE_BET_ITEM:
      return {
        ...state,
        betItems: state.betItems.filter(
          (item) => item.oddId !== action.payload
        ),
      };
    case SET_BETSLIP_COORDS:
      return {
        ...state,
        betslipCoords: action.payload,
      };
    default:
      return state;
  }
};
