import * as actions from '../actions/websocket';
import {
    setSports,
    setCountries,
    setLeagues,
    setEvents,
    setOdds,
} from '../actions/sports';

const socketMiddleware = () => {
  let socket = null;
  let timeout = null;

  const keepAlive = store => () => {
    clearTimeout(timeout);
    timeout = setInterval(() => {
      store.dispatch(actions.wsKeepAlive());
    }, 30000);
  }

  const onOpen = store => (event) => {
    store.dispatch(actions.wsConnected(event.target.url));
    keepAlive(store)();
  };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected());
    clearInterval(timeout);
  };

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    
    if (payload.sports) {
        store.dispatch(setSports(payload.sports));
    }
    else if (payload.countries) {
        store.dispatch(setCountries(payload.sportId, payload.countries));
    }
    else if (payload.leagues) {
        store.dispatch(setLeagues(Object.values(payload.leagues)));
    }
    else if (payload.events) {
        store.dispatch(setSports([payload.sport]));
        store.dispatch(setCountries(payload.sport.sportId.toString(), [payload.country]));
        store.dispatch(setLeagues([{
            ...payload.league,
            sportId: payload.sport.sportId,
            countryId: payload.country.countryId,
        }]));
        store.dispatch(setEvents(payload.events));
    }
    else if (payload.event) {
      store.dispatch(setOdds(payload.event.brId, payload.odds));
      store.dispatch(setEvents([
        {
          ...payload.event,
          eventId: payload.event.brId,
        }
      ]));
    }

    if(!payload.message) {
      keepAlive(store)();
    }
  };

  return store => next => (action) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(action.host);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case 'GET_SPORTS':
        socket.send(JSON.stringify({ command: 'sports' }));
        break;
      case 'GET_COUNTRIES':
        socket.send(JSON.stringify({ 
            command: 'countries', 
            sportId: action.payload.sportId 
        }));
        break;
      case 'GET_LEAGUES':
        socket.send(JSON.stringify({ 
            command: 'leagues', 
            sportId: action.payload.sportId,
            countryId: action.payload.countryId
        }));
        break;
      case 'GET_EVENTS':
        socket.send(JSON.stringify({ 
            command: 'leagueEvents', 
            sportId: action.payload.sportId,
            leagueId: action.payload.leagueId,
        }));
        break;
      case 'GET_EVENT':
        socket.send(JSON.stringify({ 
          command: 'event', 
          eventId: action.payload.eventId,
        }));
        break;
      case 'WS_KEEP_ALIVE':
        socket.send(JSON.stringify({ 
          command: 'ping'
        }));
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();