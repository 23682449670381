import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import logoImage from "assets/img/blog/logo-image.png";
import CasinoImage1 from "assets/img/blog/Casino/image-1.webp";
import CasinoImage2 from "assets/img/blog/Casino/image-2.webp";
import CasinoImage3 from "assets/img/blog/Casino/image-3.webp";
import CasinoImage4 from "assets/img/blog/Casino/image-4.webp";
import CasinoImage5 from "assets/img/blog/Casino/image-5.webp";
import CasinoImage6 from "assets/img/blog/Casino/image-6.webp";
import CasinoImage7 from "assets/img/blog/Casino/image-7.webp";
import { NavLink } from "react-router-dom";


const Casino = () => {
    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        Casino
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%", sm: "50%", md: "50%" }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={CasinoImage1}
                            alt="UFC Picks & Expert UFC Predictions"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>
                    <NavLink to="/blog/what-is-3-bet-poker-strategy">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={CasinoImage2}
                                alt="2024 MLB Predictions & World Series Picks"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                What is the 3-Bet Poker Strategy?
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                A 3-bet in poker is the third wager made in a betting round. read our tips & all there is to know about this elevated strategy at Wager Street Casino!
                            </Text>
                        </Box>
                    </NavLink>
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={CasinoImage3}
                            alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={CasinoImage4}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={CasinoImage5}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>
                    <NavLink to="/blog/play_stake_poker_tournaments">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={CasinoImage6}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to Join & Play Wager Street Poker Tournaments
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Poker tournaments are one of the most exciting ways to play the game, and online tournaments offer the opportunity to compete against poker players from all over the world.
                                In this step-by-step guide, we’ll explain how to join Wager Street Poker tournaments and what to expect at Wager Street Casino.
                            </Text>
                        </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default Casino;
