import React from 'react';
import PropTypes from 'prop-types';
import { Box, useStyleConfig } from "@chakra-ui/react";

function Card(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}

// Define prop types
Card.propTypes = {
  variant: PropTypes.string, // Specify the type of `variant`
  children: PropTypes.node, // Specify the type of `children`
};

export default Card;
