import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Link,
  Icon,
  Select,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import policies from "data/Policies/policies.json";

const Policies = () => {
  const { policyName } = useParams(); // Get the policy name from the URL
  const navigate = useNavigate(); // To navigate between routes
  const [selectedPolicy, setSelectedPolicy] = useState(policyName || "Privacy");

  const bg = useColorModeValue("gray.100", "gray.900");
  const sidebarBg = useColorModeValue("white", "gray.800");
  const tabTextColor = useColorModeValue("gray.800", "white");
  const textColor = useColorModeValue("gray.400", "gray.400");
  const headingColor = useColorModeValue("gray.800", "white");
  const linkHoverColor = useColorModeValue("blue.900", "blue.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const policyLinks = Object.keys(policies);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });

  const formatPolicyNameForUrl = (policy) => policy.replace(/\s+/g, "-");
  const formatPolicyNameForDisplay = (policy) => policy.replace(/-/g, " ");

  const handlePolicyClick = (policy) => {
    const formattedPolicy = formatPolicyNameForUrl(policy);
    setSelectedPolicy(policy);
    navigate(`/policies/${formattedPolicy}`);
  };

  useEffect(() => {
    const formattedPolicyName = formatPolicyNameForDisplay(policyName);
    if (policyName && policies[formattedPolicyName]) {
      setSelectedPolicy(formattedPolicyName);
    }
  }, [policyName]);

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "75px", md: "75px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      color={textColor}
      minH="100vh"
    >
      <Box
        bg={bg}
        pt={{ base: "75px", md: "100px" }}
        px={{ base: 4, md: 8 }}
        pb={10}
      >
        <Flex align="center" mb={8}>
          <Icon as={BsFillShieldLockFill} boxSize={6} mr={2} />
          <Heading size="md" color={headingColor}>
            Policies
          </Heading>
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="flex-start"
          h="full"
        >
          {isMobile ? (
            <Select
              value={selectedPolicy}
              onChange={(e) => handlePolicyClick(e.target.value)}
              mb={4}
              width={{ base: "40%", sm: "100%", md: "40%" }}
            >
              {policyLinks.map((policy) => (
                <option key={policy} value={policy}>
                  {policy}
                </option>
              ))}
            </Select>
          ) : (
            <Box
              w={{ base: "100%", md: "250px" }}
              bg={sidebarBg}
              p={4}
              borderRight="1px solid"
              borderColor={borderColor}
              h="calc(100vh - 100px)" // Sidebar height
              overflowY="auto"
            >
              <Stack spacing={4}>
                {policyLinks.map((policy) => (
                  <Link
                    key={policy}
                    p={2}
                    rounded="md"
                    cursor="pointer"
                    bg={selectedPolicy === policy ? "blue.900" : "transparent"}
                    color={selectedPolicy === policy ? "white" : tabTextColor}
                    _hover={{
                      bg: linkHoverColor,
                      color: "white",
                    }}
                    onClick={() => handlePolicyClick(policy)}
                  >
                    {policy}
                  </Link>
                ))}
              </Stack>
            </Box>
          )}

          <Box
            flex="1"
            p={8}
            bg={useColorModeValue("white", "gray.700")}
            borderRadius="md"
            ml={{ base: 0, md: 8 }}
            boxShadow="md"
            maxH="calc(100vh - 100px)" // Content area height
            overflowY="auto"
          >
            <Heading
              as="h1"
              size="md"
              mb={6}
              color={headingColor}
              style={{
                fontWeight: 600 /* Semi-bold font weight */,
                color: "#fff",
              }}
              dangerouslySetInnerHTML={{
                __html: policies[selectedPolicy].title,
              }}
            />
            <Box
              color="#b1bad3"
              dangerouslySetInnerHTML={{
                __html: policies[selectedPolicy].content,
              }}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Policies;
