import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Badge,
  Icon,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  useBreakpointValue,
  Divider,
  Select,
  Image,
} from "@chakra-ui/react";
import {
  FaStar,
  FaBitcoin,
  FaAngleLeft,
  FaAngleDown,
  FaRocket,
  FaUserAlt,
  FaArrowCircleUp,
  FaCog,
} from "react-icons/fa";
import { LuExternalLink } from "react-icons/lu";
import { SiLitecoin, SiEthereum, SiTether } from "react-icons/si";
import { NavLink } from "react-router-dom";
import evertonLogo from "assets/svg/footer/everton-logo.svg";
import UFCLogo from "assets/svg/footer/ufc-logo.svg";
import regalLogo from "assets/img/WagerStreet-logo.png";
import verifiedImage from "assets/img/footer/verified.png";
import validatedImage from "assets/img/footer/validated.png";

export default function Footer() {
  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const tabBg = useColorModeValue("gray.100", "gray.700");
  const questionsBg = useColorModeValue("gray.200", "gray.800");
  const tabOrientation = useBreakpointValue({
    base: "horizontal",
    md: "vertical",
  });

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "20px", md: "20px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      mr={5}
      color={textColor}
      mb={{ base: 20, md: 10 }}
    >
      <SimpleGrid columns={{ base: 2, sm: 2, md: 6 }} spacing="4" m={4} mb={6}>
        {/* Sports */}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            Sports
          </Text>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize="sm"
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/sports">Sportsbook</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/sports">Live Sports</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/soccer">Soccer</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Basketball</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Tennis</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>eSports</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Bet Bonuses</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Sportsbook">Sports Rules</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Racing-Rules">Racing Rules</NavLink>
          </Box>
        </Box>

        {/* Casino */}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            Casino
          </Text>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/casino">Casino Games</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/casino">Slots</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/casino">Live Casino</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Roulette</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Blackjack</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/providers">Providers</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/vip-club">VIP Club</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/promotions">Promotions</NavLink>
          </Box>
        </Box>

        {/* Support */}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            Support
          </Text>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/provably-fair/Overview">Fairness</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/affiliate">Affiliate</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/responsible-gambling/Overview">
              Responsible Gambling
            </NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Gamble Aware</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Live Support</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Help Center</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/blog/howtoguides">How to Guides</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Local Currency Guide</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Supported Crypto</NavLink>
          </Box>
        </Box>

        {/* Community */}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            Community
          </Text>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/blog">Blog</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Forum</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Facebook</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Twitter</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Instagram</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Youtube</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Shop</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
        </Box>

        {/* About Us */}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            About Us
          </Text>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Privacy">Privacy Policy</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/licenses">Licenses</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Anti-Money-Laundering">AML Policy</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Terms-of-Service">Terms of Service</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink to="/policies/Self-Exclusion">Self Exclusion</NavLink>
          </Box>
          <Box
            color="gray.500"
            _hover={{ color: textColor }}
            fontSize={"sm"}
            fontWeight={"bold"}
            p={1}
          >
            <NavLink>Primedice</NavLink>
            <Icon
              as={LuExternalLink}
              _hover={{ color: textColor }}
              w={5}
              h={5}
              color="gray.600"
              ml="2"
            />
          </Box>
        </Box>

        {/*Language*/}
        <Box>
          <Text p={1} fontSize="md" fontWeight={"bold"} mb={2}>
            Languages
          </Text>
          <Box color={textColor} fontSize={"sm"}>
            <Select border={"none"} fontSize={"sm"}>
              <option value="">English</option>
              <option value="option2">Hindi</option>
            </Select>
          </Box>
          <Box color={textColor} fontSize={"sm"} pl={4}>
            <NavLink>Odds</NavLink>
          </Box>
          <Box color={textColor} fontSize={"sm"}>
            <Select border={"none"} fontSize={"sm"}>
              <option value="">Decimal</option>
              <option value="option2">Fractional</option>
            </Select>
          </Box>
        </Box>
      </SimpleGrid>

      <Divider borderColor="gray.500" mb="4" mt={4} />
      <Box>
        <SimpleGrid
          columns={{ base: 2, sm: 2, md: 3, lg: 6 }}
          spacing="4"
          m={4}
          mb={6}
          justifyContent={"space-around"}
        >
          <Flex>
            <Icon as={SiLitecoin} w={6} h={6} color="gray.600" mr="1" />
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              LiteCoin
            </Text>
          </Flex>
          <Flex>
            <Icon as={FaBitcoin} w={6} h={6} color="gray.600" mr="1" />
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              bitcoin
            </Text>
          </Flex>
          <Flex>
            <Icon as={SiEthereum} w={6} h={6} color="gray.600" mr="1" />
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              ethereum
            </Text>
          </Flex>

          <Flex>
            <Icon as={FaBitcoin} w={6} h={6} color="gray.600" mr="1" />
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              bitcoincash
            </Text>
          </Flex>
          <Flex>
            <Icon as={SiTether} w={6} h={6} color="gray.600" mr="1" />
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              Tether
            </Text>
          </Flex>

          <Flex>
            <Text color={"gray.600"} fontWeight="bold" fontSize={"2xl"}>
              18+
            </Text>
          </Flex>
        </SimpleGrid>
      </Box>
      <Divider borderColor="gray.500" mb="4" mt={4} />
      <Box>
        <SimpleGrid
          columns={{ base: 2, sm: 2, md: 4 }}
          spacing="4"
          m={4}
          mb={6}
          justifyContent={"space-around"}
        >
          <Box>
            <Image
              src={evertonLogo}
              alt="chakra image"
              height={"150px"}
              width={"150px"}
            />
          </Box>
          <Box>
            <Image
              src={UFCLogo}
              alt="chakra image"
              height={"150px"}
              width={"150px"}
            />
          </Box>
        </SimpleGrid>
      </Box>
      <Divider borderColor="gray.600" />
      <VStack spacing={4} textAlign="center" mt={10}>
        <NavLink
          to="/home" // Direct navigation to "/home"
          style={{
            display: "flex",
            lineHeight: "100%",
            marginBottom: "30px",
            fontWeight: "bold",
            alignItems: "center",
            fontSize: "11px",
            textDecoration: "none",
          }}
        >
          <Image src={regalLogo} alt="RegalLogo" width="200px" height="50px" />
        </NavLink>
        <Text fontSize="lg" color={"gray.400"}>
          Wager Street is owned and operated by Medium Rare N.V., registration
          number: 145353, registered address: Korporaalweg 10, Willemstad,
          Curaçao. Contact us at support@wagerstreet.net. Payment agent company is
          Medium Rare Limited with address 7-9 Riga Feraiou, LIZANTIA COURT,
          Office 310, Agioi Omologites, 1087 Nicosia, Cyprus and Registration
          number: HE 410775
        </Text>
        <Text fontSize="lg" color={"gray.400"}>
          Support support@wagerstreet.net | Partners partners@wagerstreet.net | Press
          press@wagerstreet.net
        </Text>
        <Image src={verifiedImage} alt="chakra image" boxSize={10} />
        <Image src={validatedImage} alt="chakra image" boxSize={10} />
      </VStack>
    </Flex>
  );
}
