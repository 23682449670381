import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import logoImage from "assets/img/blog/logo-image.png";
import HowToGuideImage1 from "assets/img/blog/How to Guide/image-1.webp";
import HowToGuideImage2 from "assets/img/blog/How to Guide/image-2.webp";
import HowToGuideImage3 from "assets/img/blog/How to Guide/image-3.webp";
import HowToGuideImage4 from "assets/img/blog/How to Guide/image-4.webp";
import HowToGuideImage5 from "assets/img/blog/How to Guide/image-5.webp";
import HowToGuideImage6 from "assets/img/blog/How to Guide/image-6.webp";
import HowToGuideImage7 from "assets/img/blog/How to Guide/image-7.webp";
import { NavLink } from "react-router-dom";


const HowToGuide = () => {

    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        How to Guides
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%",sm:"50%", md: "50%"  }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                <NavLink to="/blog/champions_league_predictions">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage2}
                            alt="UFC Picks & Expert UFC Predictions"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        Champions League Predictions
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        The return of the UEFA Champions League is nearly upon us, with soccer fans eagerly anticipating the brand new look for the 2024-25 campaign of the most prestigious European competition.
                        </Text>
                    </Box>
                </NavLink>
                <NavLink to="/blog/europa_league_predictions">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage3}
                            alt="2024 MLB Predictions & World Series Picks"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        Europa League Predictions
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        Previously known as the UEFA Cup, the Europa League has been given a huge shake-up. Yes, the 2024/25 season will mark a watershed moment for UEFA and its other European competitions such as the Champions League and the Europa Conference League . 
                        </Text>
                    </Box>
            </NavLink>
            <NavLink to="/blog/play_stake_poker_tournaments">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage4}
                            alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        How to Join & Play Wager Street Poker Tournaments
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        Poker tournaments are one of the most exciting ways to play the game, and online tournaments offer the opportunity to compete against poker players from all over the world.
                        In this step-by-step guide, we’ll explain how to join Wager Street Poker tournaments and what to expect at Wager Street Casino.
                        </Text>
                    </Box>
                    </NavLink>
                    <NavLink to="/blog/win_slot_features">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage5}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        What are Hold & Win Slot Features?
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        The exciting Hold & Spin feature is available on many online slots , and for a good reason! This fun and rewarding mini-game gives you the chance to respin a special game grid that can pay out huge rewards and more.
                        </Text>
                    </Box>
                    </NavLink>
                    <NavLink to="/blog/double_chance_betting">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage6}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        What is Double Chance Betting?
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        Online sportsbooks are always looking to engage with players, and one of the ways they do this is with the markets and the types of bets they advertise
                        </Text>
                    </Box>
                    </NavLink>
                    <NavLink to="/blog/corner_betting">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={HowToGuideImage7}
                            alt="Another Blog Post Title"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                        What is Corner Betting?
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                        As sports go, very few match the excitement and drama of football - or soccer , if you’re that way inclined. The intricate yet simple nature of play and the fluidity with which a game unfolds leads to many different outcomes that fans are deeply invested in. That’s what makes it such a draw for sports bettors too. Goals, shots, throws, cards, and corners are big markets for those looking to add an extra dimension to the game with a wager.
                        </Text>
                    </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default HowToGuide;
