const PanelContainer = {
  baseStyle: {
    // p: { base: "0px", md: "30px 15px" }, 
    // pt: { base: "30px", md: "0px" },// No padding on mobile, padding on larger screens
    minHeight: "calc(100vh - 123px)",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
