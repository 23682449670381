import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";
import { NavLink, useLocation } from 'react-router-dom';
import logoImage from "assets/img/blog/logo-image.png";
import ufcPicksBlogImage from "assets/img/blog/ufc-picks-blog image.webp";
import mlbPredictionBlogImage from "assets/img/blog/2024-mlb-blog-image.webp";
import newGamesOneBlogImage from "assets/img/blog/new-games-1-blog-image.webp";
import whatIsDoubleBlogImage from "assets/img/blog/what-is-double-blog-image.webp";
import commonSportsBlogImage from "assets/img/blog/common-sports-blog-image.webp";
import newGamesTwoBlogImage from "assets/img/blog/new-games-2-blog-image.webp";


const Crypto = () => {
    const location = useLocation();
    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        Crypto
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%", sm: "50%", md: "50%" }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <NavLink to="/blog/ufc-picks">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={ufcPicksBlogImage}
                                alt="UFC Picks & Expert UFC Predictions"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/mlb_predictions_and_world_series_picks">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={mlbPredictionBlogImage}
                                alt="2024 MLB Predictions & World Series Picks"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/new_games_on_stake">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={newGamesOneBlogImage}
                                alt="New Games on Wager Street: August 30th 2024 Casino Releases & Sports Promos"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/double_chance_betting">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={whatIsDoubleBlogImage}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/common_sports_betting">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={commonSportsBlogImage}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/new_games_on_casino">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={newGamesTwoBlogImage}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                How to play Dise at Stack
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default Crypto;
