import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  Stack,
  Grid,
  Icon,
  Button,
  HStack,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { FaBitcoin, FaDollarSign } from "react-icons/fa";
import { NoEventsIcon } from "components/Icons/Icons";
import { FaAngleLeft, FaAngleDown, FaYoutube } from "react-icons/fa";
import { OddTabIcon } from "components/Icons/Icons";
import { useLocation, NavLink } from "react-router-dom";

import { getEvents } from "../../actions/sports";
import Betslip from "views/BetslipTemplate";

import { clearBetItems, setBetItems, setBetSlipStatus } from "actions/betslip";

const LeagueTemplate = ({
  dispatch,
  sports,
  countries,
  leagues,
  events,
  wsConnected,
  betItems,
  isBetSlipStatus,
}) => {
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  const isBetSlipVisible = useBreakpointValue({ base: false, md: true });

  const textColor = useColorModeValue("gray.700", "white");
  const activeTextColor = useColorModeValue("gray.700", "gray.700");
  const secondaryTextColor = useColorModeValue("gray.400", "gray.400");
  const collapsableBg = useColorModeValue("gray.100", "gray.700");
  const bg = useColorModeValue("gray.100", "gray.800");
  const buttonBg = useColorModeValue("gray.200", "gray.900");
  const activeButtonBg = useColorModeValue("gray.200", "blue.500");
  const tabBgColor = useColorModeValue("gray.200", "gray.900");
  const oddColor = useColorModeValue("blue.400", "blue.400");
  const activeOddColor = useColorModeValue("white", "white");
  const tabBg = useColorModeValue("gray.100", "gray.900");
  const tabHoverBg = useColorModeValue("gray.200", "gray.700");
  const selectedTabBg = useColorModeValue("gray.300", "gray.700");
  const tabColor = useColorModeValue("black", "white");

  const [allBets, setAllBets] = useState([]);
  const [highRollers, setHighRollers] = useState([]);
  const [raceLeaderboard, setRaceLeaderboard] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [eventCount, setEventsCount] = useState(0);

  const textTruncated = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
    xl: false,
  });
  const maxWidthValue = useBreakpointValue({
    base: "50px",
    md: "100%",
    lg: "100%",
    xl: "100%",
  });

  const location = useLocation();
  console.log("Location:", location);
  const { sportId, leagueId } = location.state || {};

  console.log("Sport ID:", sportId);
  console.log("League ID:", leagueId);

  console.log(betItems);

  let sport = {};
  let country = {};
  let league = {};

  if (sports) {
    sport = sports[sportId];
  }

  if (leagues[leagueId]) {
    country = countries[leagues[leagueId].countryId];
    league = leagues[leagueId];
  }

  const formatStringForURL = (str) => {
    return str.replace(/\s+/g, ""); // Remove all white spaces
  };

  useEffect(() => {
    if (sportId && leagueId && wsConnected) {
      dispatch(getEvents(sportId, leagueId));
    }
  }, [sportId, leagueId, wsConnected]);

  useEffect(() => {
    if (
      events &&
      Object.entries(events).filter(
        ([, event]) => event.leagueId.toString() === leagueId
      ).length
    ) {
      setEventsCount(
        Object.entries(events).filter(
          ([, event]) => event.leagueId.toString() === leagueId
        ).length
      );
    }
  }, [events]); // Only logs when events changes

  console.log(events);

  const selectBetSlip = (marketId, marketName, oddId, oddData) => {
    const selectedBet = {
      marketId,
      marketName,
      oddId,
      oddName: oddData.oddName,
      moneyLine: oddData.moneyLine,
    };

    if (!isBetSlipStatus) {
      dispatch(setBetSlipStatus(true));
      console.log(isBetSlipStatus);
    }

    const index = betItems.findIndex((item) => item.oddId === oddId);
    let newBetItems = [...betItems];

    if (index === -1) {
      newBetItems.push(selectedBet);
    } else {
      newBetItems.splice(index, 1);
    }
    dispatch(setBetItems(newBetItems));
    // setBetItems(newBetItems); // Update the state with the new array
    // dispatch(addBetItems(newBetItems));
  };

  const EventCard = ({ eventData }) => {
    let xData = "-";
    let xDataOdd = "";
    let xDataOddId = "";
    let yData = "-";
    let yDataOdd = "";
    let yDataOddId = "";
    let draw = "-";
    let drawDataOdd = "";
    let drawDataOddId = "";
    let mainOddName = "-";
    let selectedMarket = null;

    if (
      eventData?.oddsMarkets &&
      Object.keys(eventData.oddsMarkets).length > 0
    ) {
      // Check if marketId 805 is available
      const market805 = Object.values(eventData.oddsMarkets).find(
        (marketValue) => marketValue.marketId == 805
      );

      if (market805) {
        mainOddName = market805.marketName;
        selectedMarket = market805;
        Object.values(market805.odds).map(([oddId, odds]) => {
          if (odds.oddName == "1 HH") {
            xData = odds.moneyLine;
            xDataOdd = odds;
            xDataOddId = odds.id.toString();
          } else if (odds.oddName == "2 HH") {
            yData = odds.moneyLine;
            yDataOdd = odds;
            yDataOddId = odds.id.toString();
          } else {
            draw = odds.moneyLine;
            drawDataOdd = odds;
            drawDataOddId = odds.id.toString();
          }
        });
      } else {
        // Check if marketId 1 is available
        const market1 = Object.values(eventData.oddsMarkets).find(
          (marketValue) => marketValue.marketId == 1
        );

        if (market1) {
          mainOddName = market1.marketName;
          selectedMarket = market1;
          Object.values(market1.odds).map((odds) => {
            if (odds.oddName == "1") {
              xData = odds.moneyLine;
              xDataOdd = odds;
              xDataOddId = odds.id.toString();
            } else if (odds.oddName == "2") {
              yData = odds.moneyLine;
              yDataOdd = odds;
              yDataOddId = odds.id.toString();
            } else {
              draw = odds.moneyLine;
              drawDataOdd = odds;
              drawDataOddId = odds.id.toString();
            }
          });
        } else {
          // If neither marketId 805 nor 1 is found, take the first available market
          const firstMarket = Object.values(eventData.oddsMarkets)[0];
          if (firstMarket) {
            mainOddName = firstMarket.marketName;
            selectedMarket = firstMarket;
            const oddsArray = Object.values(firstMarket.odds);
            if (oddsArray.length > 0)
              (xData = oddsArray[0].moneyLine),
                (xDataOdd = oddsArray[0]),
                (xDataOddId = oddsArray[0].id.toString());
            if (oddsArray.length > 1)
              (yData = oddsArray[1].moneyLine),
                (yDataOdd = oddsArray[1]),
                (yDataOddId = oddsArray[1].id.toString());
            if (oddsArray.length > 2)
              (draw = oddsArray[2].moneyLine),
                (drawDataOdd = oddsArray[2]),
                (drawDataOddId = oddsArray[2].id.toString());
          }
        }
      }
    }

    console.log(xDataOdd);
    console.log(yDataOdd);
    console.log(drawDataOdd);
    console.log(selectedMarket);
    const handleBetClick = (marketId, marketName, oddId, odd) => {
      console.log(marketId);
      console.log(marketName);
      console.log(oddId);
      console.log(odd);
      selectBetSlip(marketId, marketName, oddId, odd);
    };

    const date = new Date(eventData.startTime);
    const formattedDateTime = date.toUTCString();

    const teams = eventData?.teams?.split(" - ").map((team) => team.trim());
    const team1 = teams[0];
    const team2 = teams[1];

    return (
      <div style={{ cursor: "pointer" }}>
        {" "}
        {/* Remove onClick from this div */}
        <Box pl={4} pr={4} rounded="md" shadow="md" color="white">
          {/* Separator (Divider) with odd name in big screen and normal divider in mobile view */}
          {!isMobile ? (
            <Stack direction="row" align="center" spacing={4} my={4}>
              <Divider borderColor="gray.600" flex="2" />{" "}
              {/* Left divider with more space */}
              <Text fontSize="sm" color="gray.400">
                {mainOddName}
              </Text>
              <Divider borderColor="gray.600" flex="1" />{" "}
              {/* Right divider with less space */}
            </Stack>
          ) : (
            <></>
          )}

          <Grid
            templateColumns={{ base: "1fr", md: "1fr 2fr" }} // Single column on smaller screens
            gap={4}
            alignItems="center"
          >
            {/* Team details */}
            <Box
              onClick={() => {
                navigate(`events/${formatStringForURL(eventData.teams)}`, {
                  state: {
                    countryId: league.countryId.toString(),
                    eventId: eventData.brId.toString(), // Ensure it's a string
                    leagueId: leagueId.toString(),
                    sportId: sportId.toString(),
                  },
                });
              }}
            >
              <Stack spacing={2} align="flex-start">
                <HStack spacing={2} align="center">
                  <Text fontSize="sm" color="gray.400">
                    {formattedDateTime}
                  </Text>
                </HStack>

                <Stack spacing={1} align="flex-start">
                  <Text color={textColor} fontWeight="bold">
                    {eventData.teams}
                  </Text>
                </Stack>
              </Stack>
            </Box>

            {/* Buttons and Icons */}
            <Stack
              direction={{ base: "row", md: "row" }}
              align="center"
              justify={"flex-end"}
              w="100%"
            >
              {/* Hide icons on smaller screens */}
              <Stack
                spacing={2}
                align="flex-start"
                display={{ base: "none", md: "none", lg: "flex" }}
              >
                <Icon color={secondaryTextColor} as={OddTabIcon} w={4} h={4} />
                {/* <Icon color={secondaryTextColor} as={FaYoutube} w={4} h={4} /> */}
              </Stack>

              <Button
                bg={
                  betItems.some((item) => item.oddId === xDataOddId)
                    ? activeButtonBg
                    : buttonBg
                }
                _hover={{ bg: "gray.600" }}
                height={"60px"}
                width={{ base: "90%", md: "50%" }} // Full width on smaller screens
                borderRadius={"lg"}
                onClick={() =>
                  handleBetClick(
                    selectedMarket.marketId,
                    selectedMarket.marketName,
                    xDataOddId,
                    xDataOdd
                  )
                }
              >
                <Stack spacing={2} align="flex-center">
                  <Text
                    isTruncated={textTruncated}
                    maxWidth={maxWidthValue}
                    fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                    color={
                      betItems.some((item) => item.oddId === xDataOddId)
                        ? activeTextColor
                        : textColor
                    }
                  >
                    {team1}
                  </Text>
                  <Text
                    fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                    color={
                      betItems.some((item) => item.oddId === xDataOddId)
                        ? activeOddColor
                        : oddColor
                    }
                  >
                    {xData}
                  </Text>
                </Stack>
              </Button>

              {/* Conditional Rendering: Show 3 buttons if sportId is "342" */}
              {sportId === "342" ? (
                <>
                  <Button
                    bg={
                      betItems.some((item) => item.oddId === drawDataOddId)
                        ? activeButtonBg
                        : buttonBg
                    }
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={{ base: "90%", md: "50%" }} // Full width on smaller screens
                    borderRadius={"lg"}
                    onClick={() =>
                      handleBetClick(
                        selectedMarket.marketId,
                        selectedMarket.marketName,
                        drawDataOddId,
                        drawDataOdd
                      )
                    }
                  >
                    <Stack spacing={2} align="flex-center">
                      <Text
                        isTruncated={textTruncated}
                        maxWidth={maxWidthValue}
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === drawDataOddId)
                            ? activeTextColor
                            : textColor
                        }
                      >
                        Draw
                      </Text>
                      <Text
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === drawDataOddId)
                            ? activeOddColor
                            : oddColor
                        }
                      >
                        {draw}
                      </Text>
                    </Stack>
                  </Button>

                  <Button
                    bg={
                      betItems.some((item) => item.oddId === yDataOddId)
                        ? activeButtonBg
                        : buttonBg
                    }
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={{ base: "90%", md: "50%" }} // Full width on smaller screens
                    borderRadius={"lg"}
                    onClick={() =>
                      handleBetClick(
                        selectedMarket.marketId,
                        selectedMarket.marketName,
                        yDataOddId,
                        yDataOdd
                      )
                    }
                  >
                    <Stack spacing={2} align="flex-center">
                      <Text
                        isTruncated={textTruncated}
                        maxWidth={maxWidthValue}
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === yDataOddId)
                            ? activeTextColor
                            : textColor
                        }
                      >
                        {team2}
                      </Text>
                      <Text
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === yDataOddId)
                            ? activeOddColor
                            : oddColor
                        }
                      >
                        {yData}
                      </Text>
                    </Stack>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    bg={
                      betItems.some((item) => item.oddId === yDataOddId)
                        ? activeButtonBg
                        : buttonBg
                    }
                    _hover={{ bg: "gray.600" }}
                    height={"60px"}
                    width={{ base: "90%", md: "50%" }} // Full width on smaller screens
                    borderRadius={"lg"}
                    onClick={() =>
                      handleBetClick(
                        selectedMarket.marketId,
                        selectedMarket.marketName,
                        yDataOddId,
                        yDataOdd
                      )
                    }
                  >
                    <Stack spacing={2} align="flex-center">
                      <Text
                        isTruncated={textTruncated}
                        maxWidth={maxWidthValue}
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === yDataOddId)
                            ? activeTextColor
                            : textColor
                        }
                      >
                        {team2}
                      </Text>
                      <Text
                        fontSize={{ base: "xs", md: "sm", sm: "11px" }} // Extra small on mobile
                        color={
                          betItems.some((item) => item.oddId === yDataOddId)
                            ? activeOddColor
                            : oddColor
                        }
                      >
                        {yData}
                      </Text>
                    </Stack>
                  </Button>
                </>
              )}
            </Stack>

            {/* Hide "+14" text on smaller screens */}
            <Stack
              direction="row"
              align="center"
              display={{ base: "none", md: "none" }}
            >
              <Text>+14</Text>
            </Stack>
          </Grid>
          {isMobile ? <Divider borderColor="gray.600" my={4} /> : <></>}
        </Box>
      </div>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "60px", md: "75px" }} bg={bg}>
      <Box p={6} minH="100vh">
        <style>
          {`
          .table-wrapper {
              overflow-x: auto;
             -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          }

          @media (max-width: 600px) {
            .hide-mobile {
              display: none;
            }
          }
        `}
        </style>
        {/* Breadcrumb Navigation */}
        <Flex alignItems="center" mb={4}>
          <IconButton
            icon={<ChevronLeftIcon />}
            variant="ghost"
            aria-label="Back"
            bg={tabBgColor}
            mr={2}
            onClick={() => navigate(-1)} /* This will go to the previous page */
          />
          <Breadcrumb separator=">" bg={tabBgColor} p={2} pl={3} pr={3}>
            <BreadcrumbItem display={{ base: "none", md: "flex", lg: "flex" }}>
              <Text>{sport?.name}</Text>
            </BreadcrumbItem>
            <BreadcrumbItem display={{ base: "none", md: "flex", lg: "flex" }}>
              <Text>{country?.name}</Text>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Text>{league?.name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>

        <Flex flexDirection={{ base: "column", md: "column", lg: "row" }}>
          {/* Tabs */}
          <Box flex="2" mr={{ base: 0, md: 4 }} mb={{ base: 4, md: 0 }}>
            <Tabs
              variant="soft-rounded"
              colorScheme="gray"
              sx={{
                "& .chakra-tabs__tab": {
                  backgroundColor: tabBg,
                  color: tabColor,
                },
                "& .chakra-tabs__tab:hover": {
                  backgroundColor: tabHoverBg,
                },
                '& .chakra-tabs__tab[aria-selected="true"]': {
                  backgroundColor: selectedTabBg,
                  color: tabColor,
                },
              }}
            >
              <TabList
                overflowX="auto" // Enable horizontal scrolling on smaller screens
                whiteSpace="nowrap" // Prevent tabs from wrapping
              >
                <Tab
                  fontSize={{ base: "sm", md: "md" }}
                  // bg={tabBgColor}
                  m={1}
                  mb={3}
                >
                  Live & Upcoming
                </Tab>
                <Tab
                  fontSize={{ base: "sm", md: "md" }}
                  bg={tabBgColor}
                  m={1}
                  mb={3}
                >
                  Outrights
                </Tab>
                <Tab
                  fontSize={{ base: "sm", md: "md" }}
                  bg={tabBgColor}
                  m={1}
                  mb={3}
                >
                  All
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0} pt={4}>
                  <Box
                    bg={collapsableBg}
                    alignItems="center"
                    borderRadius="md"
                    cursor="pointer"
                    pb={4}
                  >
                    <Flex
                      p={4}
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <Box flex="1">
                        <Text
                          color="gray.300"
                          fontSize={{ base: "sm", md: "md", lg: "md" }} // Responsive font size
                        >
                          {league?.name}
                        </Text>
                      </Box>
                      <Box>
                        {/* Only show event count when isOpen is false */}
                        <Flex alignItems="center">
                          {" "}
                          {/* Ensures event count and icon are in the same row */}
                          {!isOpen && (
                            <Button
                              colorScheme={"gray"}
                              borderRadius={"100%"}
                              size={{ base: "xs", md: "sm" }} // Smaller button size for mobile
                              mr="2" // Adds some space between the button and the icon
                              display={{ base: "flex" }}
                            >
                              {eventCount}
                            </Button>
                          )}
                          <Icon
                            as={isOpen ? FaAngleDown : FaAngleLeft}
                            w={{ base: 4, md: 6 }} // Smaller icon width on mobile
                            h={{ base: 4, md: 6 }} // Smaller icon height on mobile
                          />
                        </Flex>
                      </Box>
                    </Flex>

                    {isOpen &&
                      (Object.entries(events).filter(
                        ([, event]) => event.leagueId.toString() === leagueId
                      ).length > 0 ? (
                        Object.entries(events)
                          .filter(
                            ([, event]) =>
                              event.leagueId.toString() === leagueId
                          )
                          .map(([eventId, event]) => (
                            <EventCard eventData={event} key={eventId} />
                          ))
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <NoEventsIcon boxSize="80px" color="gray.400" />
                          <Text color={secondaryTextColor} mt={4} mb={4}>
                            No results found
                          </Text>
                        </Box>
                      ))}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
        <Tabs
          variant="soft-rounded"
          colorScheme="gray"
          sx={{
            "& .chakra-tabs__tab": {
              backgroundColor: tabBg,
              color: tabColor,
            },
            "& .chakra-tabs__tab:hover": {
              backgroundColor: tabHoverBg,
            },
            '& .chakra-tabs__tab[aria-selected="true"]': {
              backgroundColor: selectedTabBg,
              color: tabColor,
            },
          }}
          pt={4}
          mb={12}
        >
          <TabList
            overflowX="auto" // Enable horizontal scrolling on smaller screens
            whiteSpace="nowrap" // Prevent tabs from wrapping
            pb={4}
          >
            <Tab>All Bets</Tab>
            <Tab>High Rollers</Tab>
            <Tab>Race Leaderboard</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* My Bets content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Game</Th>
                      <Th className="hide-mobile">User</Th>
                      <Th className="hide-mobile">Time</Th>
                      <Th className="hide-mobile">Bet Amount</Th>
                      <Th className="hide-mobile">Multiplier</Th>
                      <Th>Payout</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allBets.map((bet, index) => (
                      <Tr key={index}>
                        <Td>{bet.game}</Td>
                        <Td className="hide-mobile">{bet.user}</Td>
                        <Td className="hide-mobile">{bet.time}</Td>
                        <Td className="hide-mobile">
                          <Flex align="center">
                            {bet.betAmount}
                            <FaBitcoin
                              color="orange"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td className="hide-mobile">{bet.multiplier}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.payout}
                            <FaBitcoin
                              color="orange"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* High Rollers content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Game</Th>
                      <Th>User</Th>
                      <Th>Time</Th>
                      <Th>Bet Amount</Th>
                      <Th>Multiplier</Th>
                      <Th>Payout</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {highRollers.map((bet, index) => (
                      <Tr key={index}>
                        <Td>{bet.event}</Td>
                        <Td>{bet.user}</Td>
                        <Td>{bet.time}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.betAmount}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td>{bet.odds}</Td>
                        <Td>
                          <Flex align="center">
                            {bet.betAmount}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>

            <TabPanel>
              {/* Race Leaderboard content */}
              <div className="table-wrapper">
                <Table variant="striped" color={secondaryTextColor} size="sm">
                  <Thead>
                    <Tr>
                      <Th>Rank</Th>
                      <Th>User</Th>
                      <Th>Wagered</Th>
                      <Th>Prize</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {raceLeaderboard.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.rank}</Td>
                        <Td>{entry.user}</Td>
                        <Td>
                          <Flex align="center">
                            {entry.wagered}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                        <Td>
                          <Flex align="center">
                            {entry.prize}
                            <FaDollarSign
                              color="green"
                              style={{ marginLeft: "5px" }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

const s2p = (state) => ({
  sports: state.sports.sports,
  countries: state.sports.countries,
  leagues: state.sports.leagues,
  events: state.sports.events,
  wsConnected: state.websocket?.connected || false,
  betItems: state.betSlip.betItems,
  isBetSlipStatus: state.betSlip.isBetSlipStatus,
});

export default connect(s2p)(LeagueTemplate);
