import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

const MatchDetailsChart = ({
  height,
  categories,
  series,
  stackType,
  tooltipFormatter,
  legendPosition,
}) => {
  const chartOptions = {
    chart: {
      type: "bar",
      height,
      stacked: true,
      stackType: stackType || "100%", // Default to '100%' if not provided
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: legendPosition || "top",
      horizontalAlign: "center",
    },
    grid: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: tooltipFormatter || ((val) => `${val}`), // Default formatter if none provided
      },
    },
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={series}
      type="bar"
      height={height}
    />
  );
};

// Define prop types
MatchDetailsChart.propTypes = {
  height: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  stackType: PropTypes.string,
  tooltipFormatter: PropTypes.func,
  legendPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

export default MatchDetailsChart;
