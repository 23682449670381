import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
  CloseButton,
  IconButton,
  useColorModeValue,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaBitcoin } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import {
  clearBetItems,
  removeBetItem,
  setBetSlipStatus,
} from "actions/betslip";
import {
  BetslipIcon,
  SingleBetIcon,
  MultiBetIcon,
  EmptyBetSlipIcon,
} from "components/Icons/Icons";
import 'mystyle.css';

const Betslip = ({ betItems }) => {
  const IconColor = useColorModeValue("gray.400", "gray.400");
  const tabBg = useColorModeValue("gray.100", "gray.700");
  const tabBgColor = useColorModeValue("gray.200", "gray.900");
  const tabHoverBg = useColorModeValue("gray.200", "gray.700");
  const selectedTabBg = useColorModeValue("gray.300", "gray.600");
  const tabColor = useColorModeValue("black", "white");

  //const boxHeight = useBreakpointValue({ base: "250px", md: "420px" }); // Responsive height

  const dispatch = useDispatch();
  console.log(betItems);
  const [betAmounts, setBetAmounts] = useState({}); // Track bet amounts by bet ID or index

  // Ref for the scrollable container
  const scrollableBoxRef = useRef(null);

  // Auto-scroll to bottom when new bet item is added
  useEffect(() => {
    if (scrollableBoxRef.current) {
      scrollableBoxRef.current.scrollTop =
        scrollableBoxRef.current.scrollHeight;
    }
  }, [betItems]);

  // Update bet amount for a specific bet item
  const handleBetAmountChange = (index, value) => {
    setBetAmounts((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const clearAllBetItems = () => {
    dispatch(clearBetItems());
  };

  return (
    <Box
      w={{
        base: "400px",
        sm: "100%",
        msm: "100%",
        md: "100%",
        lg: "40%",
        xl: "30%",
      }}
      bg="gray.800"
      color="white"
      borderRadius="md"
      boxShadow="lg"
      h="100vh" // Ensure it takes the full viewport height

    >
      {/* Header */}
      <Flex
        justify="space-between"
        align="center"
        p={2}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.4)"
      >
        <Flex align="center">
          <IconButton
            aria-label="Betslip"
            icon={<BetslipIcon size="20px" color={IconColor} />}
            variant="ghost"
          />
          <Select border="none">
            <option value="">Bet Slip</option>
            <option value="option2">My Bets</option>
          </Select>
          <Button
            bg="blue.500"
            _hover={{ bg: "blue.500" }}
            borderRadius="100%"
            size="xs"
            ml={1}
          >
            {betItems.length}
          </Button>
        </Flex>
        <Box>
          <CloseButton
            color="gray.400"
            onClick={() => dispatch(setBetSlipStatus(false))}
            display={{ sm: "none", md: "flex" }}
          />
        </Box>
      </Flex>

      {/* Bet Type Selection */}
      <Tabs
        variant="soft-rounded"
        colorScheme="gray"
        sx={{
          "& .chakra-tabs__tab": {
            backgroundColor: tabBg,
            color: tabColor,
          },
          "& .chakra-tabs__tab:hover": {
            backgroundColor: tabHoverBg,
          },
          '& .chakra-tabs__tab[aria-selected="true"]': {
            backgroundColor: selectedTabBg,
            color: tabColor,
          },
        }}
      >
        <TabList p={4}>
          <Tab>
            <Icon as={SingleBetIcon} boxSize={4} mr={2} />
            Single
          </Tab>
          <Tab>
            <Icon as={MultiBetIcon} boxSize={4} mr={2} />
            Multi
          </Tab>
        </TabList>
        {/* Divider */}
        <Divider borderColor="gray.600" />

        <TabPanels>
          <TabPanel>
            {/* Odds Change Acceptance */}
            <Flex justify="space-between" align="center" mb="4">
              <Text fontSize="sm" color="gray.400" mr={2}>
                No Odds Changes Accepted
              </Text>
              <Text
                fontSize="sm"
                cursor="pointer"
                onClick={clearAllBetItems}
                _hover={{ textDecoration: "none" }} // Optional hover effect
              >
                Clear All
              </Text>
            </Flex>

            <Box className="betSlipBox" overflowY="auto" ref={scrollableBoxRef}>
              {/* Conditional Rendering when there are no bet items */}
              {betItems.length === 0 ? (
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  height="100%"
                >
                  <Icon
                    as={EmptyBetSlipIcon}
                    boxSize={12}
                    color="gray.500"
                    mb={4}
                  />
                  <Text fontSize="sm" color="gray.400">
                    Bet Slip is Empty
                  </Text>
                  <Text fontSize="sm" color="white" fontWeight="bold">
                    Start Betting Now!
                  </Text>
                </Flex>
              ) : (
                betItems.map((betitem, index) => (
                  <Box
                    key={index}
                    bg="gray.700"
                    p="2"
                    borderRadius="md"
                    maxW="sm"
                    boxShadow="lg"
                    mb={4}
                  >
                    <Box display="flex" justifyContent="space-between" mb="1">
                      <Box display="flex" alignItems="center">
                        <Text
                          fontSize="xs"
                          color="red.500"
                          fontWeight="bold"
                          mr="2"
                        >
                          Live
                        </Text>
                        <Text fontSize="sm">{betitem.teams}</Text>
                      </Box>
                      <CloseButton
                        color="gray.400"
                        onClick={() => dispatch(removeBetItem(betitem.oddId))}
                      />
                    </Box>

                    <Flex justify="space-between" align="center">
                      <Text fontSize="xs" color="gray.400" mb="1">
                        {betitem.marketName}
                      </Text>
                    </Flex>

                    <Flex justify="space-between" align="center">
                      <Text fontSize="sm" color="white">
                        {betitem.oddName}
                      </Text>
                      <Text fontWeight="bold" color="blue.400">
                        {betitem.moneyLine}
                      </Text>
                    </Flex>

                    <Box p="3" borderRadius="md">
                      <Flex align="center" mb="1">
                        <Input
                          placeholder="0.00000000"
                          size="sm"
                          bg="gray.900"
                          border="none"
                          color="gray.100"
                          borderRadius="md"
                          mr="2"
                          value={betAmounts[index] || ""}
                          onChange={(e) =>
                            handleBetAmountChange(index, e.target.value)
                          }
                        />
                        <Select
                          size="sm"
                          bg="gray.900"
                          border="none"
                          color="gray.100"
                          borderRadius="md"
                          mr="2"
                          maxW="24"
                        >
                          <option>BTC</option>
                          <option>ETH</option>
                          <option>USD</option>
                        </Select>
                        <FaBitcoin color="orange" />
                      </Flex>
                      <Flex justify="space-between" align="center">
                        <Text fontSize="xs" color="gray.400">
                          Est. Payout
                        </Text>
                        <Flex align="center">
                          <Text
                            fontSize="sm"
                            fontWeight="bold"
                            color="gray.100"
                            mr="2"
                          >
                            {parseFloat(betAmounts[index] || 0) *
                              parseFloat(betitem.odds || 0)}
                          </Text>
                          <FaBitcoin color="orange" />
                        </Flex>
                      </Flex>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
            <Box className="divBottom" boxShadow="md" bg="gray.700">
              {/* Bet Amount Summary */}
              <Flex justifyContent="space-between" p={2}>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    Total Odds
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm">1.75</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" p={2}>
                <Box>
                  <Text fontSize="sm" color="gray.400">
                    Est. Payout
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="sm">0.00000000</Text>
                </Box>
              </Flex>

              {/* Register Button */}
              <Button bg="blue.500" _hover={{ bg: "blue.600" }} w="full">
                Register to Bet
              </Button>
            </Box>
          </TabPanel>

          <TabPanel>{/* Multi Bet Logic */}</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const s2p = (state) => ({
  betItems: state.betSlip.betItems,
});

export default connect(s2p)(Betslip);
