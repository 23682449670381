import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Link,
  Icon,
  Select,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import provablyFairData from "data/ProvablyFair/provablyFair.json"; // JSON data for Provably-Fair

const ProvablyFair = () => {
  const { sectionName } = useParams(); // Get the section name from the URL
  const navigate = useNavigate(); // To navigate between routes
  const [selectedSection, setSelectedSection] = useState(sectionName || "Introduction");

  const bg = useColorModeValue("gray.100", "gray.900");
  const sidebarBg = useColorModeValue("white", "gray.800");
  const tabTextColor = useColorModeValue("gray.800", "white");
  const textColor = useColorModeValue("gray.400", "gray.400");
  const headingColor = useColorModeValue("gray.800", "white");
  const linkHoverColor = useColorModeValue("blue.900", "blue.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const sectionLinks = Object.keys(provablyFairData);

  // Decide whether to show the sidebar (desktop) or dropdown (mobile) based on screen size
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false });

  // Function to convert spaces to hyphens for URL
  const formatSectionNameForUrl = (section) => section.replace(/\s+/g, "-");

  // Function to convert hyphens to spaces for displaying content
  const formatSectionNameForDisplay = (section) => section.replace(/-/g, " ");

  // Navigate when a section is selected
  const handleSectionClick = (section) => {
    const formattedSection = formatSectionNameForUrl(section); // Convert spaces to hyphens
    setSelectedSection(section);
    navigate(`/provably-fair/${formattedSection}`); // Update URL to /provably-fair/{sectionName}
  };

  // Update selected section if the URL changes
  useEffect(() => {
    const formattedSectionName = formatSectionNameForDisplay(sectionName); // Convert hyphens to spaces
    if (sectionName && provablyFairData[formattedSectionName]) {
      setSelectedSection(formattedSectionName);
    }
  }, [sectionName]);

  return (
    <Box
      bg={bg}
      minH="100vh"
      pt={{ base: "75px", md: "100px" }}
      px={{ base: 4, md: 8 }}
      pb={10}
    >
      {/* Heading with Lock Icon */}
      <Flex align="center" mb={8}>
        <Icon as={BsFillShieldLockFill} boxSize={6} mr={2} />
        <Heading size="md" color={headingColor}>
          Provably Fair
        </Heading>
      </Flex>

      {/* Main Flex Container for Sidebar and Content */}
      <Flex direction={{ base: "column", md: "row" }}>
        {/* Sidebar or Dropdown based on screen size */}
        {isMobile ? (
          <Select
            value={selectedSection}
            onChange={(e) => handleSectionClick(e.target.value)}
            mb={4} // Margin for mobile layout
          >
            {sectionLinks.map((section) => (
              <option key={section} value={section}>
                {section}
              </option>
            ))}
          </Select>
        ) : (
          <Box
            w={{ base: "100%", md: "250px" }}
            bg={sidebarBg}
            p={4}
            borderRight="1px solid"
            borderColor={borderColor}
          >
            <Stack spacing={4}>
              {sectionLinks.map((section) => (
                <Link
                  key={section}
                  p={2}
                  rounded="md"
                  cursor="pointer"
                  bg={selectedSection === section ? "blue.900" : "transparent"}
                  color={selectedSection === section ? "white" : tabTextColor}
                  _hover={{
                    bg: linkHoverColor,
                    color: "white",
                  }}
                  onClick={() => handleSectionClick(section)}
                >
                  {section}
                </Link>
              ))}
            </Stack>
          </Box>
        )}

        {/* Content Area */}
        <Box
          flex="1"
          p={8}
          bg={useColorModeValue("white", "gray.700")}
          borderRadius="md"
          ml={{ base: 0, md: 8 }}
          boxShadow="md"
        >
          <Heading as="h1" size="md" mb={6} color={headingColor}>
            {provablyFairData[selectedSection].title}
          </Heading>
          <Text color={textColor}>{provablyFairData[selectedSection].content}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ProvablyFair;
