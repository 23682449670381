import React from "react";
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  Button,
  Input,
  Tabs,
  TabList,
  Tab,
  Image,
  useColorModeValue,
  Badge,
  TabPanels,
  TabPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputGroup,
  InputLeftElement,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";

import { BsFire } from "react-icons/bs";
import { BsJoystick } from "react-icons/bs";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useBreakpointValue } from '@chakra-ui/react';

import { useState, useEffect } from "react";
import {
  FaBitcoin,
  FaDollarSign,
  FaSearch,
  FaDice,
  FaCrown,
  FaGamepad,
  FaVideo,
  FaStar,
  FaGift,
  FaNewspaper,
} from "react-icons/fa";
import raceImage from "assets/img/promotion-section/race-image.jpg";
import raffleImage from "assets/img/promotion-section/raffle-image.jpg";
import casinoImage from "assets/img/promotion-section/casino-image.jpg";
import demoGameImage from "assets/img/casino-games/demo-game-image.jpg";
import demoGameImage2 from "assets/img/casino-games/demo-game-image2.jpg";
import pokerImage from "assets/img/casino/poker.jpg";
import dailyRacesImages from "assets/img/casino/daily-races.jpg";
import weeklyRaffleImage from "assets/img/casino/weekly-raffle.png";
import conquerTheCasinoImage from "assets/img/casino/conquer-the-casino.jpg";
import stakeVsEddieImage from "assets/img/casino/stake-vs-eddie.jpg";
import ChaosCollectorImage from "assets/img/casino/chaos-collector.jpg";

import minesImage from "assets/img/casino/originals/mines.avif";
import plinkoImage from "assets/img/casino/originals/plinko.avif";
import diceImage from "assets/img/casino/originals/dice.avif";
import limboImage from "assets/img/casino/originals/limbo.avif";
import crashImage from "assets/img/casino/originals/crash.avif";
import dragonTowerImage from "assets/img/casino/originals/dragon-tower.avif";
import kenoImage from "assets/img/casino/originals/keno.avif";
import wheelImage from "assets/img/casino/originals/wheel.avif";
import blackjackImage from "assets/img/casino/originals/blackjack.avif";
import hiloImage from "assets/img/casino/originals/hilo.avif";
import diamondsImage from "assets/img/casino/originals/diamonds.avif";
import slideImage from "assets/img/casino/originals/slide.avif";
import rouletteImage from "assets/img/casino/originals/roulette.avif";
import tomeOfLifeImage from "assets/img/casino/originals/tome-of-life.avif";
import scarabSpinImage from "assets/img/casino/originals/mines.avif";
import blueSamuraiImage from "assets/img/casino/originals/blue-samurai.avif";
import videoPokerImage from "assets/img/casino/originals/video-poker.avif";
import baccaratImage from "assets/img/casino/originals/baccarat.avif";

import sweetBonaza1000 from "assets/img/casino/slots/sweetBonaza_casino.avif";
import wantedDeadOrAWild from "assets/img/casino/slots/wantedDead_casino.avif";
import gatesOfOlympus from "assets/img/casino/slots/gatesOfOlympus_casino.avif";
import sugarRush1000 from "assets/img/casino/slots/sugarRush1000_casino.avif"
import leBandit from "assets/img/casino/slots/leBnadit_casino.avif";
import zeusVSheads from "assets/img/casino/slots/zeusVSheads_casino.avif";
import bonsaiBanzai from "assets/img/casino/slots/BonsaiBenzai_casino.avif";
import sweetBonaza from "assets/img/casino/slots/sweetBonanza_casino.avif";
import gatesOfOlympus1000 from "assets/img/casino/slots/gatesOfOlympus1000_casino.avif";
import firePortals from "assets/img/casino/slots/firePortals.avif";
import sugarRush from "assets/img/casino/slots/sugarRush.avif";
import ripCity from "assets/img/casino/slots/ripCity.avif";
import fruitParty from "assets/img/casino/slots/fruitParty.avif";
import tomeOfLife from "assets/img/casino/slots/tomeOfLife.avif";
import sweetFiesta from "assets/img/casino/slots/sweetFiesta.avif";
import fistOfDestruction from "assets/img/casino/slots/fistOfDestruction.avif";
import dogHouse from "assets/img/casino/slots/dogHouse.avif";
import slayersInc from "assets/img/casino/slots/slayersInc.avif";
import bigBassSplash from "assets/img/casino/slots/bigBassSplash.avif";
import fireInTheHole2 from "assets/img/casino/slots/fireInTheHole2.avif";
import gatesOfHeaven from "assets/img/casino/slots/gatesOfHeaven.avif";
import seeAll from "assets/img/casino/slots/seeAll.avif";

import pragmaticPlay from "assets/img/casino/providers/pragmatic.avif";
import evolution from "assets/img/casino/providers/evolution.avif";
import hacksaw from "assets/img/casino/providers/hacksaw.avif";
import noLimit from "assets/img/casino/providers/noLimit.avif";
import playNgo from "assets/img/casino/providers/playNgo.avif";
import pushGaming from "assets/img/casino/providers/pushGaming.avif";
import relaxGaming from "assets/img/casino/providers/relaGaming.avif";
import massiveStudio from "assets/img/casino/providers/massiveStudio.avif";
import titan from "assets/img/casino/providers/titanGaming.avif";
import avatarUX from "assets/img/casino/providers/avatarUX.avif";
import backSeat from "assets/img/casino/providers/backSeatGaming.avif";
import twist from "assets/img/casino/providers/twist.avif";
import octoPlay from "assets/img/casino/providers/octoplay.avif";
import ELKStudios from "assets/img/casino/providers/ELKstudios.avif";
import thunderKick from "assets/img/casino/providers/thunderclick.avif";
import popiPlay from "assets/img/casino/providers/popiplay.avif";
import Bgaming from "assets/img/casino/providers/Bgaming.avif";
import printStudios from "assets/img/casino/providers/printStudios.avif";
import bullShark from "assets/img/casino/providers/bullSharkGanes.avif";
import pocketGamesSoft from "assets/img/casino/providers/pocketGamesSoft.avif";
import netent from "assets/img/casino/providers/netent.avif";
import BTG from "assets/img/casino/providers/BTG.avif";
import redTiger from "assets/img/casino/providers/redTiger.avif";
import fantasma from "assets/img/casino/providers/fantasma.avif";
import gameArt from "assets/img/casino/providers/gameArt.avif";
import oneTouch from "assets/img/casino/providers/oneTouch.avif";
import wazdan from "assets/img/casino/providers/wazdan.avif";
import live88 from "assets/img/casino/providers/live88.avif";
import gamesGlobal from "assets/img/casino/providers/gamesGlobal.avif";
import belatra from "assets/img/casino/providers/belatraGames.avif";
import endorphina from "assets/img/casino/providers/endorphina.avif";
import trueLab from "assets/img/casino/providers/trueLab.avif";
import bluePrintGaming from "assets/img/casino/providers/bluePrintGaming.avif";
import redRake from "assets/img/casino/providers/redRake.avif";
import boomingGames from "assets/img/casino/providers/booming.avif";
import quickSpin from "assets/img/casino/providers/quickspin.avif";
import oaks from "assets/img/casino/providers/3oaks.avif";
import jadeRabbitStudio from "assets/img/casino/providers/jadeRabbitStudio.avif";
import spinomenal from "assets/img/casino/providers/spinomenal.avif";

import blackJack from "assets/img/casino/liveCasino/blackjack.avif";
import roulette from "assets/img/casino/liveCasino/roulette.avif";
import baccarat from "assets/img/casino/liveCasino/baccarat.avif";
import dragonTiger from "assets/img/casino/liveCasino/dragonTiger.avif";
import craps from "assets/img/casino/liveCasino/craps.avif";
import extremeTexas from "assets/img/casino/liveCasino/extremeTexas.avif";
import redDoorRoulette from "assets/img/casino/liveCasino/redDoorRoulette.avif";
import bacBo from "assets/img/casino/liveCasino/bacBo.avif";
import casinoHoldThem from "assets/img/casino/liveCasino/casinoHold'Em.avif";
import stockMarket from "assets/img/casino/liveCasino/stockMarket.avif";
import crazyTime from "assets/img/casino/liveCasino/crazyTime.avif";
import cashOrCrash from "assets/img/casino/liveCasino/cashOrCrash.avif";
import lightningBall from "assets/img/casino/liveCasino/lightiningBall.avif";
import crazyPachinko from "assets/img/casino/liveCasino/crazyPochinko.avif";
import gonzosTreasureMap from "assets/img/casino/liveCasino/gonzo'sTreasureMap.avif";
import monopoly from "assets/img/casino/liveCasino/monopoly.avif";
import footballStudio from "assets/img/casino/liveCasino/footballStudio.avif";
import monopolyBigBaller from "assets/img/casino/liveCasino/monopolyBigBaller.avif";
import megaBall100X from "assets/img/casino/liveCasino/megaBall100X.avif";
import funkyTime from "assets/img/casino/liveCasino/funkyTime.avif";
import crazyCoinFlip from "assets/img/casino/liveCasino/crazyCoinFlip.avif";
import LiveseeAll from "assets/img/casino/liveCasino/seeAll.avif";

import crazyTimeGameShow from "assets/img/casino/gameShows/crazyTime.avif";
import lightningRoulette from "assets/img/casino/gameShows/lightiningRoulette.avif";
import lightningStorm from "assets/img/casino/gameShows/lightningStorm.avif";
import stockMarketGameShow from "assets/img/casino/gameShows/stockMarket.avif";
import balloonRace from "assets/img/casino/gameShows/balloonRace.avif";
import xtremeLightningRoulette from "assets/img/casino/gameShows/xtremeLightningRoulette.avif";
import monopolyGameShow from "assets/img/casino/gameShows/monopoly.avif";
import monopolyBigBallerGameShow from "assets/img/casino/gameShows/monopolyBigBaller.avif";
import lightningBlackJack from "assets/img/casino/gameShows/lightningBlackJack.avif";
import funkyTimeGameShow from "assets/img/casino/gameShows/funkyTime.avif";
import redDoorRouletteGameShow from "assets/img/casino/gameShows/redDoorRoulette.avif";
import sweetBonanzaCandyLand from "assets/img/casino/gameShows/sweetBonanzaCandyLand.avif";
import lightningBaccarat from "assets/img/casino/gameShows/lightningBaccarat.avif";
import crazyPachinkoGameShow from "assets/img/casino/gameShows/crazyPachinko.avif";
import crazyCoinFlipGameShow from "assets/img/casino/gameShows/crazyCoinFlip.avif";
import megaBall100XGameShow from "assets/img/casino/gameShows/megaBall100X.avif";
import footBallStudioGameShow from "assets/img/casino/gameShows/footballStudio.avif";
import lightningDice from "assets/img/casino/gameShows/lightningDice.avif";
import goldVaultRoulette from "assets/img/casino/gameShows/goldVaultRoulette.avif";
import cashOrCrashGameShow from "assets/img/casino/gameShows/cashOrCrash.avif";
import royalRichesRoulette from "assets/img/casino/gameShows/royalRichesRoulette.avif";
import seeAllGameShows from "assets/img/casino/gameShows/seeAllGameShows.avif";

import dogMansionMegaWays from "assets/img/casino/StakeExclusive/dogMansionMegaWays.avif";
import clashOfFangs from "assets/img/casino/StakeExclusive/clashOfFangs.avif";
import sweetFiestaExclusive from "assets/img/casino/StakeExclusive/sweetFiestaExclusive.avif";
import roostersRevenge from "assets/img/casino/StakeExclusive/rooster'sRevenge.avif";
import pixelFarm from "assets/img/casino/StakeExclusive/pixelFarm.avif";
import wildWestBounty from "assets/img/casino/StakeExclusive/wildWestBounty.avif";
import athenaVSares from "assets/img/casino/StakeExclusive/athenaVSares.avif";
import bisonSpirit from "assets/img/casino/StakeExclusive/bisonSpirit.avif";
import poseidonVSapollo from "assets/img/casino/StakeExclusive/poseIdonVSapollo.avif";
import popRoyale from "assets/img/casino/StakeExclusive/popRoyale.avif";
import lePharaoh from "assets/img/casino/StakeExclusive/lePharaoh.avif";
import wildWestBonanza from "assets/img/casino/StakeExclusive/wildWestBonanza.avif";
import bonsaiBanzaiExclusive from "assets/img/casino/StakeExclusive/bonsaiBanzaiExclusive.avif";
import skyshipRaiders from "assets/img/casino/StakeExclusive/skyshipRaiders.avif";
import dracksStacks from "assets/img/casino/StakeExclusive/drack'sStacks.avif";
import jewelBonanza from "assets/img/casino/StakeExclusive/jewelBonanza.avif";
import spaceKnightMergeUp from "assets/img/casino/StakeExclusive/spaceKnightMergeUp.avif";
import gatesOfHeavenExclusive from "assets/img/casino/StakeExclusive/gatesOfHeavenExclusive.avif";
import sugarTwist from "assets/img/casino/StakeExclusive/sugarTwistExclusive.avif";
import slushieParty from "assets/img/casino/StakeExclusive/slushieParty.avif";
import underworld from "assets/img/casino/StakeExclusive/underworld.avif";
import seeAllExclusive from "assets/img/casino/StakeExclusive/seeAllExclusive.avif";

import muttleyCrew from "assets/img/casino/newReleases/muttleyCrew.avif";
import brainsForBreakfast from "assets/img/casino/newReleases/brainsForBreakfast.avif";
import empressOfShadows from "assets/img/casino/newReleases/empressOfShadows.avif";
import congoCashXL from "assets/img/casino/newReleases/congoCashXl.avif";
import sweetGummy from "assets/img/casino/newReleases/sweetGummy.avif";
import divineDivas from "assets/img/casino/newReleases/divineDivas.avif";
import milkHunter from "assets/img/casino/newReleases/milkHunter.avif";
import coinsAndCannons from "assets/img/casino/newReleases/coinsAndCanons.avif";
import jokersJewelsHot from "assets/img/casino/newReleases/joker'sJewelsHOt.avif";
import voodooPeople from "assets/img/casino/newReleases/voodooPeople.avif";
import goldenBrew from "assets/img/casino/newReleases/goldenBrew.avif";
import eagleStorm from "assets/img/casino/newReleases/eagleStorm.avif";
import shadowSummonerEgypt from "assets/img/casino/newReleases/shadowSummonerEgypt.avif";
import bavarianRiches2 from "assets/img/casino/newReleases/bavarianRiches2.avif";
import monstersUnchained from "assets/img/casino/newReleases/monstersUnchained.avif";
import lePharaohNewReleases from "assets/img/casino/newReleases/lePharaohNewReleases.avif";
import mysteryMice from "assets/img/casino/newReleases/mysteryMice.avif";
import skateOrDie from "assets/img/casino/newReleases/skateOrDie.avif";
import evilEyes from "assets/img/casino/newReleases/evilEyes.avif";
import bigBassVegas from "assets/img/casino/newReleases/bigBassVegas.avif";
import buildinBucks from "assets/img/casino/newReleases/buildinBucks.avif";
import seeAllNewRelease from "assets/img/casino/newReleases/seeAllNewReleases.avif";


export default function Casino() {

  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const secondaryTextColor = useColorModeValue("gray.400", "gray.400");

  const [allBets, setAllBets] = useState([]);
  const [highRollers, setHighRollers] = useState([]);
  const [raceLeaderboard, setRaceLeaderboard] = useState([]);
  
  const CollapsibleSection = () => {
    const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Box color={secondaryTextColor} p={6} borderRadius="md">
        <Collapse startingHeight={150} in={isOpen}>
          <Flex direction={{ base: 'column', md: 'row' }} gap={6}>
            <Box flex="1">
              <Text color={textColor} fontSize="lg" fontWeight="bold">Crypto Casino Games Online</Text>
              <Text mt={4}>
                <strong>Since 2017 WagerStreet.net</strong> has offered the best online crypto and bitcoin
                casino gaming experience on the web. Starting with original first-party casino games
                developed by WagerStreet.net, the online crypto casino platform has grown to over 3000 casino games from the best
                providers in the iGaming industry.
              </Text>
              <Text mt={4}>
                Easy to use, rich in features and with a simple platform, players come back to Wager Street time and again for more of their
                favourite crypto casino games.
              </Text>
            </Box>
            <Box flex="1">
              <Text mt={{ base: 6, md: 0 }}>
                Many table game classics include <strong>Blackjack games</strong>, <strong>Baccarat games</strong>, <strong>Roulette games</strong>, and more,
                which are popular types of casino games due to their advantageous house edge and big win opportunities. Learn more about how to
                play these with our ultimate guide to table games.
              </Text>
              <Text mt={4}>
                Electronic table games provide not only the convenience of playing for real payouts in the comfort of your device, but
                also provide additional features, flexibility in betting and often a higher Return to Player than the physical game.
              </Text>
            </Box>
          </Flex>
        </Collapse>
        <Flex justifyContent="center" mt="1rem">
        <Button size="sm" onClick={onToggle}>
          {isOpen ? "View Less" : "See More"}
        </Button>
      </Flex>
      </Box>
    );
  };

  const images = [
    raceImage,
    raffleImage,
    casinoImage,
    demoGameImage,
    demoGameImage2,
  ];

  const slidesData = [
    {
      image: pokerImage,
      title: "Poker",
      description: "Cash games now live!",
      buttonText: "Play Now",
      badgeText: "Promo",
    },
    {
      image: dailyRacesImages,
      title: "Daily Races",
      description: "Play in our $100,000 Daily Race",
      buttonText: "Race Now",
      badgeText: "Special",
    },
    {
      image: weeklyRaffleImage,
      title: "Weekly Raffle",
      description: "Explore the world of thrilling casino games",
      buttonText: "Play Now",
      badgeText: "New",
    },
    {
      image: conquerTheCasinoImage,
      title: "Conquer The Casino",
      description: "Try our games for free before betting",
      buttonText: "Try Now",
      badgeText: "Demo",
    },
    {
      image: stakeVsEddieImage,
      title: "Wager Street vs Eddie",
      description: "Massive jackpot wins await you",
      buttonText: "Play Now",
      badgeText: "Jackpot",
    },
    {
      image: ChaosCollectorImage,
      title: "Chaos Collector",
      description: "Massive jackpot wins await you",
      buttonText: "Play Now",
      badgeText: "Jackpot",
    },
  ];

  const gameSliders = [
    {
      image: crashImage,
      alt: "Crash",
    },
    {
      image: plinkoImage,
      alt: "Plinko",
    },
    {
      image: minesImage,
      alt: "Mines",
    },
    {
      image: limboImage,
      alt: "Mines",
    },
    {
      image: kenoImage,
      alt: "Keno",
    },
    {
      image: hiloImage,
      alt: "Hilo",
    },
    {
      image: blackjackImage,
      alt: "Black Jack",
    },
    {
      image: dragonTowerImage,
      alt: "Dragon Tower",
    },
    {
      image: tomeOfLifeImage,
      alt: "Tome Of Life",
    },
    {
      image: wheelImage,
      alt: "Wheel",
    },
    {
      image: scarabSpinImage,
      alt: "Scarab Spin",
    },
    {
      image: diamondsImage,
      alt: "Diamonds",
    },
    {
      image: rouletteImage,
      alt: "Roulette",
    },
    {
      image: slideImage,
      alt: "Slide",
    },
    {
      image: blueSamuraiImage,
      alt: "Blue Samurai",
    },
    {
      image: videoPokerImage,
      alt: "Video Poker",
    },
    {
      image: baccaratImage,
      alt: "Baccarat",
    },
  ];

  const slotSliders = [
    {
      image: sweetBonaza1000,
      alt: 'Sweet Bonanza 1000',

    },
    {
      image: wantedDeadOrAWild,
      alt: 'Wanted Dead Or A Wild',

    },
    {
      image: gatesOfOlympus,
      alt: 'Gates of Olympus',

    },
    {
      image: sugarRush1000,
      alt: 'Sugar Rush 1000',

    },
    {
      image: leBandit,
      alt: 'Le Bandit',
    },
    {
      image: zeusVSheads,
      alt: 'Zeus vs Hades Gods Of War',

    },
    {
      image: bonsaiBanzai,
      alt: 'Bonsai Banzai',

    },
    {
      image: sweetBonaza,
      alt: 'Sweet Bonanza',

    },
    {
      image: gatesOfOlympus1000,
      alt: 'Gates Of Olympus 1000',

    },
    {
      image: firePortals,
      alt: 'Fire Portals',

    },
    {
      image: sugarRush,
      alt: 'Sugar Rush',

    },
    {
      image: ripCity,
      alt: 'RIP City',

    },
    {
      image: fruitParty,
      alt: 'Fruit Party',

    },
    {
      image: tomeOfLife,
      alt: 'Tome Of Life',

    },
    {
      image: sweetFiesta,
      alt: 'Sweet Fiesta',

    },
    {
      image: fistOfDestruction,
      alt: 'Fist Of Destruction',

    },
    {
      image: dogHouse,
      alt: 'The Dog House',

    },
    {
      image: slayersInc,
      alt: 'Slayers INC',

    },
    {
      image: bigBassSplash,
      alt: 'Big Bass Splash',

    },
    {
      image: fireInTheHole2,
      alt: 'Fire In The Hole 2',

    },
    {
      image: gatesOfHeaven,
      alt: 'Gates Of Heaven',

    },
    {
      image: seeAll,
      alt: 'See All',

    },
  ];

  const providerSliders = [
    {
      image: pragmaticPlay,
      alt: 'pragmaticPlay',

    },
    {
      image: evolution,
      alt: 'evolution',

    },
    {
      image: hacksaw,
      alt: 'hacksaw',

    },
    {
      image: noLimit,
      alt: 'noLimit',

    },
    {
      image: playNgo,
      alt: 'playNgo',
    },
    {
      image: pushGaming,
      alt: 'pushGaming',

    },
    {
      image: relaxGaming,
      alt: 'relaxGaming',

    },
    {
      image: massiveStudio,
      alt: 'massiveStudio',

    },
    {
      image: titan,
      alt: 'titan',

    },
    {
      image: avatarUX,
      alt: 'avatarUX',

    },
    {
      image: backSeat,
      alt: 'backSeat',

    },
    {
      image: twist,
      alt: 'twist',

    },
    {
      image: octoPlay,
      alt: 'octoPlay',

    },
    {
      image: ELKStudios,
      alt: 'ELKStudios',

    },
    {
      image: thunderKick,
      alt: 'thunderKick',

    },
    {
      image: popiPlay,
      alt: 'popiPlay',

    },
    {
      image: Bgaming,
      alt: 'Bgaming',

    },
    {
      image: printStudios,
      alt: 'printStudios',

    },
    {
      image: bullShark,
      alt: 'bullShark',

    },
    {
      image: pocketGamesSoft,
      alt: 'pocketGamesSoft',

    },
    {
      image: netent,
      alt: 'netent',

    },
    {
      image: BTG,
      alt: 'BTG',

    },
    {
      image: redTiger,
      alt: 'redTiger'
    },
    {
      image: fantasma,
      alt: 'fantasma',

    },
    {
      image: gameArt,
      alt: 'gameArt',

    },
    {
      image: oneTouch,
      alt: 'oneTouch',

    },
    {
      image: wazdan,
      alt: 'wazdan',

    },
    {
      image: live88,
      alt: 'live88',

    },
    {
      image: gamesGlobal,
      alt: 'gamesGlobal',

    },
    {
      image: belatra,
      alt: 'belatra',

    },
    {
      image: endorphina,
      alt: 'endorphina'
    },
    {
      image: trueLab,
      alt: 'trueLab'
    },
    {
      image: bluePrintGaming,
      alt: 'bluePrintGaming'
    },
    {
      image: redRake,
      alt: 'redRake'
    },
    {
      image: boomingGames,
      alt: 'boomingGames'
    },
    {
      image: quickSpin,
      alt: 'quickSpin'
    },
    {
      image: oaks,
      alt: 'oaks'
    },
    {
      image: jadeRabbitStudio,
      alt: 'jadeRabbitStudio'
    },
    {
      image: spinomenal,
      alt: 'spinomenal'
    },
  ];

  const liveGameSliders = [
    {
      image: blackJack,
      alt: 'blackJack',

    },
    {
      image: roulette,
      alt: 'roulette',

    },
    {
      image: baccarat,
      alt: 'baccarat',

    },
    {
      image: dragonTiger,
      alt: 'dragonTiger',

    },
    {
      image: craps,
      alt: 'craps',
    },
    {
      image: extremeTexas,
      alt: 'extremeTexas',

    },
    {
      image: redDoorRoulette,
      alt: 'redDoorRoulette',

    },
    {
      image: bacBo,
      alt: 'bacBo',

    },
    {
      image: casinoHoldThem,
      alt: 'casinoHoldThem',

    },
    {
      image: stockMarket,
      alt: 'stockMarket',

    },
    {
      image: crazyTime,
      alt: 'crazyTime',

    },
    {
      image: cashOrCrash,
      alt: 'cashOrCrash',

    },
    {
      image: lightningBall,
      alt: 'lightningBall',

    },
    {
      image: crazyPachinko,
      alt: 'crazyPachinko',

    },
    {
      image: gonzosTreasureMap,
      alt: 'gonzosTreasureMap',

    },
    {
      image: monopoly,
      alt: 'monopoly',

    },
    {
      image: footballStudio,
      alt: 'footballStudio',

    },
    {
      image: monopolyBigBaller,
      alt: 'monopolyBigBaller',

    },
    {
      image: megaBall100X,
      alt: ' megaBall100X',

    },
    {
      image: funkyTime,
      alt: 'funkyTime',

    },
    {
      image: crazyCoinFlip,
      alt: 'crazyCoinFlip',

    },
    {
      image: LiveseeAll,
      alt: 'Live See All',

    },
  ];

  const gameShowsSliders = [
    {
      image: crazyTimeGameShow,
      alt: 'crazyTime',

    },
    {
      image: lightningRoulette,
      alt: 'lightningRoulette',

    },
    {
      image: lightningStorm,
      alt: 'lightningStorm',

    },
    {
      image: stockMarketGameShow,
      alt: 'stockMarket',

    },
    {
      image: balloonRace,
      alt: 'balloonRace',
    },
    {
      image: xtremeLightningRoulette,
      alt: 'xtremeLightningRoulette',

    },
    {
      image: monopolyGameShow,
      alt: 'monopoly',

    },
    {
      image: monopolyBigBallerGameShow,
      alt: 'monopolyBigBaller',

    },
    {
      image: lightningBlackJack,
      alt: 'lightningBlackJack',

    },
    {
      image: funkyTimeGameShow,
      alt: 'funkyTime',

    },
    {
      image: redDoorRouletteGameShow,
      alt: 'redDoorRoulette',

    },
    {
      image: sweetBonanzaCandyLand,
      alt: 'sweetBonanzaCandyLand',

    },
    {
      image: lightningBaccarat,
      alt: 'lightningBaccarat',

    },
    {
      image: crazyPachinkoGameShow,
      alt: 'crazyPachinko',

    },
    {
      image: crazyCoinFlipGameShow,
      alt: 'crazyCoinFlip',

    },
    {
      image: megaBall100XGameShow,
      alt: 'megaBall100XGameShow',

    },
    {
      image: footBallStudioGameShow,
      alt: 'footBallStudioGameShow',

    },
    {
      image: lightningDice,
      alt: 'lightningDice',

    },
    {
      image: goldVaultRoulette,
      alt: 'goldVaultRoulette',

    },
    {
      image: cashOrCrashGameShow,
      alt: ' cashOrCrash',

    },
    {
      image: royalRichesRoulette,
      alt: 'royalRichesRoulette',

    },
    {
      image: seeAllGameShows,
      alt: 'seeAllGameShows',

    },
  ];

  const exclusiveSliders = [
    {
      image: dogMansionMegaWays,
      alt: 'dogMansionMegaWays',

    },
    {
      image: clashOfFangs,
      alt: 'clashOfFangs',

    },
    {
      image: sweetFiestaExclusive,
      alt: 'sweetFiestaExclusive',

    },
    {
      image: roostersRevenge,
      alt: 'roostersRevenge',

    },
    {
      image: pixelFarm,
      alt: 'pixelFarm',
    },
    {
      image: wildWestBounty,
      alt: 'wildWestBounty',

    },
    {
      image: athenaVSares,
      alt: 'athenaVSares',

    },
    {
      image: bisonSpirit,
      alt: 'bisonSpirit',

    },
    {
      image: poseidonVSapollo,
      alt: 'poseidonVSapollo',

    },
    {
      image: popRoyale,
      alt: 'popRoyale',

    },
    {
      image: lePharaoh,
      alt: 'lePharaoh',

    },
    {
      image: wildWestBonanza,
      alt: 'wildWestBonanza',

    },
    {
      image: bonsaiBanzaiExclusive,
      alt: 'bonsaiBanzaiExclusive',

    },
    {
      image: skyshipRaiders,
      alt: 'skyshipRaiders',

    },
    {
      image: dracksStacks,
      alt: 'dracksStacks',

    },
    {
      image: jewelBonanza,
      alt: 'jewelBonanza',

    },
    {
      image: spaceKnightMergeUp,
      alt: 'spaceKnightMergeUp',

    },
    {
      image: gatesOfHeavenExclusive,
      alt: 'gatesOfHeavenExclusive',

    },
    {
      image: sugarTwist,
      alt: 'sugarTwist',

    },
    {
      image: slushieParty,
      alt: 'slushieParty',

    },
    {
      image: underworld,
      alt: 'underworld',

    },
    {
      image: seeAllExclusive,
      alt: 'seeAllExclusive',

    },
  ];

  const newReleasesSliders = [
    {
      image: muttleyCrew,
      alt: 'muttleyCrew',

    },
    {
      alt: 'brainsForBreakfast',
   image: brainsForBreakfast,
   
    },
    {
      alt: 'empressOfShadows',
   image: empressOfShadows,
   
    },
    {
      image: congoCashXL,
      alt: 'congoCashXL',

    },
    {
      image: sweetGummy,
      alt: 'sweetGummy',

    },
    {
      image: divineDivas,
      alt: 'divineDivas',
    },
    {
      image: milkHunter,
      alt: 'milkHunter',

    },
    {
      image: coinsAndCannons,
      alt: 'coinsAndCannons',

    },
    {
      image: jokersJewelsHot,
      alt: 'jokersJewelsHot',

    },
    {
      image: poseidonVSapollo,
      alt: 'poseidonVSapollo',

    },
    {
      image: voodooPeople,
      alt: 'voodooPeople',

    },
    {
      image: goldenBrew,
      alt: 'goldenBrew',

    },
    {
      image: eagleStorm,
      alt: 'eagleStorm',

    },
    {
      image: shadowSummonerEgypt,
      alt: 'shadowSummonerEgypt',

    },
    {
      image: bavarianRiches2,
      alt: 'bavarianRiches2',

    },
    {
      image: monstersUnchained,
      alt: 'monstersUnchained',

    },
    {
      image: lePharaohNewReleases,
      alt: 'lePharaohNewReleases',

    },
    {
      image: mysteryMice,
      alt: 'mysteryMice',

    },
    {
      image: skateOrDie,
      alt: 'skateOrDie',

    },
    {
      image: evilEyes,
      alt: 'evilEyes',

    },
    {
      image: bigBassVegas,
      alt: 'bigBassVegas',

    },
    {
      image: buildinBucks,
      alt: 'buildinBucks',

    },
    {
      image: seeAllNewRelease,
      alt: 'seeAllNewRelease',

    },
  ];
  

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    const iconColor = useColorModeValue("black", "white");

    return (
      <ChevronRightIcon
        className={className}
        style={{ ...style, color: iconColor, fontSize: "2rem" }}
        onClick={onClick}
      />
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    const iconColor = useColorModeValue("black", "white");

    return (
      <ChevronLeftIcon
        className={className}
        style={{ ...style, color: iconColor, fontSize: "2rem" }}
        onClick={onClick}
      />
    );
  };
  // Simulate fetching data from an API
  useEffect(() => {
    const fetchAllBets = async () => {
      const data = [
        {
          game: "Plinko",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "Limbo",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "-1999.199920",
        },
        {
          game: "Black Jack",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "LE Bandit",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "2999.199920",
        },
        // Add more dummy data here
      ];
      setAllBets(data);
    };

    const fetchHighRollers = async () => {
      const data = [
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        // Add more dummy data here
      ];
      setHighRollers(data);
    };

    const fetchRaceLeaderboard = async () => {
      const data = [
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        // Add more dummy data here
      ];
      setRaceLeaderboard(data);
    };

    fetchAllBets();
    fetchHighRollers();
    fetchRaceLeaderboard();
  }, []);

  const settings = {
    dots: false,
    infinite: false, // Set infinite to false so it doesn't loop
    speed: 500,
    slidesToShow: 3, // Adjust as needed
    slidesToScroll: 2,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const carouselSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 6, // Number of slides visible at once
    slidesToScroll: 2, // Number of slides to scroll at once
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768, // For smaller devices
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "65px" }}>
      <style>
        {`
          .table-wrapper {
              overflow-x: auto;
             -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          }

          @media (max-width: 600px) {
            .hide-mobile {
              display: none;
            }
          }
        `}
      </style>
      {/* Promotions Section */}
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <SimpleGrid key={index} columns={{ base: 1, md: 2, lg: 3 }}>
            <Box
              m={2}
              p="16px"
              borderRadius="12px"
              boxShadow="lg"
              bg={useColorModeValue("white", "gray.700")}
              minH="250px" // Set a minimum height for all slides
              h="100%"
            >
              <Flex justify="space-between" align="center" h="100%" gap="10px">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  h="100%"
                >
                  <Box>
                    <Badge borderRadius="full" px="2" colorScheme="teal" mb={4}>
                      {slide.badgeText}
                    </Badge>
                    <Text fontSize="xl" fontWeight="bold">
                      {slide.title}
                    </Text>
                    <Text fontSize="0.8em" mb={4}>
                      {slide.description}
                    </Text>
                  </Box>
                  <Button mt="auto" alignSelf="start">
                    {slide.buttonText}
                  </Button>
                </Box>
                <Image
                  src={slide.image}
                  alt={slide.title}
                  boxSize={{ base: "120px", md: "150px", lg: "200px" }}
                  minW="35px"
                  maxW="100%"
                  objectFit="cover"
                  transition="all 0.2s ease-in-out"
                  _hover={{ transform: "translateY(-10px)" }}
                />
              </Flex>
            </Box>
          </SimpleGrid>
        ))}
      </Slider>

      {/* Search Bar */}
      <Box mb={4} mt={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FaSearch color="gray.300" />
          </InputLeftElement>
          <Input placeholder="Search your game" size="md" borderRadius="12px" />
        </InputGroup>
      </Box>

      {/* Games Tabs */}
      <Box mx={{ base: "2", md: "4" }}
      >
        <Tabs
          boxShadow="lg"
          variant="solid-rounded"
          colorScheme="teal"
          size={{ base: "sm", md: "md" }} // Responsive tab size
          mb={12}
          isLazy // Lazy loading for performance
        >
          <TabList mb={6} display="flex" overflowX="auto">
            
            <Tab minWidth="100px"
             mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaDice style={{ marginRight: "8px" }} />
              Lobby
            </Tab>
            <Tab minWidth="100px"
             mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaCrown style={{ marginRight: "8px" }} />
              Regal Originals
            </Tab>
            <Tab minWidth="100px"  mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaGamepad style={{ marginRight: "8px" }} />
              Slots
            </Tab>
            <Tab minWidth="100px"  mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaVideo style={{ marginRight: "8px" }} />
              Live Casino
            </Tab>
            <Tab minWidth="100px"  mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaStar style={{ marginRight: "8px" }} />
              Game Shows
            </Tab>
            <Tab minWidth="100px"  mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaGift style={{ marginRight: "8px" }} />
              Regal Exclusives
            </Tab>
            <Tab minWidth="100px"  mb={useBreakpointValue({ base: "8px", md: "0" })}>
              <FaNewspaper style={{ marginRight: "8px" }} />
              New Releases
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* Lobby Section */}

              <Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold"
              >
                <BsFire />
                <Text ml={2}>Regal Originals</Text>
              </Flex>
              <Slider {...carouselSettings}>
                {gameSliders.map(({ image, alt }, index) => (
                  <Box
                    key={index}
                    position="relative"
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    p={4}
                    pl={0}
                    transition="all 0.2s ease-in-out"
                    _hover={{ transform: "translateY(-10px)" }}
                  >
                    <Badge
                      position="absolute"
                      top="30px"
                      left="0"
                      width="26px"
                      height="30px"
                      padding=".5em"
                      backgroundColor="#2f4553"
                      borderBottomRightRadius="5px"
                      borderTopRightRadius="5px"
                      fontWeight="700"
                      lineHeight="120%"
                      zIndex="1"
                    >
                      {index + 1}
                    </Badge>
                    <Image
                      src={image}
                      style={{ width: "100%", height: "80%" }}
                      alt={alt}
                      borderRadius="md"
                    />
                    {/* Container for the green light and text */}
                    <Box mt={2} display="flex" alignItems="center">
                      {/* Green blinking light */}
                      <Box
                        width="6.5px"
                        height="6.5px"
                        borderRadius="50%"
                        backgroundColor="#39FF14"
                        boxShadow="0 0 5px #39FF14, 0 0 10px #39FF14"
                        animation="blink 1s infinite"
                        mr={2}
                      />
                      <Text fontSize="sm" color="gray.300">
                        5,647 playing
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Slider>

              <Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold">
                   <FaGamepad />
                <Text ml={2}>Slots</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {slotSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>

      <Badge
        position="absolute"
        top="30px"
        left="0"
        width="26px"
        height="30px"
        padding=".5em"
        backgroundColor="#2f4553"
        borderBottomRightRadius="5px"
        borderTopRightRadius="5px"
        fontWeight="700"
        lineHeight="120%"
        zIndex="1"
      >
        {index + 1}
      </Badge>

      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'See All' && (
        <Box
          position="absolute"
          top="45%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}

      {/* Container for the green light and text */}
      <Box mt={2} display="flex" alignItems="center">
        {/* Green blinking light */}
        <Box
          width="6.5px"
          height="6.5px"
          borderRadius="50%"
          backgroundColor="#39FF14"
          boxShadow="0 0 5px #39FF14, 0 0 10px #39FF14"
          animation="blink 1s infinite"
          mr={2}
        />
        <Text fontSize="sm" color="gray.300">
          347 playing
        </Text>
      </Box>
    </Box>
  ))}
</Slider>

<Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold"
              >
                <BsJoystick />
                <Text ml={2}>Providers</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {providerSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>


      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'Live See All' && (
        <Box
          position="absolute"
          top="45%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}
    </Box>
  ))}
</Slider>

<Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold"
              >
                <FaVideo />

                <Text ml={2}>Live Casino</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {liveGameSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>

      <Badge
        position="absolute"
        top="30px"
        left="0"
        width="26px"
        height="30px"
        padding=".5em"
        backgroundColor="#2f4553"
        borderBottomRightRadius="5px"
        borderTopRightRadius="5px"
        fontWeight="700"
        lineHeight="120%"
        zIndex="1"
      >
        {index + 1}
      </Badge>

      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'Live See All' && (
        <Box
          position="absolute"
          top="50%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}

    </Box>
  ))}
</Slider>

<Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold"
              >
<FaStar />

                <Text ml={2}>Game Shows</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {gameShowsSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>

      <Badge
        position="absolute"
        top="30px"
        left="0"
        width="26px"
        height="30px"
        padding=".5em"
        backgroundColor="#2f4553"
        borderBottomRightRadius="5px"
        borderTopRightRadius="5px"
        fontWeight="700"
        lineHeight="120%"
        zIndex="1"
      >
        {index + 1}
      </Badge>

      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'seeAllGameShows' && (
        <Box
          position="absolute"
          top="45%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}

      {/* Container for the green light and text */}
      <Box mt={2} display="flex" alignItems="center">
        {/* Green blinking light */}
        <Box
          width="6.5px"
          height="6.5px"
          borderRadius="50%"
          backgroundColor="#39FF14"
          boxShadow="0 0 5px #39FF14, 0 0 10px #39FF14"
          animation="blink 1s infinite"
          mr={2}
        />
        <Text fontSize="sm" color="gray.300">
          47 playing
        </Text>
      </Box>
    </Box>
  ))}
</Slider>

<Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold">
<FaGift />
<Text ml={2}>Regal Exclusives</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {exclusiveSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>

      <Badge
        position="absolute"
        top="30px"
        left="0"
        width="26px"
        height="30px"
        padding=".5em"
        backgroundColor="#2f4553"
        borderBottomRightRadius="5px"
        borderTopRightRadius="5px"
        fontWeight="700"
        lineHeight="120%"
        zIndex="1"
      >
        {index + 1}
      </Badge>

      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'seeAllExclusive' && (
        <Box
          position="absolute"
          top="45%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}

      {/* Container for the green light and text */}
      <Box mt={2} display="flex" alignItems="center">
        {/* Green blinking light */}
        <Box
          width="6.5px"
          height="6.5px"
          borderRadius="50%"
          backgroundColor="#39FF14"
          boxShadow="0 0 5px #39FF14, 0 0 10px #39FF14"
          animation="blink 1s infinite"
          mr={2}
        />
        <Text fontSize="sm" color="gray.300">
          10 playing
        </Text>
      </Box>
    </Box>
  ))}
</Slider>


<Flex
                alignItems="center"
                lineHeight="1.5"
                fontSize="1.125rem"
                fontWeight="bold"
              >
<FaNewspaper/>

                <Text ml={2}>New Realeases</Text>
              </Flex>
              <Slider {...carouselSettings}>
  {newReleasesSliders.map(({ image, alt }, index) => (
    <Box
      key={index}
      position="relative"
      borderRadius="12px"
      textAlign="center"
      color="white"
      p={4}
      pl={0}
      transition="all 0.2s ease-in-out"
      _hover={{ transform: "translateY(-10px)" }}>

      <Badge
        position="absolute"
        top="30px"
        left="0"
        width="26px"
        height="30px"
        padding=".5em"
        backgroundColor="#2f4553"
        borderBottomRightRadius="5px"
        borderTopRightRadius="5px"
        fontWeight="700"
        lineHeight="120%"
        zIndex="1"
      >
        {index + 1}
      </Badge>

      <Image
        src={image}
        style={{ width: "100%", height: "80%" }}
        alt={alt}
        borderRadius="md"
      />

      {alt === 'seeAllNewReleases' && (
        <Box
          position="absolute"
          top="45%"
          left="45%"
          transform="translate(-50%, -50%)"
          zIndex="2"
          borderRadius="md"
          padding="10px 20px"
        >
          <Text
            fontSize="xl"
            color="white"
             whiteSpace="nowrap"
             fontWeight="400"
          >
            See All
          </Text>
        </Box>
      )}

      {/* Container for the green light and text */}
      <Box mt={2} display="flex" alignItems="center">
        {/* Green blinking light */}
        <Box
          width="6.5px"
          height="6.5px"
          borderRadius="50%"
          backgroundColor="#39FF14"
          boxShadow="0 0 5px #39FF14, 0 0 10px #39FF14"
          animation="blink 1s infinite"
          mr={2}
        />
        <Text fontSize="sm" color="gray.300">
          17 playing
        </Text>
      </Box>
    </Box>
  ))}
</Slider>
            </TabPanel>

            <TabPanel>
              {/* Regal Originals Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "MINES", image: minesImage },
                  { bg: "orange.400", title: "PLINKO", image: plinkoImage },
                  { bg: "purple.300", title: "DICE", image: diceImage },
                  { bg: "green.400", title: "LIMBO", image: limboImage },
                  { bg: "yellow.400", title: "CRASH", image: crashImage },
                  {
                    bg: "green.300",
                    title: "DRAGONTOWER",
                    image: dragonTowerImage,
                  },
                  { bg: "green.600", title: "KENO", image: kenoImage },
                  { bg: "purple.500", title: "WHEEL", image: wheelImage },
                  {
                    bg: "orange.400",
                    title: "BLACKJACK",
                    image: blackjackImage,
                  },
                  { bg: "purple.300", title: "HILO", image: hiloImage },
                  { bg: "green.400", title: "DIAMONDS", image: diamondsImage },
                  { bg: "yellow.400", title: "SLIDE", image: slideImage },
                  { bg: "green.300", title: "ROULETTE", image: rouletteImage },
                  {
                    bg: "green.600",
                    title: "TOMEOFLIFE",
                    image: tomeOfLifeImage,
                  },
                  {
                    bg: "purple.500",
                    title: "SCARABSPIN",
                    image: scarabSpinImage,
                  },
                  {
                    bg: "orange.400",
                    title: "BLUESAMURAI",
                    image: blueSamuraiImage,
                  },
                  {
                    bg: "purple.300",
                    title: "VIDEOPOKER",
                    image: videoPokerImage,
                  },
                  { bg: "green.400", title: "BACCARAT", image: baccaratImage },
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              {/* Slots Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "sweetBonaza1000", image: sweetBonaza1000 },
                  { bg: "orange.400", title: "wantedDeadOrAWild", image: wantedDeadOrAWild },
                  { bg: "purple.300", title: "gatesOfOlympus", image: gatesOfOlympus },
                  { bg: "green.400", title: "sugarRush1000", image: sugarRush1000 },
                  { bg: "yellow.400", title: "leBandit", image: leBandit },
                  {
                    bg: "green.300",
                    title: "zeusVSheads",
                    image: zeusVSheads,
                  },
                  { bg: "green.600", title: "bonsaiBanzai", image: bonsaiBanzai },
                  { bg: "purple.500", title: "sweetBonaza", image: sweetBonaza },
                  {
                    bg: "orange.400",
                    title: "gatesOfOlympus1000",
                    image: gatesOfOlympus1000,
                  },
                  { bg: "purple.300", title: "firePortals", image: firePortals },
                  { bg: "green.400", title: "sugarRush", image: sugarRush },
                  { bg: "yellow.400", title: "ripCity", image: ripCity },
                  { bg: "green.300", title: "fruitParty", image: fruitParty },
                  {
                    bg: "green.600",
                    title: "tomeOfLife",
                    image: tomeOfLife,
                  },
                  {
                    bg: "purple.500",
                    title: "sweetFiesta",
                    image: sweetFiesta,
                  },
                  {
                    bg: "orange.400",
                    title: "fistOfDestruction",
                    image: fistOfDestruction,
                  },
                  {
                    bg: "purple.300",
                    title: "dogHouse",
                    image: dogHouse,
                  },
                  { bg: "green.400", title: "slayersInc", image: slayersInc },
                  
                  { bg: "green.400", title: "bigBassSplash", image: bigBassSplash },
                  { bg: "green.400", title: "fireInTheHole2", image: fireInTheHole2 },
                  { bg: "green.400", title: "gatesOfHeaven", image: gatesOfHeaven },
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              {/* Live Casino Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "blackJack", image: blackJack },
                  { bg: "orange.400", title: "roulette", image: roulette },
                  { bg: "purple.300", title: "baccarat", image: baccarat },
                  { bg: "green.400", title: "dragonTiger", image: dragonTiger },
                  { bg: "yellow.400", title: "craps", image: craps },
                  {
                    bg: "green.300",
                    title: "extremeTexas",
                    image: extremeTexas,
                  },
                  { bg: "green.600", title: "redDoorRoulette", image: redDoorRoulette },
                  { bg: "purple.500", title: "bacBo", image: bacBo },
                  {
                    bg: "orange.400",
                    title: "casinoHoldThem",
                    image:casinoHoldThem,
                  },
                  { bg: "purple.300", title: "stockMarket", image: stockMarket },
                  { bg: "green.400", title: "crazyTime", image: crazyTime },
                  { bg: "yellow.400", title: "cashOrCrash", image: cashOrCrash },
                  { bg: "green.300", title: "lightningBall", image: lightningBall },
                  {
                    bg: "green.600",
                    title: "crazyPachinko",
                    image: crazyPachinko,
                  },
                  {
                    bg: "purple.500",
                    title: "gonzosTreasureMap",
                    image: gonzosTreasureMap,
                  },
                  {
                    bg: "orange.400",
                    title: "monopoly",
                    image: monopoly,
                  },
                  {
                    bg: "purple.300",
                    title: "footballStudio",
                    image: footballStudio,
                  },
                  { bg: "green.400", title: "monopolyBigBaller", image: monopolyBigBaller },
                  
                  { bg: "green.400", title: "megaBall100X", image: megaBall100X },
                  { bg: "green.400", title: "funkyTime", image: funkyTime },
                  { bg: "green.400", title: "crazyCoinFlip", image: crazyCoinFlip },
                  
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              {/* Game Shows Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "crazyTime", image: crazyTimeGameShow },
                  { bg: "orange.400", title: "Lightning roulette", image: lightningRoulette },
                  { bg: "purple.300", title: "lightningStorm", image: lightningStorm },
                  { bg: "green.400", title: "stockMarket", image: stockMarketGameShow },
                  { bg: "yellow.400", title: "balloonRace", image: balloonRace },
                  {
                    bg: "green.300",
                    title: "extremeLightningRoulette",
                    image: xtremeLightningRoulette,
                  },
                  { bg: "green.600", title: "monopoly", image: monopolyGameShow },
                  { bg: "purple.500", title: "monopolyBigballer", image: monopolyBigBallerGameShow },
                  {
                    bg: "orange.400",
                    title: "lightningBlackJack",
                    image:lightningBlackJack,
                  },
                  { bg: "purple.300", title: "funkyTime", image: funkyTimeGameShow },
                  { bg: "green.400", title: "redDoorRoulette", image: redDoorRouletteGameShow },
                  { bg: "yellow.400", title: "sweetBonanzaCandyLand", image: sweetBonanzaCandyLand },
                  { bg: "green.300", title: "lightningBaccarat", image: lightningBaccarat },
                  {
                    bg: "green.600",
                    title: "crazyPachinko",
                    image: crazyPachinkoGameShow,
                  },
                  {
                    bg: "purple.500",
                    title: "crazyCoinFlip",
                    image: crazyCoinFlipGameShow,
                  },
                  {
                    bg: "orange.400",
                    title: "megaBall100X",
                    image: megaBall100XGameShow,
                  },
                  {
                    bg: "purple.300",
                    title: "footballStudio",
                    image: footBallStudioGameShow,
                  },
                  { bg: "green.400", title: "lightningDice", image: lightningDice },
                  
                  { bg: "green.400", title: "goldVaultRoulette", image: goldVaultRoulette },
                  { bg: "green.400", title: "cashOrCrash", image: cashOrCrashGameShow },
                  { bg: "green.400", title: "royalRichesRoulette", image: royalRichesRoulette },
                  
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              {/* Regal Exclusive Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "dogMansionMegaWays", image: dogMansionMegaWays },
                  { bg: "orange.400", title: "clashOfFangs", image: clashOfFangs },
                  { bg: "purple.300", title: "sweetFiestaExclusive", image: sweetFiestaExclusive },
                  { bg: "green.400", title: "roostersRevenge", image: roostersRevenge },
                  { bg: "yellow.400", title: "pixelFarm", image: pixelFarm },
                  {
                    bg: "green.300",
                    title: "wildWestBounty",
                    image: wildWestBounty,
                  },
                  { bg: "green.600", title: "athenVSares", image: athenaVSares },
                  { bg: "purple.500", title: "bisonSpirit", image: bisonSpirit },
                  {
                    bg: "orange.400",
                    title: "poseIdonVSapollo",
                    image:poseidonVSapollo,
                  },
                  { bg: "purple.300", title: "popRoyale", image: popRoyale },
                  { bg: "green.400", title: "lePharaoh", image: lePharaoh },
                  { bg: "yellow.400", title: "wildWestBonanza", image: wildWestBonanza },
                  { bg: "green.300", title: "bonsaiBanzai", image: bonsaiBanzaiExclusive },
                  {
                    bg: "green.600",
                    title: "skyShipRaiders",
                    image: skyshipRaiders,
                  },
                  {
                    bg: "purple.500",
                    title: "dracksStacks",
                    image: dracksStacks,
                  },
                  {
                    bg: "orange.400",
                    title: "jewelBonanza",
                    image: jewelBonanza,
                  },
                  {
                    bg: "purple.300",
                    title: "spaceKnightMergeUp",
                    image: spaceKnightMergeUp,
                  },
                  { bg: "green.400", title: "gatesOfHeaven", image: gatesOfHeavenExclusive },
                  
                  { bg: "green.400", title: "sugarTwist", image: sugarTwist },
                  { bg: "green.400", title: "slushieParty", image: slushieParty },
                  { bg: "green.400", title: "underWorld", image: underworld },
                  
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            <TabPanel>
              {/* New Releases Section */}
              <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3, lg: 8 }} // Responsive columns for different screen sizes
                spacing="16px"
              >
                {[
                  { bg: "purple.500", title: "muttleyCrew", image: muttleyCrew },
                  { bg: "orange.400", title: "brainsForBreakFast", image: brainsForBreakfast },
                  { bg: "purple.300", title: "empressOfShadows", image: empressOfShadows },
                  { bg: "green.400", title: "congoCashXL", image: congoCashXL },
                  { bg: "yellow.400", title: "sweetGummy", image: sweetGummy },
                  {
                    bg: "green.300",
                    title: "divineDIvas",
                    image: divineDivas,
                  },
                  { bg: "green.600", title: "milkHUnter", image: milkHunter },
                  { bg: "purple.500", title: "bisonSpirit", image: bisonSpirit },
                  {
                    bg: "orange.400",
                    title: "coinsAndCannons",
                    image:coinsAndCannons,
                  },
                  { bg: "purple.300", title: "JokersJewelsHot", image: jokersJewelsHot },
                  { bg: "green.400", title: "voodooPeople", image: voodooPeople },
                  { bg: "yellow.400", title: "goldenBrew", image: goldenBrew },
                  { bg: "green.300", title: "eagleStorm", image: eagleStorm },
                  {
                    bg: "green.600",
                    title: "shadowSummonerEgypt",
                    image: shadowSummonerEgypt,
                  },
                  {
                    bg: "purple.500",
                    title: "bavarianRiches",
                    image: bavarianRiches2,
                  },
                  {
                    bg: "orange.400",
                    title: "monstersUnchained",
                    image: monstersUnchained,
                  },
                  {
                    bg: "purple.300",
                    title: "lePharaOh",
                    image: lePharaohNewReleases,
                  },
                  { bg: "green.400", title: "mysteryMIce", image: mysteryMice },
                  
                  { bg: "green.400", title: "skateOrDIe", image: skateOrDie },
                  { bg: "green.400", title: "evilEyes", image: evilEyes },
                  { bg: "green.400", title: "bigBassVegas", image: bigBassVegas },
                  { bg: "green.400", title: "buildinBucks", image: buildinBucks },
                ].map((game, index) => (
                  <Box
                    key={index}
                    borderRadius="12px"
                    textAlign="center"
                    color="white"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={game.image}
                      alt={game.title}
                      boxSize={{ base: "150px", md: "200px" }} // Responsive box size
                      objectFit="cover"
                      mb={4}
                      borderRadius="md"
                      transition="all 0.2s ease-in-out"
                      _hover={{ transform: "translateY(-10px)" }}
                      mx="auto" // Ensure image is centered
                    />
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Tabs
        boxShadow="lg"
        variant="solid-rounded"
        colorScheme="teal"
        size="md"
        mb={12}
      >
        <TabList>
        <Tab>My Bets</Tab>
          <Tab>All Bets</Tab>
          <Tab>High Rollers</Tab>
          <Tab>Race Leaderboard</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* All Bets content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Game</Th>
                    <Th className="hide-mobile">User</Th>
                    <Th className="hide-mobile">Time</Th>
                    <Th className="hide-mobile">Bet Amount</Th>
                    <Th className="hide-mobile">Multiplier</Th>
                    <Th>Payout</Th>
                  </Tr>
                </Thead>
                <Tbody >
                  {allBets.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.game}</Td>
                      <Td className="hide-mobile">{bet.user}</Td>
                      <Td className="hide-mobile">{bet.time}</Td>
                      <Td className="hide-mobile">
                        <Flex align="center">
                          {bet.betAmount}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td className="hide-mobile">{bet.multiplier}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.payout}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* High Rollers content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Game</Th>
                    <Th>User</Th>
                    <Th>Time</Th>
                    <Th>Bet Amount</Th>
                    <Th>Multiplier</Th>
                    <Th>Payout</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {highRollers.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.event}</Td>
                      <Td>{bet.user}</Td>
                      <Td>{bet.time}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>{bet.odds}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* Race Leaderboard content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>User</Th>
                    <Th>Wagered</Th>
                    <Th>Prize</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {raceLeaderboard.map((entry, index) => (
                    <Tr key={index}>
                      <Td>{entry.rank}</Td>
                      <Td>{entry.user}</Td>
                      <Td>
                        <Flex align="center">
                          {entry.wagered}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>
                        <Flex align="center">
                          {entry.prize}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
      
      <CollapsibleSection/>

    </Flex>
  );
}
