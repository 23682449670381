/*eslint-disable*/
import {
    Box,
    Button,
    Flex,
    Image,
    Stack,
    Text,
    useColorModeValue,
    Tooltip,
    List,
    ListItem,
    Collapse,
  } from "@chakra-ui/react";
  import IconBox from "components/Icons/IconBox";
  import regalLogo from "assets/img/WagerStreet-logo.png";
  import { Separator } from "components/Separator/Separator";
  import React, { useState, useEffect } from "react";
  import { connect } from "react-redux";
  import { NavLink, useLocation, useNavigate } from "react-router-dom";
  import { FaAngleRight, FaAngleDown, FaVoteYea } from "react-icons/fa";
  import { FaAlignJustify, FaArrowLeft } from "react-icons/fa";
  import {
    CasinoIcon,
    SportsIcon,
    LiveIcon,
    BonusIcon,
    PersonIcon,
    DocumentIcon,
    RocketIcon,
    RouletteIcon,
  } from "components/Icons/Icons";
  import {
    GiTennisRacket,
    GiBasketballBall,
    GiCrossedSwords,
    GiPistolGun,
    GiBoxingGlove,
    GiBattleAxe,
  } from "react-icons/gi";
  import {
    GiMegaphone,
    GiHorseHead,
    GiTreasureMap,
    GiCrown,
    GiMoneyStack,
    GiCarWheel,
    GiChessKnight,
    GiConsoleController,
    GiCheckeredFlag,
    GiLaurelsTrophy,
    GiCardAceHearts,
    GiGameConsole,
    GiPokerHand,
    GiArrowDunk,
    GiSpades,
    GiDiamonds,
    GiLockedChest,
    GiTrophyCup,
    GiRugbyConversion,
    GiHockey,
    GiBaseballBat,
    GiCricketBat,
  } from "react-icons/gi";
  
  import {
    FaTicketAlt,
    FaEye,
    FaHandshake,
    FaBlogger,
    FaComments,
    FaFutbol,
    FaUser,
    FaBalanceScale,
    FaHeadset,
    FaGlobe,
    FaHourglassStart,
    FaStar,
    FaHistory,
    FaFilm,
    FaDollarSign,
    FaHandHoldingUsd,
    FaFootballBall,
  } from "react-icons/fa";
  
  import { MdLiveTv } from "react-icons/md";
  
  import { getSports, getCountries, getLeagues } from "../../actions/sports";
  
  import inactiveCasinoBg from "assets/img/sidebar/casino-poker-cards.jpg";
  import activeCasinoBg from "assets/img/sidebar/casino-poker-cards-green.jpg";
  import inactiveSportsBg from "assets/img/sidebar/sports-balls.jpg";
  import activeSportsBg from "assets/img/sidebar/sports-balls-orange.jpg";
  
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const SidebarMid = ({
    dispatch,
    sports,
    countries,
    leagues,
    wsConnected,
    logoText,
    isCollapsed,
    leftMenu,
    toggleSidebar,
  }) => {
    // to check for active links and opened collapses
    let location = useLocation();
    const navigate = useNavigate();
    // this is for the rest of the collapses
    const [isSportStatus, setisSportStatus] = useState(false);
    const [isCasinoStatus, setisCasinoStatus] = useState(false);
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");
    const buttonHoverbg = useColorModeValue("gray.300", "gray.600");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const backgroundImages = {
      Casino: {
        active: activeCasinoBg,
        inactive: inactiveCasinoBg,
      },
      Sports: {
        active: activeSportsBg,
        inactive: inactiveSportsBg,
      },
      // Add more mappings as needed...
    };
  
    // WebSocket connection setup
    useEffect(() => {
      Object.values(leftMenu.leftMenu).map((menu) => {
        if (activeRoute(menu.path) === "active") {
          setStatus(menu.path);
        }
      });
  
      if (isSportStatus && wsConnected && !Object.keys(sports).length) {
        dispatch(getSports());
      }
    }, [isSportStatus, sports, wsConnected]);
  
    const AllSportsDropdown = ({ isCollapsed }) => {
      const [isOpen, setIsOpen] = useState(false);
  
      const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };
  
      // Chakra Color Mode for consistency
  
      return (
        <Box
          width="100%"
          bg={activeBg}
          mb="10px"
          borderRadius="15px"
          _hover="none"
          _focus={{ boxShadow: "none" }}
          padding="2px"
        >
          <Button
            onClick={toggleDropdown}
            justifyContent="flex-start"
            alignItems="center"
            bg={activeBg}
            w="100%"
            mb="10px"
            borderRadius="15px"
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{ boxShadow: "none" }}
          >
            <Tooltip
              label={"All Sports"}
              placement="right"
              isDisabled={!isCollapsed}
              hasArrow
            >
              <Flex>
                <IconBox bg="teal.300" color="white" h="30px" w="30px" me="12px">
                  <Text fontWeight="bold">🏅</Text>
                </IconBox>
                {!isCollapsed && (
                  <Text
                    color={inactiveColor}
                    my="auto"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    All Sports
                  </Text>
                )}
              </Flex>
            </Tooltip>
            {!isCollapsed && (
              <Box ml={1}>{isOpen ? <FaAngleDown /> : <FaAngleRight />}</Box>
            )}
          </Button>
          <Collapse in={isOpen} animateOpacity>
            <List spacing={2} mt={2}>
              {Object.entries(sports).map(([sportId, sport]) => (
                <ListItem key={sportId} borderRadius="md">
                  <NavLink
                    to={`/sports/${sport.name}`}
                    style={{
                      textDecoration: "none",
                      width: isCollapsed ? "100%" : "50%",
                    }}
                  >
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="transparent"
                      w="100%"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{ boxShadow: "none" }}
                      padding="5px"
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Text color={inactiveColor} my="auto" fontSize="sm">
                          {sport.name}
                        </Text>
                      </Flex>
                    </Button>
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      );
    };
  
    const CreateLeftMenus = (leftMenu) => {
      const Menulist = leftMenu.leftMenu;
      if (isCollapsed) {
        setIsMenuOpen(false);
      }
      const toggleDropdown = (menuName) => {
        setIsMenuOpen((prev) => ({
          ...prev,
          [menuName]: !prev[menuName],
        }));
      };
  
      return (
        <Box
          bg={activeBg}
          w="100%"
          mb="10px"
          pb="10px"
          borderRadius="15px"
          _hover="none"
          _focus={{ boxShadow: "none" }}
          padding="2px"
        >
          {Object.values(Menulist).map((menu, index) => {
            return (
              <Box width={isCollapsed ? "100%" : "90%"} key={index}>
                <Button
                  onClick={() => {
                    if (isCollapsed) {
                      toggleSidebar();
                    }
                    if (Object.keys(menu.subMenu).length > 0) {
                      toggleDropdown(menu.menuName);
                    }
                  }}
                  justifyContent="flex-start"
                  alignItems="center"
                  bg={activeBg}
                  w="100%"
                  borderRadius="15px"
                  _hover={{ bg: buttonHoverbg }}
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{ boxShadow: "none" }}
                  padding="10px"
                >
                  {Object.keys(menu.subMenu).length == 0 && (
                    <NavLink
                      to={
                        Object.keys(menu.subMenu).length === 0 ? menu.path : "#"
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Tooltip
                        label={menu.menuName}
                        placement="right"
                        isDisabled={!isCollapsed}
                        hasArrow
                      >
                        <Flex>
                          {menu.icon && iconMapping[menu.icon] && (
                            <IconBox
                              bg={inactiveBg}
                              color="gray.400"
                              h="30px"
                              w="30px"
                              me="12px"
                            >
                              {iconMapping[menu.icon]}
                            </IconBox>
                          )}
                          {!isCollapsed && (
                            <Text
                              color={inactiveColor}
                              my="auto"
                              fontSize="sm"
                              fontWeight="bold"
                              isTruncated
                              maxWidth="120px"
                            >
                              {menu.menuName}
                            </Text>
                          )}
                        </Flex>
                      </Tooltip>
                    </NavLink>
                  )}
                  {Object.keys(menu.subMenu).length > 0 && (
                    <Tooltip
                      label={menu.menuName}
                      placement="right"
                      isDisabled={!isCollapsed}
                      hasArrow
                    >
                      <Flex>
                        {menu.icon && iconMapping[menu.icon] && (
                          <IconBox
                            bg={inactiveBg}
                            color="gray.300"
                            h="30px"
                            w="30px"
                            me="12px"
                          >
                            {iconMapping[menu.icon]}
                          </IconBox>
                        )}
                        {!isCollapsed && (
                          <Text
                            color={inactiveColor}
                            my="auto"
                            fontSize="sm"
                            fontWeight="bold"
                            isTruncated
                            maxWidth="120px"
                          >
                            {menu.menuName}
                          </Text>
                        )}
                      </Flex>
                    </Tooltip>
                  )}
                  {!isCollapsed && Object.keys(menu.subMenu).length > 0 && (
                    <Box ml={1}>
                      {isMenuOpen[menu.menuName] ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleRight />
                      )}
                    </Box>
                  )}
                </Button>
                <Collapse in={isMenuOpen[menu.menuName]} animateOpacity>
                  <List spacing={2} mt={2}>
                    {Object.values(menu.subMenu).map((subMenuItem, index) => {
                      return (
                        <ListItem borderRadius="md" key={index}>
                          <NavLink
                            to={`/sports/${subMenuItem.menuName}`}
                            style={{
                              textDecoration: "none",
                              width: isCollapsed ? "100%" : "50%",
                            }}
                          >
                            <Button
                              boxSize="initial"
                              justifyContent="flex-start"
                              alignItems="center"
                              bg="transparent"
                              w="100%"
                              _hover={{ bg: buttonHoverbg }}
                              _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                              }}
                              _focus={{ boxShadow: "none" }}
                              padding="5px"
                              paddingBottom={1}
                            >
                              <Flex>
                                {subMenuItem.icon &&
                                  iconMapping[subMenuItem.icon] && (
                                    <IconBox
                                      bg={inactiveBg}
                                      color="gray.300"
                                      h="30px"
                                      w="30px"
                                      me="12px"
                                    >
                                      {iconMapping[subMenuItem.icon]}
                                    </IconBox>
                                  )}
  
                                {!isCollapsed && (
                                  <Text
                                    color={inactiveColor}
                                    my="auto"
                                    fontSize="sm"
                                    fontWeight="bold"
                                  >
                                    {subMenuItem.menuName}
                                  </Text>
                                )}
                              </Flex>
                            </Button>
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </Box>
            );
          })}
        </Box>
      );
    };
  
    const setStatus = (path) => {
      setisSportStatus(false);
      setisCasinoStatus(false);
  
      if (path.startsWith("/sports")) {
        setisSportStatus(true);
      } else if (path.startsWith("/casino")) {
        setisCasinoStatus(true);
      }
    };
  
    const sportsIconMapping = {
      342: <SportsIcon color="inherit" />,
      343: <GiTennisRacket color="inherit" />,
      348: <GiBasketballBall color="inherit" />,
      350: <FaFootballBall color="inherit" />,
      345: <GiRugbyConversion color="inherit" />,
      372: <GiRugbyConversion color="inherit" />,
      347: <GiHockey color="inherit" />,
      366: <GiBaseballBat color="inherit" />,
      386: <GiCricketBat color="inherit" />,
      427: <GiTennisRacket color="inherit" />,
    };
  
    const iconMapping = {
      CasinoIcon: <CasinoIcon color="inherit" />,
      SportsIcon: <SportsIcon color="inherit" />,
      LiveIcon: <LiveIcon color="inherit" />,
      BonusIcon: <BonusIcon color="inherit" />,
      PersonIcon: <PersonIcon color="inherit" />,
      DocumentIcon: <DocumentIcon color="inherit" />,
      RocketIcon: <RocketIcon color="inherit" />,
      SoccerIcon: <SportsIcon color="inherit" />,
      TennisIcon: <GiTennisRacket color="inherit" />,
      BaseballIcon: <GiBaseballBat color="inherit" />,
      PoliticsIcon: <FaVoteYea color="inherit" />,
      BasketBallIcon: <GiBasketballBall color="inherit" />,
      CricketIcon: <GiCricketBat color="inherit" />,
      LeagueOfLegendsIcon: <GiCrossedSwords color="inherit" />,
      CSIcon: <GiPistolGun color="inherit" />,
      MMAIcon: <GiBoxingGlove color="inherit" />,
      DOTAIcon: <GiBattleAxe color="inherit" />,
      PromotionsIcon: <GiMegaphone color="inherit" />,
      RaffleIcon: <FaTicketAlt color="inherit" />,
      RaceIcon: <GiHorseHead color="inherit" />,
      PragmaticDropsIcon: <GiTreasureMap color="inherit" />,
      ViewAllIcon: <FaEye color="inherit" />,
      AffiliateIcon: <FaHandshake color="inherit" />,
      VipClubIcon: <GiCrown color="inherit" />,
      BlogIcon: <FaBlogger color="inherit" />,
      ForumIcon: <FaComments color="inherit" />,
      SponsorshipIcon: <GiMoneyStack color="inherit" />,
      GamblingIcon: <FaBalanceScale color="inherit" />,
      LiveSupportIcon: <FaHeadset color="inherit" />,
      LanguageIcon: <FaGlobe color="inherit" />,
      LiveEventsIcon: <MdLiveTv color="inherit" />,
      StartingSoonIcon: <FaHourglassStart color="inherit" />,
      MyBetsIcon: <GiPokerHand color="inherit" />,
      AllSportsIcon: <GiTrophyCup color="inherit" />,
      AllEsportsIcon: <GiConsoleController color="inherit" />,
      AllRacingIcon: <GiCheckeredFlag color="inherit" />,
      FavouritesIcon: <FaStar color="inherit" />,
      RecentIcon: <FaHistory color="inherit" />,
      ChallengesIcon: <GiLaurelsTrophy color="inherit" />,
      OriginalsIcon: <FaFilm color="inherit" />,
      ExclusivesIcon: <GiLockedChest color="inherit" />,
      SlotsIcon: <GiBattleAxe color="inherit" />,
      LiveCasinoIcon: <GiCardAceHearts color="inherit" />,
      GameShowsIcon: <GiGameConsole color="inherit" />,
      StakePokerIcon: <GiPokerHand color="inherit" />,
      BonusBuyIcon: <FaDollarSign color="inherit" />,
      EnhancedRTPIcon: <GiArrowDunk color="inherit" />,
      TableGamesIcon: <GiCardAceHearts color="inherit" />,
      BlackjackIcon: <GiSpades color="inherit" />,
      BaccaratIcon: <GiDiamonds color="inherit" />,
      RouletteIcon: <RouletteIcon color="inherit" />,
      StakeF1TeamIcon: <GiCarWheel color="inherit" />,
      UFCIcon: <GiBoxingGlove color="inherit" />,
      EvertonFootballClubIcon: <FaFutbol color="inherit" />,
      EsporteClubeJuventudeIcon: <FaFutbol color="inherit" />,
      EnyimbaFootballClubIcon: <FaFutbol color="inherit" />,
      DavisCupAndBillieJeanKingCupIcon: <GiTennisRacket color="inherit" />,
      TeamSecretIcon: <GiChessKnight color="inherit" />,
      KunAgueroIcon: <FaUser color="inherit" />,
      IsraelAdesanyaIcon: <GiBoxingGlove color="inherit" />,
      AlexPereiraIcon: <GiBoxingGlove color="inherit" />,
      AlexaGrassoIcon: <GiBoxingGlove color="inherit" />,
      AlexandrePantojaIcon: <GiBoxingGlove color="inherit" />,
      JailtonAlmeidaIcon: <GiBoxingGlove color="inherit" />,
      FittipaldiBrothersIcon: <GiCarWheel color="inherit" />,
      ProvidersIcon: <FaHandHoldingUsd color="inherit" />,
    };
  
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
      return location.pathname.includes(routeName) ? "active" : "";
    };
  
    const createLinks = (leftMenu) => {
      return Object.values(leftMenu).map((menu) => {
        const isActive = activeRoute(menu.path) === "active";
        const menuBg = backgroundImages[menu.menuName] || {};
        const bgImage = isActive ? menuBg.active : menuBg.inactive;
        const hoverBgImage = menuBg.active;
  
        return (
          <NavLink
            to={menu.path}
            key={menu.menuName}
            style={{ width: isCollapsed ? "100%" : "50%" }}
          >
            <Flex
              direction="column" // If buttons are in a vertical layout
              justifyContent="center" // Center the buttons vertically
              alignItems="center" // Center the buttons horizontally
              w="100%" // Make sure the container takes full width
              h="100%" // Set height as needed to fill the available space
            >
              <Button
                onClick={() => {
                  setStatus(menu.path);
                }}
                boxSize="initial"
                justifyContent="center"
                alignItems="center"
                mb={{
                  xl: "10px",
                }}
                py={isCollapsed ? "22px" : "10px"} // Increase padding when collapsed
                ps={{
                  sm: "10px",
                  xl: isCollapsed ? "10px" : "16px",
                }}
                borderRadius="8"
                w={isCollapsed ? "60%" : "90%"} // Adjust width based on collapsed state
                _hover={{
                  backgroundImage: hoverBgImage,
                  backgroundSize: "cover",
                  backgroundPosition: "left",
                  backgroundRepeat: "no-repeat",
                }}
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                backgroundImage={bgImage}
                backgroundSize="cover"
                backgroundPosition="left"
                backgroundRepeat="no-repeat"
                bgColor={isActive ? activeBg : "transparent"}
              >
                <Tooltip
                  label={menu.menuName}
                  placement="right"
                  isDisabled={!isCollapsed}
                  hasArrow
                >
                  <Flex w="100%" justifyContent="center">
                    {/* Hide the text when the sidebar is collapsed */}
                    {!isCollapsed && (
                      <Text
                        color="white"
                        my="auto"
                        fontSize="sm"
                        textTransform="uppercase"
                      >
                        {document.documentElement.dir === "rtl"
                          ? menu.rtlName
                          : menu.menuName}
                      </Text>
                    )}
                  </Flex>
                </Tooltip>
              </Button>
            </Flex>
          </NavLink>
        );
      });
    };
  
    const createSubLinks = (leftMenu) => {
      // Helper function to format the strings of Navlinks
      const formatStringForURL = (str) => {
        if (typeof str === "string") {
          return str.replace(/\s+/g, ""); // Remove all white spaces
        }
        return ""; // Return an empty string if str is not a valid string
      };
  
      const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
      const [countryDropdowns, setCountryDropdowns] = useState({});
  
      // When the sidebar is collapsed, close all submenus and country dropdowns
      useEffect(() => {
        if (isCollapsed) {
          setIsSubMenuOpen({});
          setCountryDropdowns({});
        }
      }, [isCollapsed]);
  
      const toggleSubMenuDropdown = (menuName) => {
        setIsSubMenuOpen((prev) => ({
          ...prev,
          [menuName]: !prev[menuName],
        }));
      };
  
      const toggleCountryDropdown = (countryId) => {
        setCountryDropdowns((prev) => ({
          ...prev,
          [countryId]: !prev[countryId],
        }));
      };
  
      if (isSportStatus) {
        return (
          <Box
            width={isCollapsed ? "100%" : "90%"}
            bg={activeBg}
            w="100%"
            mb="10px"
            borderRadius="15px"
            _hover="none"
            _focus={{ boxShadow: "none" }}
            padding="10px 0px 10px 0px"
          >
            {!isCollapsed && (
              <Box
                width={isCollapsed ? "100%" : "90%"}
                w="100%"
                mb="10px"
                borderRadius="15px"
                padding="10px"
              >
                <Text color={inactiveColor} my="auto" fontSize="lg" pl="10px">
                  Top Sports
                </Text>
              </Box>
            )}
            {!isCollapsed && (
              <Box pb={5}>
                <hr />
              </Box>
            )}
            {Object.values(leftMenu).map((menu) => {
              if (activeRoute(menu.path) === "active") {
                return (
                  Object.entries(sports).length > 0 &&
                  Object.entries(sports).map(([sportId, sport]) => {
                    const filteredCountries =
                      Object.entries(countries).filter(
                        ([, country]) => country.sportId === sportId
                      ) || [];
  
                    return (
                      <Box key={sport.name} width="100%" mb="2px">
                        <Button
                          onClick={() => {
                            if (isCollapsed) {
                              toggleSidebar();
                            }
                            if (Object.keys(menu.subMenu).length > 0) {
                              toggleSubMenuDropdown(sport.name);
                            }
                            dispatch(getCountries(sportId));
                          }}
                          justifyContent="flex-start"
                          alignItems="center"
                          bg={activeBg}
                          w="100%"
                          borderRadius="15px"
                          _hover={{ bg: buttonHoverbg }}
                          _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                          }}
                          _focus={{ boxShadow: "none" }}
                          padding="10px"
                        >
                          <Flex alignItems="center">
                            <IconBox
                              bg={inactiveBg}
                              color="gray.300"
                              h="30px"
                              w="30px"
                              me="12px"
                            >
                              {sportsIconMapping[sportId] || (
                                <SoccerIcon color="inherit" />
                              )}
                            </IconBox>
                            {!isCollapsed && (
                              <Text
                                color={inactiveColor}
                                my="auto"
                                fontSize="sm"
                                fontWeight="bold"
                                isTruncated
                                maxWidth="120px"
                              >
                                {sport.name}
                              </Text>
                            )}
                          </Flex>
                          {!isCollapsed && (
                            <Box ml={1}>
                              {isSubMenuOpen[sport.name] ? (
                                <FaAngleDown />
                              ) : (
                                <FaAngleRight />
                              )}
                            </Box>
                          )}
                        </Button>
                        <Collapse in={isSubMenuOpen[sport.name]} animateOpacity>
                          <List spacing={2} mt={2}>
                            <ListItem borderRadius="md">
                              <List spacing={2} mt={2}>
                                {filteredCountries.map(([countryId, country]) => {
                                  const filteredLeagues = [];
                                  Object.entries(leagues).filter(
                                    ([leagueId, league]) => {
                                      if (
                                        league.sportId.toString() === sportId &&
                                        league.countryId.toString() == countryId
                                      ) {
                                        filteredLeagues.push({
                                          ...league,
                                          id: leagueId,
                                        });
                                      }
                                    }
                                  );
                                  return (
                                    <ListItem key={countryId} borderRadius="md">
                                      <Button
                                        onClick={() => {
                                          toggleCountryDropdown(countryId);
                                          dispatch(
                                            getLeagues(sportId, countryId)
                                          );
                                        }}
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        bg="transparent"
                                        w="100%"
                                        _hover={{ bg: buttonHoverbg }}
                                        _active={{
                                          bg: "inherit",
                                          transform: "none",
                                          borderColor: "transparent",
                                        }}
                                        _focus={{ boxShadow: "none" }}
                                        padding="5px"
                                        pl={8}
                                      >
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="center"
                                          w="100%"
                                        >
                                          <Text
                                            color={inactiveColor}
                                            my="auto"
                                            fontSize="sm"
                                          >
                                            {country.name}
                                          </Text>
                                          {countryDropdowns[countryId] ? (
                                            <FaAngleDown />
                                          ) : (
                                            <FaAngleRight />
                                          )}
                                        </Flex>
                                      </Button>
                                      <Collapse
                                        in={countryDropdowns[countryId]}
                                        animateOpacity
                                      >
                                        <List spacing={2} mt={2}>
                                          {filteredLeagues &&
                                            filteredLeagues.map((league) => {
                                              return (
                                                <ListItem
                                                  key={league.id}
                                                  borderRadius="md"
                                                  pl={12}
                                                  _hover={{ bg: buttonHoverbg }}
                                                  bg={
                                                    activeRoute(
                                                      league.name.replace(
                                                        /\s+/g,
                                                        ""
                                                      )
                                                    ) === "active"
                                                      ? buttonHoverbg
                                                      : ""
                                                  }
                                                >
                                                  <div
                                                    onClick={() => {
                                                      // Directly using navigate on click
                                                      navigate(
                                                        `/sports/${formatStringForURL(
                                                          sport.name
                                                        )}/${formatStringForURL(
                                                          country.name
                                                        )}/${formatStringForURL(
                                                          league.name
                                                        )}`,
                                                        {
                                                          state: {
                                                            sportId: sportId.toString(),
                                                            leagueId: league.id.toString(),
                                                          },
                                                        }
                                                      );
                                                    }}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "flex-start",
                                                      alignItems: "center",
                                                      width: "100%",
                                                      padding: "5px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <Flex
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                      w="100%"
                                                    >
                                                      <Text
                                                        color={
                                                          activeRoute(
                                                            league.name.replace(
                                                              /\s+/g,
                                                              ""
                                                            )
                                                          ) === "active"
                                                            ? activeColor
                                                            : inactiveColor
                                                        }
                                                        my="auto"
                                                        fontSize="13px"
                                                      >
                                                        {league.name}
                                                      </Text>
                                                    </Flex>
                                                  </div>
                                                </ListItem>
                                              );
                                            })}
                                        </List>
                                      </Collapse>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                    );
                  })
                );
              }
  
              return null; // Return null if there's no subMenu
            })}
          </Box>
        );
      }
      return null;
    };
  
    const links = <>{createLinks(leftMenu.leftMenu)}</>;
    const subLinks = <>{createSubLinks(leftMenu.leftMenu)}</>;
  
    return (
      <Box
        height="100vh"
        overflowY="auto"
        pt="25px"
        mb="10px"
        mt="55px"
        ml="-9px"
        pb={2}
        sx={{
          scrollbarWidth: "none" /* Hide scrollbar for Firefox */,
          "&::-webkit-scrollbar": {
            display: "none" /* Hide scrollbar for Chrome, Safari, Edge */,
          },
        }}
      >
        <Stack direction="column" spacing={4}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={isCollapsed ? "flex-start" : "space-between"}
          >
            {links}
          </Box>
        </Stack>
  
        {isSportStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              <CreateLeftMenus leftMenu={leftMenu.leftMenu4} />
            </Box>
          </Stack>
        )}
  
        {isSportStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              {subLinks}
            </Box>
          </Stack>
        )}
  
        {isSportStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              <CreateLeftMenus leftMenu={leftMenu.leftMenu6} />
            </Box>
          </Stack>
        )}
  
        {isSportStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              <CreateLeftMenus leftMenu={leftMenu.leftMenu7} />
            </Box>
          </Stack>
        )}
  
        {isCasinoStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              <CreateLeftMenus leftMenu={leftMenu.leftMenu8} />
            </Box>
          </Stack>
        )}
  
        {isCasinoStatus && (
          <Box
            width={isCollapsed ? "100%" : "90%"}
            bg={activeBg}
            w="100%"
            mb="10px"
            borderRadius="15px"
            _hover="none"
            _focus={{ boxShadow: "none" }}
            padding="10px 0px 0px 0px"
          >
            {!isCollapsed && (
              <Box
                width={isCollapsed ? "100%" : "90%"}
                w="100%"
                mb="10px"
                borderRadius="15px"
              >
                <Text color={inactiveColor} my="auto" fontSize="lg" pl="10px">
                  Games
                </Text>
              </Box>
            )}
            {!isCollapsed && (
              <Box pb={5}>
                <hr />
              </Box>
            )}
            <Stack direction="column" spacing={4}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={isCollapsed ? "flex-start" : "space-between"}
              >
                <CreateLeftMenus leftMenu={leftMenu.leftMenu9} />
              </Box>
            </Stack>
          </Box>
        )}
  
        {isCasinoStatus && (
          <Stack direction="column" spacing={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent={isCollapsed ? "flex-start" : "space-between"}
            >
              <CreateLeftMenus leftMenu={leftMenu.leftMenu10} />
            </Box>
          </Stack>
        )}
  
        <Stack direction="column" spacing={4}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={isCollapsed ? "flex-start" : "space-between"}
          >
            <CreateLeftMenus leftMenu={leftMenu.leftMenu2} />
          </Box>
        </Stack>
  
        <Stack direction="column" spacing={4}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={isCollapsed ? "flex-start" : "space-between"}
            mb={20}
          >
            <CreateLeftMenus leftMenu={leftMenu.leftMenu3} />
          </Box>
        </Stack>
      </Box>
    );
  };
  
  const s2p = (state) => ({
    sports: state.sports.sports,
    countries: state.sports.countries,
    leagues: state.sports.leagues,
    wsConnected: state.websocket?.connected || false,
  });
  
  export default connect(s2p)(SidebarMid);
  